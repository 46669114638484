import React from 'react';
import {
  Table,
  Tag,
  Modal,
  Card,
  Button,
  Icon,
  Popover,
  Input,
  Radio,
  message as showMessage,
  Tooltip,
} from 'antd';
// import { Link } from 'react-router-dom';

import { getData } from '../../helper/dataService';
import API from '../../helper/api';
import { getName } from '../../helper/utility';

import NotificationAssetForm from './NotificationAssetForm';
import NotificationAssetAllForm from './NotificationAllAssetForm';
import DividerWithColor from '../common/DividerWithColor';
import UserDetails from '../user/UserDetails';
import NotificationCount from './NotificationCount';
import { PermissionContext } from '../../permissionContext';

class Notification extends React.Component {
  static contextType = PermissionContext;

  state = {
    showAssetSettingModal: false,
    showUserDetailsModal: false,
    showNotificationCountModal: false,
    notificationsSettings: [], // Saving all setting only with settings update
    notificationsSettingsForTable: [], // Saving for render in Table with/without filter
    featureSortName: 'email',
    user: {},
    isFetchingData: true,
    title: '',
    assetOid: '',
    showAssetSearch: false,
    showAssetSearchValue: '',
    isSettingsUpdated: false,
  };

  // user oid from  url (react router parameter)
  userOid = this.props.match.params.oid;

  componentDidMount() {
    //  fetch notifications against user oid
    this.fetchNotificationData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      featureSortName,
      isSettingsUpdated,
      showAssetSearchValue,
    } = this.state;

    if (
      prevState.featureSortName !== featureSortName ||
      prevState.isSettingsUpdated !== isSettingsUpdated ||
      prevState.showAssetSearchValue !== showAssetSearchValue
    ) {
      // when feature type change sort notifications
      this.sortDataByFeature();
      if (showAssetSearchValue.length < 1) {
        this.sortDataByFeature();
      }
    }
  }

  fetchNotificationData = () => {
    getData(API.user.notification(this.userOid))
      .then(
        ({
          data: {
            data: { notification_settings, user },
          },
        }) => {
          this.setState({
            isFetchingData: false,
            notificationsSettings: notification_settings,
            notificationsSettingsForTable: notification_settings,
            user,
          });
          this.sortDataByFeature();
        }
      )
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isFetchingData: false });
      });
  };

  closeModal = () => {
    this.setState({
      showAssetSettingModal: false,
      showUserDetailsModal: false,
      showNotificationCountModal: false,
      assetOid: '',
      title: '',
    });
  };

  handleUserDetail = () => {
    // Show user details modal
    this.setState({
      showUserDetailsModal: true,
    });
  };

  handleNotificationCount = () => {
    // Show user currrent notification count
    this.setState({
      showNotificationCountModal: true,
    });
  };

  handleAssetNotification = (assetOid, name) => {
    // Set asset oid and name
    this.setState({
      showAssetSettingModal: true,
      assetOid,
      title: name,
    });
  };

  handleAllAssetsNotification = () => {
    // Blank asset oid means bulk edit
    this.setState({
      showAssetSettingModal: true,
      assetOid: '',
      title: 'Bulk Edit',
    });
  };

  updateAssetNotification = (values, assetOid) => {
    this.setState((prevState) => {
      // Modify main state against single asset modified values
      const notificationsSettings = prevState.notificationsSettings.map(
        (item) => {
          if (item.asset._id.$oid === assetOid) {
            // merge current and updated setting
            return {
              ...item,
              ...values.settings,
            };
          }
          return item;
        }
      );

      //  setState
      return {
        notificationsSettings,
        notificationsSettingsForTable: this.filterByName(
          notificationsSettings,
          prevState.showAssetSearchValue
        ),
        isSettingsUpdated: true,
      };
    });
  };

  updateAllAssetNotification = (values) => {
    // 'values' provide as object. get the name of 'key' (feature) - power_cut, over_load etc
    const feature = Object.keys(values)[0];

    // settings of feature. Can be single setting.
    const { sms = '', email = '', app = '' } = values[feature];

    this.setState((prevState) => {
      const notificationsSettings = prevState.notificationsSettings.map(
        (item) => {
          // Check asset has current feature
          if (item[feature]) {
            // save current state of feature
            const {
              email: currentEmailStatus,
              sms: currentSmsStatus,
              app: currentAppStatus,
            } = item[feature];

            // bulk update against asset search. if search empty update all.
            if (
              item.asset.name
                .toLowerCase()
                .includes(prevState.showAssetSearchValue)
            ) {
              // merge current and updated setting
              return {
                ...item,
                /* feature setting can be on OR off
            set as on -> true, off -> false
            otherwise current state.
            */
                [feature]: {
                  email: email ? email === 'on' : currentEmailStatus,
                  sms: sms ? sms === 'on' : currentSmsStatus,
                  app: app ? app === 'on' : currentAppStatus,
                },
              };
            }
          }
          return item;
        }
      );

      // setState
      return {
        notificationsSettings,
        notificationsSettingsForTable: this.filterByName(
          notificationsSettings,
          prevState.showAssetSearchValue
        ),
        isSettingsUpdated: true,
      };
    });
  };

  handleAssetSearchShow = () => {
    // show asset search box
    this.setState((prevState) => ({
      showAssetSearch: !prevState.showAssetSearch,
    }));
  };

  handleAssetSearchClear = () => {
    //  Hide search box, clear current search value, set all notifications
    this.setState((prevState) => ({
      showAssetSearchValue: '',
      showAssetSearch: false,
      notificationsSettingsForTable: prevState.notificationsSettings,
    }));
  };

  handleAssetSearch = (e) => {
    const value = e.target.value.toLowerCase();
    this.setState((prevState) => {
      // for current typed value search against it
      if (value) {
        return {
          notificationsSettingsForTable: this.filterByName(
            prevState.notificationsSettings,
            value
          ),
          showAssetSearchValue: value,
        };
      }
      // if value empty set to default all notifications
      return {
        notificationsSettingsForTable: prevState.notificationsSettings,
        showAssetSearchValue: value,
      };
    });
  };

  handleFeatureSort = (e) => {
    // set feature sort setting - sms, email etc
    const { value } = e.target;
    this.setState({ featureSortName: value });
  };

  sortDataByFeature = () => {
    // current feature setting and notifications
    const { featureSortName, notificationsSettingsForTable } = this.state;

    const sortNotificationsSettings = notificationsSettingsForTable.sort(
      (firstElement, secondElement) => {
        // Must add all feature here to sort them
        const features = ['power_cut', 'ignition', 'over_speed', 'geofence'];
        const featureLength = features.length;

        // status will use for sort return value
        let status;
        // will count feature setting enable (true) list
        let trueCountFirst = 0;
        let trueCountSecond = 0;

        // loop against features list and count them
        features.forEach((item) => {
          // if feature exist for first element
          if (firstElement[item]) {
            trueCountFirst = firstElement[item][featureSortName]
              ? trueCountFirst + 1
              : trueCountFirst;
          }

          // if feature exist for Second element
          if (secondElement[item]) {
            trueCountSecond = secondElement[item][featureSortName]
              ? trueCountSecond + 1
              : trueCountSecond;
          }
        });

        if (
          featureLength === trueCountFirst &&
          featureLength === trueCountSecond
        ) {
          status = 0;
        } else if (trueCountFirst < trueCountSecond) {
          status = 1;
        } else if (trueCountFirst > trueCountSecond) {
          status = -1;
        }

        trueCountFirst = 0;
        trueCountSecond = 0;

        return status;
      }
    );

    this.setState({
      isSettingsUpdated: false,
      notificationsSettingsForTable: sortNotificationsSettings,
    });
  };

  // filter notification array against provided name
  filterByName = (arr, name) =>
    arr.filter((item) => item.asset.name.toLowerCase().includes(name));

  info = ({ email, sms, app }) => {
    const whichColor = (isActive) => (isActive ? '#099268' : '#e8590c');
    return (
      <React.Fragment>
        <Tag color={whichColor(email)}>email</Tag>
        <Tag color={whichColor(sms)}>sms</Tag>
        <Tag color={whichColor(app)}>app</Tag>
      </React.Fragment>
    );
  };

  render() {
    const {
      showAssetSettingModal,
      showUserDetailsModal,
      showNotificationCountModal,
      notificationsSettings,
      notificationsSettingsForTable,
      featureSortName,
      user,
      isFetchingData,
      title,
      assetOid,
      showAssetSearch,
      showAssetSearchValue,
    } = this.state;

    const { checkSubSectionPermission } = this.context;

    return (
      <React.Fragment>
        <Card
          title={
            <span>
              <Icon type='notification' />
              {'User Notification '}
              {Object.keys(user).length > 0 && (
                <span>
                  {`of ${getName(1, user.name)} `}
                  <Button onClick={this.handleUserDetail}>
                    {user.user_id}
                  </Button>
                </span>
              )}

              <span>
                {' '}
                <Icon type='table' />
                {`  Total Asset: ${notificationsSettings.length} `}
              </span>
              {showAssetSearchValue && (
                <span>
                  <Icon type='table' />
                  {` Search Found:${notificationsSettingsForTable.length} `}
                </span>
              )}
            </span>
          }
          extra={
            // hide when data fetching, user flagged and notification empty
            isFetchingData ||
            user.flagged ||
            notificationsSettingsForTable.length <= 0 ? null : (
              <span>
                {/* feature setting  */}
                <Radio.Group
                  defaultValue='sms'
                  onChange={this.handleFeatureSort}
                  value={featureSortName}
                >
                  <Radio.Button value='email'>Email</Radio.Button>
                  <Radio.Button value='sms'>SMS</Radio.Button>
                  <Radio.Button value='app'>App</Radio.Button>
                </Radio.Group>
                {/* show notificaiont count */}
                &nbsp; &nbsp;
                <Button onClick={this.handleNotificationCount} icon='bell' />
                {/* show bulk edit setting */}
                &nbsp; &nbsp;
                {checkSubSectionPermission('users', 'notification_edit') && (
                  <Button
                    onClick={this.handleAllAssetsNotification}
                    icon='tool'
                  />
                )}
              </span>
            )
          }
        >
          {user.flagged && (
            <DividerWithColor> Account is Flagged</DividerWithColor>
          )}

          <Table
            columns={this.columns}
            align='left'
            bordered
            scroll={{ x: true }}
            style={{ whiteSpace: 'nowrap' }}
            pagination={{
              position: 'top',
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
            }}
            dataSource={
              notificationsSettingsForTable.length > 0
                ? notificationsSettingsForTable
                : null
            }
            loading={isFetchingData}
            rowKey={(record) => record.asset._id.$oid}
          >
            <Table.Column
              title={
                <div>
                  {/* show when no search value available */}
                  {!showAssetSearchValue && <span>Asset Name</span>}

                  {/* Asset search bar  */}
                  <span
                    style={{
                      marginLeft: '10px',
                      lineHeight: '1',
                      cursor: 'pointer',
                    }}
                  >
                    <Popover
                      content={
                        <Input.Search
                          placeholder='Search here...'
                          onChange={this.handleAssetSearch}
                          value={showAssetSearchValue}
                          autoFocus
                        />
                      }
                      trigger='click'
                      visible={showAssetSearch}
                      onVisibleChange={this.handleAssetSearchShow}
                      placement='bottom'
                    >
                      {/* show search icon when no search value available
                      otherwise show search content */}
                      {showAssetSearchValue ? (
                        <span style={{ textTransform: 'uppercase' }}>
                          {showAssetSearchValue}
                        </span>
                      ) : (
                        <Tooltip title='Click here to filter asset'>
                          <Icon type='search' style={{ marginRight: '10px' }} />
                        </Tooltip>
                      )}
                    </Popover>

                    {/* show clear current search content */}
                    {showAssetSearchValue && (
                      <Icon
                        type='delete'
                        onClick={this.handleAssetSearchClear}
                      />
                    )}
                  </span>
                </div>
              }
              render={(record) => (
                // <Link to={`assets/${record.asset._id.$oid}`} target="_blank">
                //   {record.asset.name}
                // </Link>
                <a
                  href={`http://www.finder-lbs.com/admin#/assets/${record.asset._id.$oid}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {record.asset.name}
                </a>
              )}
              key={(record) => record.asset._id.$oid}
            />
            <Table.Column
              title='Power Cut'
              render={(record) =>
                record.power_cut ? this.info(record.power_cut) : ''
              }
            />
            <Table.Column
              title='Ignition'
              render={(record) =>
                record.ignition ? this.info(record.ignition) : ''
              }
            />
            <Table.Column
              title='Over Speed'
              render={(record) =>
                record.over_speed ? this.info(record.over_speed) : ''
              }
            />
            <Table.Column
              title='Geofence'
              render={(record) =>
                record.geofence ? this.info(record.geofence) : ''
              }
            />
            {!user.flagged &&
              checkSubSectionPermission('users', 'notification_edit') && (
                <Table.Column
                  fixed='right'
                  width={100}
                  title='Action'
                  render={({ asset }) => (
                    <Button.Group>
                      <Button
                        type='primary'
                        ghost
                        onClick={this.handleAssetNotification.bind(
                          this,
                          asset._id.$oid,
                          asset.name
                        )}
                      >
                        Edit
                      </Button>
                    </Button.Group>
                  )}
                />
              )}
          </Table>
        </Card>

        {/* Modal for change asset settings */}
        {showAssetSettingModal && (
          <Modal
            title={title}
            visible
            onCancel={this.closeModal}
            footer={null}
            maskClosable={false}
          >
            {/* For single asset settings */}

            {assetOid && (
              <NotificationAssetForm
                // Pass object from array index 0
                assetSetting={
                  notificationsSettingsForTable.filter(
                    (item) => item.asset._id.$oid === assetOid
                  )[0]
                }
                assetOid={assetOid}
                userOid={this.userOid}
                updateAssetNotification={this.updateAssetNotification}
                closeModal={this.closeModal}
              />
            )}

            {/* For all asset setting */}
            {!assetOid && (
              <NotificationAssetAllForm
                assetSetting={notificationsSettingsForTable}
                allAssetSetting={notificationsSettings}
                userOid={this.userOid}
                updateAllAssetNotification={this.updateAllAssetNotification}
                closeModal={this.closeModal}
              />
            )}
          </Modal>
        )}

        {/*  Modal for user details */}
        {showUserDetailsModal && (
          <Modal
            title='User Details'
            visible
            onCancel={this.closeModal}
            footer={null}
            maskClosable={false}
          >
            <UserDetails userId={user._id.$oid} />
          </Modal>
        )}

        {/*  Modal for notification count */}
        {showNotificationCountModal && (
          <Modal
            title='Notification Active Count'
            visible
            onCancel={this.closeModal}
            footer={null}
            maskClosable={false}
          >
            <NotificationCount assetSetting={notificationsSettingsForTable} />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default Notification;
