import React from 'react';
import { message as showMessage } from 'antd';
import styled from 'styled-components';
import GoogleLogin from 'react-google-login';
import { postData } from '../../helper/dataService';
import API from '../../helper/api';
import finderLogo from '../../assets/finder_logo_red.png';
import Spinner from '../common/Spinner';
import { setItem } from '../../helper/utility';
import { withRouter } from 'react-router-dom';
import CompleteRegistration from './CompleteRegistration';

class Login extends React.Component {
  state = {
    loader: false,
    registrationComplete: true,
  };

  responseGoogleFailure = (failed) => {
    console.error('Login Failed: ', failed.error);
    this.setState({ loader: false });
    showMessage.error('Sorry! Something went wrong');
  };

  responseGoogleSuccess = (response) => {
    const timezone = new Date().getTimezoneOffset();
    const data = {
      client_id: 'finder_admin',
      client_secret: 'f1nd3r@dm1n',
      grant_type: 'client_credentials',
      scope: '',
      login_type: 'social_auth',
      access_token: `${response.accessToken}`,
      social_auth_type: 'google',
      timezone: `${timezone}`,
    };

    postData(API.auth.login, data)
      .then(
        ({
          data: {
            data: { access_token, refresh_token } = {},
            user: {
              name,
              email,
              email_verified,
              mobile,
              mobile_verified,
              gender,
              religion,
              blood_group,
              birth_date,
            } = {},
            status = '',
            display = '',
          },
        }) => {
          if (status === 'success') {
            setItem('accessToken', access_token);
            setItem('refreshToken', refresh_token);
            setItem('name', name);
            setItem('email', email);

            if (
              name &&
              email &&
              mobile &&
              mobile_verified &&
              gender &&
              religion &&
              blood_group &&
              birth_date
            ) {
              // Redirect to home
              setItem('profileComplete', 'yes');
              showMessage.success(
                `Welcome, ${name.split(' ').splice(0, 2).join(' ')}`
              );
              this.props.history.push('/');
            } else {
              this.setState({ registrationComplete: false, loader: false });
            }
          } else if (status === 'failed') {
            showMessage.error(display);
            this.setState({ loader: false });
          }
        }
      )
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ loader: false });
      });
  };

  handleLoader = () => {
    // View loader when is user request to login
    this.setState({ loader: true });
  };

  render() {
    const { loader, registrationComplete } = this.state;
    if (registrationComplete) {
      return (
        <React.Fragment>
          <div className='center-of-page'>
            <LoginBox>
              <Logo src={finderLogo} alt='Finder Logo' />
              <Title>Admin Panel</Title>
              <div>
                {loader ? (
                  <Spinner />
                ) : (
                  <GoogleLogin
                    clientId='308668390355-l50jt9oqghcuqshm45p517khtk95dfih.apps.googleusercontent.com'
                    onSuccess={this.responseGoogleSuccess}
                    onFailure={this.responseGoogleFailure}
                    onRequest={this.handleLoader}
                    theme='dark'
                  />
                )}
              </div>
            </LoginBox>
          </div>
        </React.Fragment>
      );
    } else {
      return <CompleteRegistration />;
    }
  }
}

export default withRouter(Login);

const Logo = styled.img`
  display: block;
  width: 300px;
  height: 70px;
  margin-bottom: 10px;
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 80px;
`;

const LoginBox = styled.div`
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 2px;
  padding: 40px 50px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
