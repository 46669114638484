import React from 'react';
import {
  Card,
  Form,
  Button,
  Input,
  Select,
  Icon,
  message as showMessage,
  Row,
  Col,
} from 'antd';
import { getData, patchData } from '../../helper/dataService';
import API from '../../helper/api';
import { objDifferenceWith } from '../../helper/utility';
import Spinner from '../common/Spinner';

class DeviceAdd extends React.Component {
  state = {
    data: {},
    assetTypes: [],
    submitted: false,
    fetching: true,
  };

  EDITABLE_FIELDS = [];

  ORiGINAL_DATA = {};

  componentDidMount() {
    this.fetchDeviceData();
    this.fetchDeviceTypes();
  }

  fetchDeviceData = () => {
    getData(API.device.editOrView(this.props.match.params.id))
      .then(({ data: { data, status, editable } }) => {
        if (status === 'success') {
          this.EDITABLE_FIELDS = [...editable];
          const { model, status, remarks } = data;
          this.props.form.setFieldsValue({
            model,
            status,
            remarks,
          });

          editable.forEach((item) => {
            this.ORiGINAL_DATA[item] = data[item];
          });

          this.setState({ data });
        } else if (status === 'failed') {
          showMessage.error('Sorry! Something went wrong');
        }
        this.setState({ fetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ fetching: false });
      });
  };

  fetchDeviceTypes = () => {
    const { assetTypes } = this.state;

    if (Array.isArray(assetTypes) && assetTypes.length === 0) {
      this.setState({ assetTypes: 'loading' });
      getData(API.device.type)
        .then(({ data: { data, status, message } }) => {
          if (status === 'success') {
            this.setState({ assetTypes: data });
          } else {
            showMessage.error(message);
          }
        })
        .catch(() => showMessage.error('Sorry! Something went wrong'));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = objDifferenceWith(this.ORiGINAL_DATA, values);

        if (Object.keys(data).length > 0) {
          this.setState({ submitted: true });
          patchData(API.device.editOrView(this.props.match.params.id), data)
            .then(({ data: { status, display } }) => {
              this.ORiGINAL_DATA = { ...this.ORiGINAL_DATA, ...data };

              if (status === 'success') {
                showMessage.success(display);
              } else if (status === 'failed') {
                showMessage.error(display);
              }
              this.setState({ submitted: false });
            })
            .catch(() => {
              showMessage.error('Sorry! Something went wrong');
              this.setState({ submitted: false });
            });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const status = getFieldValue('status');

    const {
      assetTypes,
      submitted,
      fetching,
      data: { imei, device_id },
    } = this.state;

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='cluster' />
                {' Edit Device'}
              </span>
            }
          >
            <Card loading={fetching}>
              <Form layout='vertical'>
                <Form.Item label='Device ID' {...formItemLayout}>
                  {device_id}
                </Form.Item>
                <Form.Item label='IMEI' {...formItemLayout}>
                  {imei}
                </Form.Item>
              </Form>
            </Card>
            <br />
            <Card loading={fetching}>
              <Form onSubmit={this.handleSubmit} layout='vertical'>
                <Form.Item {...formItemLayout} label='Model'>
                  {getFieldDecorator(
                    'model',
                    {}
                  )(
                    <Select
                      placeholder='Select device model'
                      onFocus={this.fetchDeviceTypes}
                      notFoundContent={
                        assetTypes === 'loading' ? <Spinner /> : null
                      }
                      disabled={!this.EDITABLE_FIELDS.includes('model')}
                    >
                      {Array.isArray(assetTypes) &&
                        assetTypes.map(
                          ({ device_model, device_name, display }, index) => (
                            <Select.Option
                              key={device_model + index}
                              value={device_model}
                            >
                              {`${device_model} [${device_name}] [${display}]`}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item {...formItemLayout} label='Status'>
                  {getFieldDecorator(
                    'status',
                    {}
                  )(
                    <Select
                      placeholder='Select device status'
                      disabled={status === 'used'}
                    >
                      <Select.Option key='available'>Available</Select.Option>
                      <Select.Option key='unavailable'>
                        Unavailable
                      </Select.Option>
                      {status !== 'used' ? null : (
                        <Select.Option key='used'>Used</Select.Option>
                      )}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label='Remarks' {...formItemLayout}>
                  {getFieldDecorator('remarks', {
                    rules: [
                      {
                        max: 250,
                        message: 'The input not more than 250 characters.',
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder='Remarks'
                      disabled={!this.EDITABLE_FIELDS.includes('remarks')}
                    />
                  )}
                </Form.Item>

                <br />
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  style={{ float: 'right' }}
                  loading={submitted}
                >
                  SUBMIT
                </Button>
              </Form>
            </Card>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(DeviceAdd);
