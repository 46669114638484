import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';

import { PermissionContext } from '../../../permissionContext';

class OrderDataTable extends React.Component {
  static contextType = PermissionContext;

  render() {
    const { data, isLoading } = this.props;
    const { checkSubSectionPermission } = this.context;

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='shopping-cart' />
              &nbsp;
              {'List of Orders'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={isLoading}
          dataSource={data}
          rowKey={({ _id }) => _id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='ID' dataIndex='reference_id' />
          <Table.Column title='Name' dataIndex='customer.name' />
          <Table.Column title='Mobile' dataIndex='customer.mobile' />
          <Table.Column title='E-mail' dataIndex='customer.email' />
          <Table.Column title='Total' dataIndex='total_amount' />
          <Table.Column title='Status' dataIndex='status' />

          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={({ _id }) => (
              <Button.Group>
                {checkSubSectionPermission('orders', 'edit') && (
                  <Button type='primary' ghost>
                    <Link to={`/orders/edit/${_id.$oid}`}>Edit</Link>
                  </Button>
                )}
              </Button.Group>
            )}
          />
        </Table>
      </React.Fragment>
    );
  }
}

OrderDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default OrderDataTable;
