import { Button, Input, message as showMessage, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getData } from '../helper/dataService';
import { getValueFromUrl } from '../helper/utility';

class Pagination extends React.Component {
  state = {
    pageNumber: null,
    maxPage: 0,
    pageLimit: null,
    gotoValue: null,
    isClicked: true,
    search: null,
    searchTypeText: '',
    country: '',
  };

  componentDidMount() {
    this.setState({
      pageNumber: Number(getValueFromUrl('page')) || 1,
      pageLimit: Number(getValueFromUrl('limit')) || 10,
    });

    if (getValueFromUrl('search')) {
      this.setState({
        search: getValueFromUrl('search'),
        searchTypeText: getValueFromUrl('search'),
      });
    }

    if (getValueFromUrl('country')) {
      this.setState({ country: getValueFromUrl('country') || 'all' });
    }
  }

  componentDidUpdate(_, prevState) {
    const { pageNumber, pageLimit, search, country } = this.state;
    // Any of these state change fetch data
    if (
      prevState.pageNumber !== pageNumber ||
      prevState.pageLimit !== pageLimit ||
      prevState.search !== search ||
      prevState.country !== country
    ) {
      this.getTable();
    }
  }

  getTable = () => {
    // Start loader, clear current data and disable all button
    this.setState({ isClicked: true });
    const { path, saveData } = this.props;
    saveData([], true);
    const { pageNumber, pageLimit, search, country } = this.state;

    // Get URL path and function to save data in parent component.

    // Generate URL
    let url = `${path}?page=${pageNumber}&limit=${pageLimit}`;
    url = search ? `${url}&search=${search}` : url;
    url = country ? `${url}&country=${country}` : url;

    getData(url)
      .then(
        ({
          data: {
            data: { data, total, limit, page },
            status,
            display,
          },
        }) => {
          if (status === 'success') {
            this.setState({
              maxPage: Math.ceil(total / limit),
              gotoValue: null,
            });

            // Send data to parent

            const dataWithNo = data.map((item, index) => ({
              ...item,
              no: limit * (page - 1) + index + 1,
            }));
            saveData(dataWithNo, false);
          } else if (status === 'failed') {
            showMessage.info(display);
          }
          this.setState({ isClicked: false });
        }
      )
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isClicked: false });
      });

    // Set URL
    let pathname = window.location.pathname;
    pathname += `?page=${pageNumber}&limit=${pageLimit}`;
    if (search) {
      pathname += `&search=${search}`;
    }
    this.props.history.push(pathname);
  };

  handleReload = () => {
    // Refresh data
    this.setState({
      isClicked: true,
    });
    this.getTable();
  };

  handlePageNumber = (value) => {
    this.setState((prevState) => {
      // value 'next' or 'prev'
      const pageNumber =
        value === 'next' ? prevState.pageNumber + 1 : prevState.pageNumber - 1;
      if (pageNumber > 0 && pageNumber <= prevState.maxPage) {
        return { pageNumber };
      }
      return { pageNumber: prevState.pageNumber };
    });
  };

  handleGotoPageNumber = (e) => {
    const value = Number(e.target.value);
    this.setState((prevState) => {
      if (value > 0 && value <= prevState.maxPage) {
        return { pageNumber: value };
      }
      return { pageNumber: prevState.pageNumber };
    });
  };

  handleGotoValue = (e) => {
    // Without number value will NaN
    const value = Number(e.target.value);
    // Check NaN type and set value
    this.setState({
      gotoValue:
        !isNaN(value) && value > 0 && value <= this.state.maxPage
          ? value
          : null,
    });
  };

  handleLimit = (value) => {
    // Set page limit
    this.setState({ pageLimit: value, pageNumber: 1 });
  };

  handleSearch = (value) => {
    // Set search value and reset gotoValue and pageNumber to default
    this.setState({
      search: value,
      gotoValue: null,
      pageNumber: 1,
    });
    // const pathname = window.location.pathname;
    // if (value) {
    //   this.props.history.push(`${pathname}?search=${value}`);
    // } else {
    //   this.props.history.push(`${pathname}`);
    // }
  };

  handleSearchInput = (e) => {
    const { value } = e.target;
    if (value.length > 50) {
      showMessage.warning('Search input more than 50 characters');
    } else if (value.length <= 50) {
      this.setState({
        searchTypeText: value,
      });
    }
  };

  handleCountry = (value) => {
    this.setState({ country: value, pageNumber: 1 });
  };

  render() {
    const { isClicked, maxPage, pageNumber, gotoValue, searchTypeText } =
      this.state;

    const { filterByCountry } = this.props;

    return (
      <div>
        <Box>
          <SearchBar>
            <Input.Search
              placeholder='Search here...'
              onSearch={this.handleSearch}
              enterButton
              onChange={this.handleSearchInput}
              value={searchTypeText}
              allowClear
            />
          </SearchBar>

          <div>
            {filterByCountry ? (
              <Select
                defaultValue='all'
                onChange={this.handleCountry}
                disabled={isClicked}
                style={{ width: 120, margin: '0 20px' }}
              >
                <Select.Option value='all'>All</Select.Option>
                <Select.Option value='bd'>Bangladesh</Select.Option>
                <Select.Option value='np'>Nepal</Select.Option>
              </Select>
            ) : null}
            {maxPage > 0 && <span>{`Page ${pageNumber} of ${maxPage}`}</span>}
            &nbsp;&nbsp;&nbsp;
            <Button
              icon='left'
              onClick={() => this.handlePageNumber('prev')}
              disabled={!!(pageNumber === 1 || isClicked)}
            />
            &nbsp;&nbsp;&nbsp;
            <Button
              icon='right'
              onClick={() => this.handlePageNumber('next')}
              disabled={!!(pageNumber === maxPage || isClicked)}
            />
            &nbsp;&nbsp;&nbsp;
            <Select
              defaultValue='10'
              onChange={this.handleLimit}
              disabled={isClicked}
              style={{ width: 60 }}
            >
              <Select.Option value='10'>10</Select.Option>
              <Select.Option value='20'>20</Select.Option>
              <Select.Option value='30'>30</Select.Option>
              <Select.Option value='40'>40</Select.Option>
              <Select.Option value='50'>50</Select.Option>
            </Select>
            &nbsp;&nbsp;&nbsp;
            <Input
              onPressEnter={this.handleGotoPageNumber}
              onChange={this.handleGotoValue}
              addonBefore='Go to'
              style={{ width: 140, display: 'inline-block' }}
              value={gotoValue}
              disabled={isClicked}
              type='number'
              min={1}
              max={maxPage}
            />
            <Button
              icon='reload'
              onClick={this.handleReload}
              style={{
                marginLeft: '10px',
              }}
              disabled={isClicked}
            />
          </div>
        </Box>
      </div>
    );
  }
}

Pagination.propTypes = {
  path: PropTypes.string.isRequired, // API for fetch data
  saveData: PropTypes.func.isRequired, // Function for store data in parent
  filterByCountry: PropTypes.bool,
};

export default withRouter(Pagination);

const Box = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const SearchBar = styled.div`
  width: 500px;
  @media (max-width: 1200px) {
    width: 400px;
    margin-bottom: 20px;
  }
`;
