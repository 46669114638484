import React from 'react';
import {
  Card,
  Form,
  Button,
  Input,
  Switch,
  Select,
  Col,
  Row,
  Table,
  Popconfirm,
  message as showMessage,
  InputNumber,
  Icon,
  Spin,
  Tooltip,
} from 'antd';
import axios from 'axios';
import { getData, postData } from '../../helper/dataService';
import API from '../../helper/api';
import {
  mobileRegexFromPrefix,
  mobileAndPrefix,
  delayApiRequest,
} from '../../helper/utility';

class AddOrganization extends React.Component {
  state = {
    contactList: [' '],
    userData: [],
    userDataFetching: false,
    selectedUsers: [],
    filteredSelectedUsers: [],
    userSearchText: '',
    countryData: [],
    cityData: [],
    organizationTypes: [],
    loading: true,
    activeStatus: false,
    submitted: false,
    orgId: '',
    isLoading: false,
  };

  profileList = [
    { id: 1, name: 'General' },
    { id: 2, name: 'VIP' },
    { id: 3, name: 'Dangerous' },
    { id: 4, name: 'BadBehaviour' },
    { id: 5, name: 'Picky' },
  ];

  countryWithCity = [];

  componentDidMount() {
    this.fetchCommonData();
  }

  componentDidUpdate() {
    const { selectedUsers, activeStatus } = this.state;
    const { billingPersonName, billingPersonMobile, billingPersonEmail } =
      this.props.form.getFieldsValue();

    if (
      !billingPersonName ||
      !billingPersonMobile ||
      !billingPersonEmail ||
      !selectedUsers.length > 0
    ) {
      if (activeStatus) {
        this.handleActiveStatus(false);
      }
    }
  }

  fetchCommonData = () => {
    axios
      .all([getData(API.organization.types), getData(API.countryWithCity)])
      .then(
        axios.spread((types, cities) => {
          this.setState({ loading: false });
          this.saveOrgTypes(types);
          this.saveCountryAndCities(cities);
        })
      )
      .catch(() => {
        this.setState({ loading: false });
        showMessage.error('Sorry! Something went wrong');
      });
  };

  saveOrgTypes = ({ data: { status, message, data = [] } }) => {
    if (status === 'success') {
      this.setState({
        organizationTypes: data,
      });
    } else if (status === 'failed') {
      showMessage.error(message);
    }
  };

  saveCountryAndCities = ({ data: { status, message, data = [] } }) => {
    if (status === 'success') {
      this.countryWithCity = data;
      this.setState({
        countryData: data,
        cityData: data.find(({ code }) => code === 'bd'),
      });
      this.props.form.setFieldsValue({ country_code: 'bd' });
    } else if (status === 'failed') {
      showMessage.error(message);
    }
  };

  handleFetchUser = (userName) => {
    if (userName.length >= 4) {
      delayApiRequest(() => this.fetchUser(userName));
    }
  };

  fetchUser = (userName) => {
    this.setState({ userData: [], userDataFetching: true });

    getData(API.user.search(userName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ userData: data });
        }
        this.setState({ userDataFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ userDataFetching: false });
      });
  };

  changeCityList = (value) => {
    this.setState({
      cityData: this.countryWithCity.find(({ code }) => code === value),
    });
    this.props.form.setFieldsValue({
      billingPersonMobilePrefix: value === 'bd' ? '+880' : '+977',
    });
    this.clearCurrentMobile();
    this.props.form.resetFields(['city']);
  };

  addUser = (oid) => {
    this.setState((prevState) => {
      this.props.form.resetFields(['users']);
      if (!prevState.selectedUsers.some((item) => item._id.$oid === oid)) {
        const newUser = prevState.userData.find(
          (item) => item._id.$oid === oid
        );
        const adminStatus = newUser.hasOwnProperty('admin')
          ? newUser.admin
          : false;
        return {
          selectedUsers: [
            ...prevState.selectedUsers,
            { ...newUser, admin: adminStatus },
          ],
        };
      }
      return {};
    });
  };

  removeUser = (oid) => {
    this.setState((prevState) => {
      const filteredUsers = prevState.selectedUsers.filter(
        (item) => item._id.$oid !== oid
      );
      return { selectedUsers: filteredUsers };
    });
  };

  addAsBillingPerson = (oid) => {
    this.setState((prevState) => {
      this.props.form.resetFields([
        'billingPersonName',
        'billingPersonMobile',
        'billingPersonEmail',
        'billingPersonMobilePrefix',
      ]);

      const filteredUsers = prevState.selectedUsers.find(
        (item) => item._id.$oid === oid
      );
      const { name, mobile: originalNumber, email } = filteredUsers;

      this.props.form.setFieldsValue({
        billingPersonName: name,
        billingPersonEmail: email,
      });

      const { mobile, prefix } = mobileAndPrefix(originalNumber);
      if (mobile && prefix) {
        this.props.form.setFieldsValue({
          billingPersonMobile: mobile,
          billingPersonMobilePrefix: prefix,
        });
      }
    });
  };

  controlContactNumberForm = (action, index) => {
    this.setState((prevState) => {
      const { contactList } = prevState;

      if (contactList.length < 3 && action === 'add') {
        return { contactList: [...contactList, ' '] };
      }
      if (action === 'remove') {
        const contactNumbers = [
          ...this.props.form.getFieldValue('contactNumbers'),
        ];
        contactNumbers.splice(index, 1);
        this.props.form.setFieldsValue({ contactNumbers });
        contactList.splice(index, 1);
        return { contactList: [...contactList] };
      }
    });
  };

  controlAdminPermission = (oid) => {
    this.setState((prevState) => {
      const selectedUsers = prevState.selectedUsers.map((item) => {
        if (item._id.$oid === oid) {
          return { ...item, admin: !item.admin };
        }
        return item;
      });
      const filteredSelectedUsers = prevState.filteredSelectedUsers.map(
        (item) => {
          if (item._id.$oid === oid) {
            return { ...item, admin: !item.admin };
          }
          return item;
        }
      );
      return { selectedUsers, filteredSelectedUsers };
    });
  };

  handleActiveStatus = (activeStatus) => {
    this.setState({ activeStatus });
  };

  filterUser = (e) => {
    const value = e.target.value.toLowerCase();
    this.setState((prevState) => {
      let users = [];
      if (value) {
        const isInclude = (text) => {
          return text.toLowerCase().includes(value);
        };
        users = prevState.selectedUsers.filter(
          ({ name, mobile, email }) =>
            isInclude(name) || isInclude(mobile) || isInclude(email)
        );
      }
      return { userSearchText: value, filteredSelectedUsers: users };
    });
  };

  clearCurrentMobile = () => {
    this.props.form.resetFields(['billingPersonMobile']);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const { selectedUsers, activeStatus } = this.state;
      if (
        selectedUsers.length > 0 &&
        !selectedUsers.some((user) => user.admin)
      ) {
        showMessage.warning('Please make at least one admin from user list.');
        return;
      }

      if (!err) {
        this.setState({ submitted: true });

        const {
          name,
          address,
          country_code,
          profile,
          city,
          organization_type,
          discount,
          taxes,
          contactNumbers,
          billingPersonName,
          billingPersonEmail,
          billingPersonMobile,
          billingPersonMobilePrefix,
          remarks,
        } = values;

        const currentCountry = this.countryWithCity.find(
          ({ code }) => code === country_code
        );

        const mobile = billingPersonMobile
          ? billingPersonMobilePrefix + billingPersonMobile
          : '';

        const data = {
          name,
          address,
          country: currentCountry.name,
          city,
          organization_type,
          profile,
          discount,
          taxes,
          contact_numbers: contactNumbers.filter(
            (item) => item && Number(item)
          ),
          users: selectedUsers.map(({ _id, admin }) => ({
            user_oid: _id.$oid,
            admin,
          })),
          billing_person: {
            name: billingPersonName,
            mobile: mobile,
            email: billingPersonEmail,
          },
          remarks,
          active: activeStatus,
          country_code,
          currency: currentCountry.currency,
        };

        postData(API.organization.add, data)
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              showMessage.success(display);

              this.props.form.resetFields([
                'name',
                'address',
                'country_code',
                'city',
                'organization_type',
                'profile',
                'discount',
                'taxes',
                'contactNumbers',
                'billingPersonName',
                'billingPersonMobile',
                'billingPersonEmail',
                'remarks',
                'users',
              ]);

              this.setState({
                selectedUsers: [],
                userSearchText: '',
                filteredSelectedUsers: [],
                activeStatus: false,
                contactList: [' '],
                userData: [],
              });

              this.props.form.setFieldsValue({
                country_code: 'bd',
              });
              this.changeCityList('bd');
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  // Get sof data
  handleSearch = () => {
    const { orgId } = this.state;
    if (orgId.length === 0) {
      showMessage.error('SOF number is required');
      return;
    } else if (orgId.length < 1 || orgId.length > 5) {
      showMessage.error('Enter valid SOF number');
      return;
    } else if (isNaN(orgId)) {
      showMessage.error('SOF number must be digits');
      return;
    }
    this.setState({ isLoading: true });
    getData(API.organization.sof(orgId))
      .then((data) => {
        this.saveOrgData(data);
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ fetching: false });
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isLoading: false });
      });
  };

  saveOrgData = ({ data: { data, status } }) => {
    if (status === 'success') {
      this.setState({
        contactList:
          data.contact_numbers.length > 0
            ? data.contact_numbers.map((_) => ' ')
            : [' '],
      });

      const currentCountry =
        this.state.countryData.find((item) => item.name === data.country) || {};

      const cityName = data.hasOwnProperty('city')
        ? currentCountry.cities.includes(data.city)
          ? data.city
          : ''
        : '';

      if (data.hasOwnProperty('search_user')) {
        this.handleFetchUser(data.search_user);
      }

      this.props.form.setFieldsValue({
        name: data.hasOwnProperty('name') ? data.name : '',
        address: data.hasOwnProperty('address') ? data.address : '',
        country_code: currentCountry.hasOwnProperty('code')
          ? currentCountry.code
          : '',
        city: cityName,

        organization_type: data.hasOwnProperty('organization_type')
          ? data.organization_type
          : '',

        contactNumbers:
          data.contact_numbers.length > 0 ? data.contact_numbers : [],

        users: data.search_user ? data.search_user : '',
        remarks: data.hasOwnProperty('remarks') ? data.remarks : '',
      });
    } else {
      showMessage.error('Sorry! No data found');
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      contactList,
      countryData,
      cityData,
      userData,
      userDataFetching,
      selectedUsers,
      filteredSelectedUsers,
      userSearchText,
      loading,
      organizationTypes,
      activeStatus,
      submitted,
      orgId,
      isLoading,
    } = this.state;

    const {
      billingPersonName,
      billingPersonMobile,
      billingPersonEmail,
      billingPersonMobilePrefix,
    } = this.props.form.getFieldsValue();

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='bank' />
                {' Add Organization'}
              </span>
            }
            position='center'
            extra={
              <div style={{ width: '300px' }}>
                <Input.Search
                  placeholder='Input SOF number here'
                  onSearch={this.handleSearch}
                  onChange={(e) => this.setState({ orgId: e.target.value })}
                  value={orgId}
                  enterButton
                  disabled={isLoading}
                />
              </div>
            }
            loading={loading}
          >
            <Form onSubmit={this.handleSubmit} layout='vertical'>
              <Form.Item label='Name' {...formItemLayout}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input organization name',
                    },
                    {
                      min: 5,
                      message: 'The input is minimum 5 characters.',
                    },
                    {
                      max: 150,
                      message: 'The input is maximum 150 characters.',
                    },
                  ],
                })(<Input placeholder='Name' />)}
              </Form.Item>

              <Form.Item label='Address' {...formItemLayout}>
                {getFieldDecorator('address', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input address',
                    },
                    {
                      max: 250,
                      message: 'The input not more than 250 characters.',
                    },
                  ],
                })(<Input.TextArea rows={3} placeholder='Address' />)}
              </Form.Item>

              <Form.Item label='Country' {...formItemLayout}>
                {getFieldDecorator('country_code', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a country',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder='Select Country'
                    onChange={this.changeCityList}
                    optionFilterProp='name'
                  >
                    {countryData.map((item) => (
                      <Select.Option key={item.code} name={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='City' {...formItemLayout}>
                {getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a city',
                    },
                  ],
                })(
                  <Select showSearch placeholder='Select City'>
                    {cityData.hasOwnProperty('cities') &&
                      cityData.cities.map((item) => (
                        <Select.Option key={item}>{item}</Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label='Type' {...formItemLayout}>
                {getFieldDecorator('organization_type', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a organization type',
                    },
                  ],
                })(
                  <Select showSearch placeholder='Select Type'>
                    {organizationTypes.length > 0 &&
                      organizationTypes.map(({ name, display }) => (
                        <Select.Option key={name}>{display}</Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='Profile' {...formItemLayout}>
                {getFieldDecorator('profile', {
                  initialValue: 'General',
                  rules: [
                    {
                      required: true,
                      message: 'Please select profile',
                    },
                  ],
                })(
                  <Select placeholder='Select Profile'>
                    {this.profileList.map((item) => (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='Discount (%)' {...formItemLayout}>
                {getFieldDecorator('discount', {
                  initialValue: 0.0,
                  rules: [
                    {
                      required: true,
                      message: 'Please input discount',
                    },
                  ],
                })(
                  <InputNumber
                    placeholder='Discount'
                    min={0}
                    max={100}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>

              <Form.Item label='Taxes (%)' {...formItemLayout}>
                {getFieldDecorator('taxes', {
                  initialValue: 0.0,
                  rules: [
                    {
                      required: true,
                      message: 'Please input taxes',
                    },
                  ],
                })(
                  <InputNumber
                    placeholder='Taxes'
                    min={0}
                    max={100}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>

              {contactList.map((_item, index) => (
                <Form.Item
                  label={index === 0 ? 'Contact Numbers' : ' '}
                  {...formItemLayout}
                  key={index}
                  extra={
                    contactList.length < 3 &&
                    index === contactList.length - 1 ? (
                      <Button
                        size='small'
                        onClick={() => this.controlContactNumberForm('add')}
                      >
                        +
                      </Button>
                    ) : null
                  }
                >
                  {getFieldDecorator(`contactNumbers[${index}]`, {
                    rules: [
                      {
                        pattern: /^\d+$/,
                        message: 'The input is not a valid mobile number',
                      },
                      {
                        required: index === 0,
                        message: 'Please input contact numbers',
                      },
                    ],
                  })(
                    <Input
                      placeholder={`Contact Number [${index + 1}]`}
                      addonAfter={
                        index !== 0 ? (
                          <Icon
                            type='delete'
                            onClick={() =>
                              this.controlContactNumberForm('remove', index)
                            }
                          />
                        ) : null
                      }
                    />
                  )}
                </Form.Item>
              ))}

              <Form.Item label='User(s)' {...formItemLayout}>
                {getFieldDecorator(
                  'users',
                  {}
                )(
                  <Select
                    showSearch
                    placeholder='Search and Select Users'
                    filterOption={false}
                    notFoundContent={
                      userDataFetching ? (
                        <Spin size='small' />
                      ) : (
                        'No Content Found'
                      )
                    }
                    onSearch={this.handleFetchUser}
                    onChange={this.addUser}
                    defaultActiveFirstOption={false}
                  >
                    {userData.map(({ _id, name, mobile, email }) => (
                      <Select.Option key={_id.$oid}>
                        {name}
                        {mobile ? ', ' + mobile : ''}
                        {email ? ', ' + email : ''}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              {selectedUsers.length > 0 && (
                <Card
                  title={
                    <span>
                      <span>User: {selectedUsers.length} | </span>
                      <span>
                        Admin:{' '}
                        {selectedUsers.filter(({ admin }) => admin).length}
                      </span>
                      <span>
                        {filteredSelectedUsers.length
                          ? ` | Found: ${filteredSelectedUsers.length}`
                          : null}
                      </span>
                    </span>
                  }
                  extra={<Input.Search onChange={this.filterUser} allowClear />}
                  style={{ marginBottom: '15px' }}
                  bordered={false}
                >
                  <Table
                    dataSource={
                      userSearchText.length
                        ? filteredSelectedUsers
                        : selectedUsers
                    }
                    size='small'
                    rowKey={(record) => record._id.$oid}
                    bordered={false}
                    pagination={{ size: 'small' }}
                  >
                    <Table.Column title='Name' dataIndex='name' />
                    <Table.Column title='Email' dataIndex='email' />
                    <Table.Column title='Mobile' dataIndex='mobile' />
                    <Table.Column
                      title='Admin'
                      render={(value) => (
                        <Switch
                          checked={value.admin}
                          size='small'
                          onClick={() =>
                            this.controlAdminPermission(value._id.$oid)
                          }
                        />
                      )}
                    />
                    <Table.Column
                      title='Action'
                      dataIndex='_id'
                      render={(value) => (
                        <span>
                          <Popconfirm
                            title='Want to set as a billing person？'
                            okText='Yes'
                            cancelText='No'
                            onConfirm={() =>
                              this.addAsBillingPerson(value.$oid)
                            }
                            placement='bottomRight'
                          >
                            <Tooltip title='Add as a billing person'>
                              <Icon type='plus' />
                            </Tooltip>
                          </Popconfirm>
                          &nbsp; &nbsp;
                          <Popconfirm
                            title='Are you sure want to remove'
                            okText='Yes'
                            cancelText='No'
                            onConfirm={() => this.removeUser(value.$oid)}
                            placement='bottomRight'
                          >
                            <Tooltip title='Remove this user'>
                              <Icon type='delete' />
                            </Tooltip>
                          </Popconfirm>
                        </span>
                      )}
                    />
                  </Table>
                </Card>
              )}

              <Form.Item label='Billing Person Name' {...formItemLayout}>
                {getFieldDecorator(
                  'billingPersonName',
                  {}
                )(<Input placeholder='Full Name' />)}
              </Form.Item>

              <Form.Item label='Billing Person Mobile' {...formItemLayout}>
                {getFieldDecorator('billingPersonMobile', {
                  rules: [
                    {
                      pattern: mobileRegexFromPrefix(billingPersonMobilePrefix),
                      message: 'The input is not a valid mobile number',
                    },
                  ],
                })(
                  <Input
                    addonBefore={getFieldDecorator(
                      'billingPersonMobilePrefix',
                      {
                        initialValue: '+880',
                      }
                    )(
                      <Select
                        style={{ width: 80 }}
                        onChange={this.clearCurrentMobile}
                      >
                        <Select.Option value='+880'>+880</Select.Option>
                        <Select.Option value='+977'>+977</Select.Option>
                      </Select>
                    )}
                    placeholder='Mobile Number'
                  />
                )}
              </Form.Item>

              <Form.Item label='Billing Person Email' {...formItemLayout}>
                {getFieldDecorator('billingPersonEmail', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not a valid email',
                    },
                    {
                      max: 320,
                      message: 'The input not more than 320 characters.',
                    },
                  ],
                })(<Input placeholder='Email Address' />)}
              </Form.Item>

              <Form.Item label='Remarks' {...formItemLayout}>
                {getFieldDecorator('remarks', {
                  initialValue: '',
                  rules: [
                    {
                      max: 250,
                      message: 'The input not more than 250 characters.',
                    },
                  ],
                })(<Input.TextArea rows={4} placeholder='Remarks' />)}
              </Form.Item>

              <Form.Item label='Active' {...formItemLayout}>
                <Switch
                  checked={activeStatus}
                  onClick={() => this.handleActiveStatus(!activeStatus)}
                  disabled={
                    !(
                      billingPersonName &&
                      billingPersonMobile &&
                      billingPersonEmail &&
                      selectedUsers.length > 0
                    )
                  }
                />
              </Form.Item>

              <Button
                type='primary'
                size='large'
                htmlType='submit'
                style={{ float: 'right' }}
                loading={submitted}
              >
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(AddOrganization);
