import React from 'react';
import { Card } from 'antd';

import OrderDataTable from './OrderDataTable';
import Pagination from '../../Pagination';

import API from '../../../helper/api';

class OrderList extends React.Component {
  state = {
    data: '',
    isLoading: true,
  };

  saveData = (data, isLoading) => {
    this.setState({ data, isLoading });
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <Card style={{ marginBottom: '100px' }}>
        <Pagination path={API.order.list} saveData={this.saveData} />
        {data && <OrderDataTable data={data} isLoading={isLoading} />}
      </Card>
    );
  }
}

export default OrderList;
