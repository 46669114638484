import React from 'react';
import { Card, Icon, Button, message as showMessage, Select } from 'antd';

import Axios from 'axios';
// import { getData } from "../../helper/dataService";
// import API from "../../helper/api";

import Spinner from '../common/Spinner';

class DeviceLog extends React.Component {
  state = {
    dates: [],
    currentDate: '',
    filteredData: [],
    dataLoading: true,
    id: '',
    currentType: 'All',
  };

  data = [];

  componentDidMount() {
    const { id, type } = this.props.match.params;
    this.setState({ id });
    this.fetchList(id, type);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentDate } = this.state;
    if (currentDate && prevState.currentDate !== currentDate) {
      console.log('fetching log');
      this.fetchLog();
    }
  }

  fetchList = (id, type) => {
    Axios.get(
      'http://dev2.finder-lbs.com:9999/logs/x3/0351510090384242/list?access_token=8f4cdadf-8a5b-4543-9103-90cda32e1b25'
    )
      .then(({ data: { files, status, message } }) => {
        if (status === 'success') {
          this.setState({ dates: files, dataLoading: false });
        } else if (status === 'failed') {
        }
      })
      .catch(() => showMessage.error('Sorry! Something went wrong'));
  };

  fetchLog = () => {
    this.setState({ dataLoading: true, currentType: 'All' });
    const { currentDate } = this.state;
    Axios.get(
      `http://dev2.finder-lbs.com:9999/logs/x3/0351510090384242/${this.formateDate(
        currentDate
      )}?access_token=8f4cdadf-8a5b-4543-9103-90cda32e1b25`
    )
      .then(({ data }) => {
        const value = data.split('\n').reverse();
        this.data = [...value];
        this.setState({ dataLoading: false, filteredData: value });
      })
      .catch(() => showMessage.error('Sorry! Something went wrong'));
  };

  handleDate = (date) => {
    this.setState({ currentDate: this.findDate(date) });
  };

  onFilterChange = (value) => {
    let data;
    if (value === 'All') {
      data = [...this.data];
    } else if (value === 'Other') {
      data = this.data.filter(
        (item) =>
          !['HeartBeatData', 'LocationData', 'AlarmData'].some((type) =>
            item.includes(type)
          )
      );
    } else {
      data = this.data.filter(
        (item) => item.includes(value) || item.includes('log')
      );
    }

    this.setState({
      currentType: value,
      filteredData: data,
    });
  };

  findDate = (logDate) => {
    const value = logDate.split('.');
    return value.length === 3 ? value[2] : 'today';
  };

  formateDate = (date) => (date === 'today' ? date : date.split('-').join('_'));

  whichColor = (title) => {
    const colors = {
      HeartBeatData: '#845ef7',
      LocationData: '#cc5de8',
      AlarmData: '#ff6b6b',
    };
    return colors[title];
  };

  infoCard = (item, index) => {
    const info = item.split('0351510090384242');

    return (
      <div key={index}>
        {info.length > 1 &&
          (info.length === 2 && info[0] === '' ? (
            <Card
              style={{
                textAlign: 'center',
                textTransform: 'capitalize',
                marginBottom: '10px',
                color: '#20c997',
                fontSize: '20px',
              }}
              bordered={false}
            >
              <Icon type='info-circle' />
              {info[1]}
            </Card>
          ) : (
            <React.Fragment>
              <Card
                size='small'
                style={{
                  marginBottom: '15px',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    color: this.whichColor(info[0].split(' ')[0]),
                  }}
                >
                  {info[0]}
                </span>
                {info.splice(1)}
              </Card>
            </React.Fragment>
          ))}
      </div>
    );
  };

  render() {
    const {
      dataLoading,
      filteredData,
      currentDate,
      dates,
      id,
      currentType,
    } = this.state;
    if (dataLoading) {
      return (
        <Card title={`Log for ${id}`}>
          <Spinner />
        </Card>
      );
    }
    return (
      <Card title={`Log for ${id}`}>
        <Card>
          {dates.sort().map((item, index) => (
            <Button
              key={index}
              style={{ marginRight: '5px' }}
              onClick={() => this.handleDate(item)}
            >
              {this.findDate(item)}
            </Button>
          ))}
        </Card>
        <br />
        {currentDate && (
          <Card
            title={currentDate}
            extra={
              this.data.length > 0 ? (
                <span>
                  <Button
                    size='small'
                    icon='reload'
                    onClick={this.fetchLog}
                    style={{ marginRight: '10px' }}
                  />
                  <Select
                    size='small'
                    onChange={this.onFilterChange}
                    value={currentType}
                    style={{ width: '150px' }}
                  >
                    {[
                      'All',
                      'HeartBeatData',
                      'LocationData',
                      'AlarmData',
                      'Other',
                    ].map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </span>
              ) : null
            }
          >
            {filteredData.map((item, index) => this.infoCard(item, index))}
          </Card>
        )}
      </Card>
    );
  }
}

export default DeviceLog;
