import React from 'react';
import {
  Card,
  Form,
  Button,
  Input,
  Switch,
  Select,
  Row,
  Col,
  Icon,
  Modal,
  Tooltip,
  message as showMessage,
} from 'antd';
// import { Link } from 'react-router-dom';

import { postData, getData } from '../../helper/dataService';
import API from '../../helper/api';
import {
  nameFromId,
  mobileRegexFromPrefix,
  delayApiRequest,
} from '../../helper/utility';
import Spinner from '../common/Spinner';
import OrganizationDetails from '../organization/OrganizationDetails';

class AddUser extends React.Component {
  state = {
    orgData: [],
    assetsData: [],
    orgFetching: false,
    assetFetching: false,
    submitted: false,
    userTypes: [],
    showModal: '',
    orgId: '',
  };

  componentDidMount() {
    this.fetchUserTypes();
  }

  fetchUserTypes = () => {
    getData(API.user.types)
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ userTypes: data });
        }
      })
      .catch(() => showMessage.error('Sorry! Something went wrong'));
  };

  handleFetchOrg = (organizationName) => {
    if (organizationName.length >= 4) {
      delayApiRequest(() => this.fetchOrg(organizationName));
    }
  };

  fetchOrg = (organizationName) => {
    // Clear current selected organization oid and assets oids for FORM state
    this.props.form.setFieldsValue({
      organizations_oid: '',
      assets_oid: [],
    });

    // Clear current organization and assets list from DOM and replace with loader.
    this.setState({ orgData: [], assetsData: [], orgFetching: true });

    // Fetch organization names by searching
    getData(API.organization.search(organizationName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ orgData: data });
        }
        this.setState({ orgFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ orgFetching: false });
      });
  };

  fetchAssets = (organizationOid) => {
    // Start loader then fetch
    this.props.form.setFieldsValue({
      assets_oid: [],
    });
    this.setState({ assetsData: [], assetFetching: true });

    if (organizationOid) {
      getData(API.organization.assetList(organizationOid))
        .then(({ data: { data, status } }) => {
          if (status === 'success') {
            this.setState({
              assetsData: data,
            });
          }
          this.setState({ assetFetching: false });
        })
        .catch(() => {
          showMessage.error('Sorry! Something went wrong');
          this.setState({ assetFetching: false });
        });
    }
  };

  handleOrgChange = (value) => {
    // Fetch assets list when organization selection change
    this.fetchAssets(value);
  };

  clearOrg = () => {
    // Remove selected organization and assets from DOM and FORM state
    this.setState({
      orgData: [],
      assetsData: [],
    });
    this.props.form.setFieldsValue({
      organizations_oid: '',
      assets_oid: [],
    });
  };

  handleAssetSelectAll = () => {
    const { assetsData } = this.state;

    /*
    Remove button clicked: Unselect all if assets list and selected assets oid length is equal
    OTHERWISE
    Add button Clicked: Select all assets
     */

    this.props.form.setFieldsValue({
      assets_oid:
        assetsData.length === this.props.form.getFieldValue('assets_oid').length
          ? []
          : assetsData.map((item) => item._id.$oid),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitted: true });
        const { mobile, mobilePrefix } = values;

        const organizationsOidString = this.props.form.getFieldValue(
          'organizations_oid'
        );
        /*
        For future development purpose for multiple organization selection,
        send organization oid in array.
        */
        const organizations_oid = organizationsOidString
          ? [organizationsOidString]
          : undefined;

        postData(API.user.add, {
          ...values,
          mobile: mobilePrefix + mobile,
          organizations_oid,
        })
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              showMessage.success(display);

              // Clear current FORM state
              this.props.form.setFieldsValue({
                name: '',
                mobile: '',
                email: '',
                user_type: '',
                organizations_oid: '',
                assets_oid: [],
                remarks: '',
                sms_subscription: true,
                email_subscription: true,
              });

              // Clear current organization and asset list from DOM
              this.setState({
                orgData: [],
                assetsData: [],
              });
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  clearCurrentMobile = () => {
    this.props.form.setFieldsValue({ mobile: '' });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  showDetails = (orgId) => {
    this.setState({ orgId });
    this.handleModal();
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const {
      orgData,
      orgFetching,
      assetsData,
      assetFetching,
      submitted,
      userTypes,
      showModal,
      orgId,
    } = this.state;

    const assetsOid = getFieldValue('assets_oid');
    const organizationsOid = getFieldValue('organizations_oid');
    const mobilePrefix = getFieldValue('mobilePrefix');

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='team' />
                {' Add User'}
              </span>
            }
          >
            <Form onSubmit={this.handleSubmit} layout='vertical'>
              <Form.Item label='Name' {...formItemLayout}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      // eslint-disable-next-line no-useless-escape
                      pattern: /^(\(?([a-zA-Z\d]+)\s?\d*\/?\-?\.?\s?([a-zA-Z\d]+)\)?\d*\.?\s?)+$/,
                      message: 'The input is not a valid name',
                    },
                    {
                      required: true,
                      message: 'Please input full name',
                    },
                  ],
                })(<Input placeholder='Full Name' />)}
              </Form.Item>
              <Form.Item label='Mobile' {...formItemLayout}>
                {getFieldDecorator('mobile', {
                  rules: [
                    {
                      pattern: mobileRegexFromPrefix(mobilePrefix),
                      message: 'The input is not a valid mobile number',
                    },
                    {
                      required: true,
                      message: 'Please input mobile number',
                    },
                  ],
                })(
                  <Input
                    addonBefore={getFieldDecorator('mobilePrefix', {
                      initialValue: '+880',
                    })(
                      <Select
                        style={{ width: 80 }}
                        onChange={this.clearCurrentMobile}
                      >
                        <Select.Option value='+880'>+880</Select.Option>
                        <Select.Option value='+977'>+977</Select.Option>
                      </Select>
                    )}
                    placeholder='Mobile Number'
                  />
                )}
              </Form.Item>
              <Form.Item label='Email' {...formItemLayout}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not a valid email',
                    },
                    {
                      max: 320,
                      message: 'The input not more than 320 characters.',
                    },
                    {
                      required: true,
                      message: 'Please input email',
                    },
                  ],
                })(<Input placeholder='Email Address' />)}
              </Form.Item>

              <Form.Item label='User Type' {...formItemLayout}>
                {getFieldDecorator('user_type', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a user type',
                    },
                  ],
                })(
                  <Select
                    placeholder='Select User Type'
                    notFoundContent={userTypes.length <= 0 ? <Spinner /> : null}
                    showSearch
                  >
                    {userTypes.map((item) => (
                      <Select.Option key={item.name}>
                        {item.display}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              {/* Organization Start */}
              <Form.Item
                label='Organization'
                extra={
                  organizationsOid ? (
                    <Button.Group size='small'>
                      <Button
                        onClick={() => this.showDetails(organizationsOid)}
                      >
                        {nameFromId(organizationsOid, orgData)}
                      </Button>
                      <Tooltip title='Remove organization'>
                        <Button icon='delete' onClick={this.clearOrg} />
                      </Tooltip>
                    </Button.Group>
                  ) : null
                }
                {...formItemLayout}
              >
                {getFieldDecorator(
                  'organizations_oid',
                  {}
                )(
                  <Select
                    showSearch
                    placeholder='Search and Select Organization'
                    filterOption={false}
                    notFoundContent={
                      orgFetching ? <Spinner /> : 'No Content Found'
                    }
                    onSearch={this.handleFetchOrg}
                    onChange={this.handleOrgChange}
                    defaultActiveFirstOption={false}
                  >
                    {orgData.length > 0 &&
                      orgData.map((item) => (
                        <Select.Option key={item._id.$oid}>
                          {item.name} [{item.organization_id}]
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              {/* Organization End */}

              {/* Assets Start */}
              <Form.Item
                label='Asset(s)'
                extra={
                  assetsData.length > 0 ? (
                    <Button
                      onClick={this.handleAssetSelectAll}
                      size='small'
                      type='ghost'
                    >
                      {assetsData.length === assetsOid.length
                        ? 'Remove all assets'
                        : 'Add all assets'}
                    </Button>
                  ) : null
                }
                {...formItemLayout}
              >
                {getFieldDecorator('assets_oid', {
                  rules: [
                    {
                      type: 'array',
                    },
                  ],
                })(
                  <Select
                    mode='multiple'
                    placeholder='Search and Select Asset(s)'
                    optionFilterProp='name'
                    maxTagCount={0}
                    maxTagPlaceholder={() =>
                      assetsOid.length === 1
                        ? '1 asset selected'
                        : `${assetsOid.length} assets selected`
                    }
                    notFoundContent={
                      // eslint-disable-next-line no-nested-ternary
                      organizationsOid ? (
                        assetFetching ? (
                          <Spinner />
                        ) : (
                          'No Content Found'
                        )
                      ) : (
                        'Select Organization First'
                      )
                    }
                  >
                    {assetsData.map((item) => (
                      <Select.Option
                        key={item._id.$oid}
                        value={item._id.$oid}
                        name={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              {/* Show selected assets list  */}
              {assetsData.length > 0 && assetsOid && (
                <Card
                  title='Selected Assets'
                  style={{ marginBottom: '24px' }}
                  bordered={false}
                >
                  {assetsOid.map((id) => (
                    <Button
                      key={id}
                      size='small'
                      href={`http://www.finder-lbs.com/admin#/assets/${id}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ margin: '5px' }}
                    >
                      {nameFromId(id, assetsData)}
                    </Button>
                  ))}
                </Card>
              )}
              {/* Assets End */}

              <Form.Item label='Remarks' {...formItemLayout}>
                {getFieldDecorator('remarks', {
                  initialValue: '',
                  rules: [
                    {
                      max: 250,
                      message: 'The input not more than 250 characters.',
                    },
                  ],
                })(<Input.TextArea rows={4} placeholder='Remarks' />)}
              </Form.Item>

              <Form.Item label='Email Subscription' {...formItemLayout}>
                {getFieldDecorator('email_subscription', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Switch />)}
              </Form.Item>

              <Form.Item label='SMS Subscription' {...formItemLayout}>
                {getFieldDecorator('sms_subscription', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Switch />)}
              </Form.Item>
              <Button
                type='primary'
                size='large'
                htmlType='submit'
                style={{ float: 'right' }}
                loading={submitted}
              >
                Submit
              </Button>
            </Form>
          </Card>
          {showModal && (
            <Modal
              title='Organization Details'
              visible
              onCancel={this.handleModal}
              footer={null}
              maskClosable={false}
            >
              <OrganizationDetails orgId={orgId} />
            </Modal>
          )}
        </Col>
      </Row>
    );
  }
}

export default Form.create()(AddUser);
