import React from "react";
import { Card, Checkbox, message as showMessage, Button, Modal } from "antd";
import { getData, patchData } from "../../helper/dataService";
import API from "../../helper/api";
import StaffDetails from "./StaffDetails";

class StaffPrivileges extends React.Component {
  state = {
    privileges: {},
    userPrivileges: {},
    loading: true,
    submitted: false,
    employee: {},
    showStaffDetails: false,
  };

  userPrivileges = {};

  staffOid = this.props.match.params.oid;

  componentDidMount() {
    this.getAllPrivileges();
  }

  getAllPrivileges = () => {
    getData(API.staff.privileges)
      .then(({ data: { data, status, message } }) => {
        if (status === "success") {
          this.setState({ privileges: data });
          this.getUserPrivileges();
        } else {
          showMessage.error(message);
        }
      })
      .catch(() => showMessage.error("Sorry! Something went wrong"));
  };

  getUserPrivileges = () => {
    getData(API.staff.userPrivileges(this.staffOid))
      .then(({ data: { data, status, message } }) => {
        if (status === "success") {
          // Create all section
          const allPrivileges = {};
          Object.keys(this.state.privileges).forEach((item) => {
            allPrivileges[item] = [];
          });
          // merge empty sections and user sections
          const finalPrivileges = { ...allPrivileges, ...data.privileges };
          this.userPrivileges = finalPrivileges;
          this.setState({
            userPrivileges: finalPrivileges,
            employee: { id: data.employee_id, oid: data._id.$oid },
          });
        } else {
          showMessage.error(message);
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        showMessage.error("Sorry! Something went wrong");
        this.setState({ loading: false });
      });
  };

  checkPermission = (section, action) => {
    const { userPrivileges } = this.state;
    return (
      userPrivileges.hasOwnProperty(section) &&
      userPrivileges[section].includes(action)
    );
  };

  handlePermission = (checked, section, action) => {
    this.setState((prevState) => {
      const userPrivileges = { ...prevState.userPrivileges };
      let updatedValue;
      // if checked add action otherwise remove action
      if (checked) {
        updatedValue = [...userPrivileges[section], action];
      } else {
        updatedValue = userPrivileges[section].filter(
          (access) => access !== action
        );
      }

      return {
        userPrivileges: { ...userPrivileges, [section]: updatedValue },
      };
    });
  };

  handleSubmit = (section) => {
    this.setState({ submitted: section });
    const { userPrivileges } = this.state;

    const data = {
      ...this.userPrivileges,
      [section]: userPrivileges[section],
    };
    const finalData = {};
    Object.keys(data).forEach((section) => {
      if (data[section].length) {
        finalData[section] = [...data[section]];
      }
    });

    patchData(API.staff.userPrivileges(this.staffOid), finalData)
      .then(({ data: { display, status } }) => {
        if (status === "success") {
          // update with updated value
          this.userPrivileges = { ...data };
          showMessage.success(display);
        } else if (status === "failed") {
          showMessage.warning(display);
        }
        this.setState({ submitted: false });
      })
      .catch(() => {
        showMessage.error("Sorry! Something went wrong");
        this.setState({ submitted: false });
      });
  };

  isSameArr = (main, change) => {
    let isSame = false;
    if (main && change) {
      isSame = JSON.stringify(main.sort()) === JSON.stringify(change.sort());
    }
    return isSame;
  };

  render() {
    const {
      privileges,
      userPrivileges,
      loading,
      submitted,
      employee,
      showStaffDetails,
    } = this.state;

    return (
      <>
        <Card
          title={
            <span>
              Staff Privileges{" "}
              {employee.id ? (
                <Button
                  size="small"
                  onClick={() => this.setState({ showStaffDetails: true })}
                >
                  {employee.id}
                </Button>
              ) : null}
            </span>
          }
          loading={loading}
        >
          {Object.keys(privileges).length > 0
            ? Object.keys(privileges)
                .sort()
                .map((section, sectionIndex) => (
                  <div key={sectionIndex}>
                    <Card title={privileges[section]._display}>
                      {Object.keys(privileges[section])
                        .sort()
                        .map((access, accessIndex) =>
                          access !== "_display" ? (
                            <Checkbox
                              key={accessIndex}
                              checked={this.checkPermission(section, access)}
                              onChange={(e) =>
                                this.handlePermission(
                                  e.target.checked,
                                  section,
                                  access
                                )
                              }
                            >
                              {privileges[section][access]}
                            </Checkbox>
                          ) : null
                        )}

                      <Button
                        size="small"
                        type="primary"
                        loading={submitted === section}
                        onClick={() => this.handleSubmit(section)}
                        style={{ float: "right" }}
                        disabled={
                          this.isSameArr(
                            this.userPrivileges[section],
                            userPrivileges[section]
                          ) || submitted
                        }
                      >
                        Submit
                      </Button>
                    </Card>
                    <br />
                  </div>
                ))
            : null}
        </Card>
        {showStaffDetails && (
          <Modal
            title="Staff Details"
            visible
            onCancel={() => this.setState({ showStaffDetails: false })}
            footer={null}
            maskClosable={false}
          >
            <StaffDetails staffId={employee.oid} />
          </Modal>
        )}
      </>
    );
  }
}

export default StaffPrivileges;
