import React from 'react';
import { Divider, message as showMessage } from 'antd';
import styled from 'styled-components';

import { getData } from '../helper/dataService';
import API from '../helper/api';

import Spinner from './common/Spinner';

class Dashboard extends React.Component {
  state = {
    isLoading: true,
    data: []
  };

  componentDidMount() {
    this.fetchDashboardData();
  }

  fetchDashboardData = () => {
    getData(API.dashboard)
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ data });
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isLoading: false });
      });
  };

  isPlural = (arrLength = 0, singularText = '') =>
    arrLength <= 1 ? singularText : `${singularText}s`;

  render() {
    const { isLoading, data } = this.state;
    return (
      <React.Fragment>
        <Divider>TODAY'S STATUS</Divider>
        {isLoading ? (
          <Spinner />
        ) : (
          <Content>
            {Object.entries(data).map(item => (
              <Card key={item}>
                <Number>{item[1]}</Number>
                <Text>{this.isPlural(item[1], item[0])}</Text>
              </Card>
            ))}
          </Content>
        )}
      </React.Fragment>
    );
  }
}

export default Dashboard;

const Content = styled.div`
  height: calc(100vh - 200px);
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 3px 1px 0px #343a40;
  padding: 10px;
  margin: 20px;
  text-align: center;
  width: 250px;
  color: ${props => props.color};
`;

const Number = styled.div`
  color: #343a40;
  font-size: 24px;
  letter-spacing: 3px;
  font-weight: 400;
`;

const Text = styled.div`
  color: #495057;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 10px;
  text-transform: uppercase;
`;
