import {
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  message as showMessage,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import API from '../../helper/api';
import { getData, patchData } from '../../helper/dataService';
import {
  delayApiRequest,
  mobileAndPrefix,
  mobileRegexFromPrefix,
  objDifferenceWith,
} from '../../helper/utility';
import UserDetails from '../user/UserDetails';

class EditOrganization extends React.Component {
  state = {
    contactList: [' '],
    userData: [],
    userDataFetching: false,
    selectedUsers: [],
    filteredSelectedUsers: [],
    userSearchText: '',
    countryData: [],
    cityData: [],
    organizationTypes: [],
    fetching: true,
    activeStatus: false,
    submitted: false,
    assetList: [],
    assetSearchText: '',
    filteredAssetList: [],
    orgId: '',
    showUserDetailsModal: false,
    userId: '',
  };

  ORG_OID = this.props.match.params.oid;

  countryWithCity = [];

  ORIGINAL_DATA = {};

  EDITABLE_FIELDS = [];

  assetInfo = '';

  profileList = [
    { id: 1, name: 'General' },
    { id: 2, name: 'VIP' },
    { id: 3, name: 'Dangerous' },
    { id: 4, name: 'BadBehaviour' },
    { id: 5, name: 'Picky' },
  ];

  componentDidMount() {
    this.fetchCommonData();
  }

  fetchCommonData = () => {
    axios
      .all([
        getData(API.organization.types),
        getData(API.countryWithCity),
        getData(API.organization.assetList(this.ORG_OID)),
        getData(API.organization.userList(this.ORG_OID)),
        getData(API.organization.editOrView(this.ORG_OID)),
      ])
      .then(
        axios.spread((types, cities, assets, users, organization) => {
          this.setState({ fetching: false });
          this.saveOrgTypes(types);
          this.saveCountryAndCities(cities);
          this.saveAssetList(assets);
          this.saveUserList(users);
          this.saveOrgData(organization);
        })
      )
      .catch(() => {
        this.setState({ fetching: false });
        showMessage.error('Sorry! Something went wrong');
      });
  };

  saveOrgTypes = ({ data: { status, message, data = [] } }) => {
    if (status === 'success') {
      this.setState({
        organizationTypes: data,
      });
    } else if (status === 'failed') {
      showMessage.error(message);
    }
  };

  saveCountryAndCities = ({ data: { status, message, data = [] } }) => {
    if (status === 'success') {
      this.countryWithCity = data;
      this.setState({
        countryData: data,
        cityData: data.find(({ code }) => code === 'bd'),
      });
      this.props.form.setFieldsValue({ country_code: 'bd' });
    } else if (status === 'failed') {
      showMessage.error(message);
    }
  };

  saveAssetList = ({ data: { status, message, data = [] } }) => {
    if (status === 'success') {
      this.setState({ assetList: data });
    } else if (status === 'failed') {
      showMessage.error(message);
    }
  };

  saveUserList = ({ data: { status, message, data = [] } }) => {
    if (status === 'success') {
      this.setState({
        selectedUsers: this.sortUsersByAdminStatus(
          this.sortUsersByAssetCount(data)
        ),
      });
      this.ORIGINAL_DATA.users = data.map(({ _id, admin }) => ({
        user_oid: _id.$oid,
        admin,
      }));
    } else if (status === 'failed') {
      showMessage.error(message);
    }
  };

  saveOrgData = ({ data: { data, editable, status } }) => {
    if (status === 'success') {
      const {
        name,
        address,
        country,
        city,
        organization_type,
        discount,
        taxes,
        profile,
        contact_numbers: contactNumbers,
        billing_person: {
          name: billingPersonName,
          email: billingPersonEmail,
          mobile: billingPersonMobile,
        },
        remarks,
        active,
        number_of_active_assets,
        number_of_assets,
        organization_id: orgId,
      } = data;

      this.assetInfo = `${number_of_active_assets} / ${number_of_assets}`;

      this.setState({
        contactList:
          contactNumbers.length > 0 ? contactNumbers.map((_) => ' ') : [' '],
        activeStatus: active,
      });

      const currentOrgType =
        this.state.organizationTypes.find(
          (item) => item.display === organization_type
        ) || {};

      const currentCountry =
        this.state.countryData.find((item) => item.name === country) || {};

      const cityName =
        currentCountry.hasOwnProperty('cities') &&
        currentCountry.cities.includes(city)
          ? city
          : '';

      if (currentCountry.hasOwnProperty('code')) {
        this.changeCityList(currentCountry.code);
      }

      const profileName =
        this.profileList.find((item) => item.name === profile) || {};

      let mobile;
      let prefix;
      if (
        billingPersonMobile &&
        (billingPersonMobile.includes('+880') ||
          billingPersonMobile.includes('+977'))
      ) {
        prefix = billingPersonMobile.includes('+977') ? '+977' : '+880';
        mobile = billingPersonMobile.slice(4);
      } else {
        prefix = '+880';
        mobile = '';
      }

      this.props.form.setFieldsValue({
        name,
        address,
        country_code: currentCountry.hasOwnProperty('code')
          ? currentCountry.code
          : '',
        city: cityName,
        organization_type: currentOrgType.hasOwnProperty('name')
          ? currentOrgType.name
          : '',
        profile: profileName.hasOwnProperty('name') ? profileName.name : '',
        discount,
        taxes,
        contactNumbers,
        billingPersonName,
        billingPersonMobile: mobile,
        billingPersonMobilePrefix: prefix,
        billingPersonEmail,
        remarks,
      });

      this.ORIGINAL_DATA = {
        name,
        address,
        country_code: currentCountry.hasOwnProperty('code')
          ? currentCountry.code
          : '',
        city: cityName,
        organization_type: currentOrgType.hasOwnProperty('name')
          ? currentOrgType.name
          : '',
        profile: profileName.hasOwnProperty('name') ? profileName.name : '',
        discount,
        taxes,
        contact_numbers: contactNumbers,
        users: this.state.selectedUsers.map(({ _id, admin }) => ({
          user_oid: _id.$oid,
          admin,
        })),
        billing_person: {
          name: billingPersonName,
          mobile: billingPersonMobile,
          email: billingPersonEmail,
        },
        remarks,
        active,
        country,
        currency: currentCountry.currency,
      };

      this.EDITABLE_FIELDS = [...editable];

      this.setState({ orgId, activeStatus: active });
    } else {
      showMessage.error('Sorry! Something went wrong');
    }
  };

  handleFetchUser = (userName) => {
    if (userName.length >= 4) {
      delayApiRequest(() => this.fetchUser(userName));
    }
  };

  fetchUser = (userName) => {
    this.setState({ userData: [], userDataFetching: true });

    getData(API.user.search(userName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ userData: data });
        }
        this.setState({ userDataFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ userDataFetching: false });
      });
  };

  changeCityList = (value) => {
    this.setState({
      cityData: this.countryWithCity.find(({ code }) => code === value),
    });
    this.props.form.resetFields(['city']);
  };

  addUser = (oid) => {
    this.setState((prevState) => {
      this.props.form.resetFields(['users']);
      if (!prevState.selectedUsers.some((item) => item._id.$oid === oid)) {
        const newUser = prevState.userData.find(
          (item) => item._id.$oid === oid
        );
        const adminStatus = newUser.hasOwnProperty('admin')
          ? newUser.admin
          : false;
        return {
          selectedUsers: [
            ...prevState.selectedUsers,
            { ...newUser, admin: adminStatus },
          ],
        };
      }
      return {};
    });
  };

  removeUser = (oid) => {
    this.setState((prevState) => {
      const filteredUsers = prevState.selectedUsers.filter(
        (item) => item._id.$oid !== oid
      );
      return { selectedUsers: filteredUsers };
    });
  };

  addAsBillingPerson = (oid) => {
    this.setState((prevState) => {
      this.props.form.resetFields([
        'billingPersonName',
        'billingPersonMobile',
        'billingPersonEmail',
        'billingPersonMobilePrefix',
      ]);

      const filteredUsers = prevState.selectedUsers.find(
        (item) => item._id.$oid === oid
      );
      const { name, mobile: originalNumber, email } = filteredUsers;

      this.props.form.setFieldsValue({
        billingPersonName: name,
        billingPersonEmail: email,
      });

      const { mobile, prefix } = mobileAndPrefix(originalNumber);
      if (mobile && prefix) {
        this.props.form.setFieldsValue({
          billingPersonMobile: mobile,
          billingPersonMobilePrefix: prefix,
        });
      }
    });
  };

  controlContactNumberForm = (action, index) => {
    this.setState((prevState) => {
      const { contactList } = prevState;

      if (contactList.length < 3 && action === 'add') {
        return { contactList: [...contactList, ' '] };
      }
      if (action === 'remove') {
        const contactNumbers = [
          ...this.props.form.getFieldValue('contactNumbers'),
        ];
        contactNumbers.splice(index, 1);
        this.props.form.setFieldsValue({ contactNumbers });
        contactList.splice(index, 1);
        return { contactList: [...contactList] };
      }
    });
  };

  controlAdminPermission = (oid) => {
    this.setState((prevState) => {
      const selectedUsers = prevState.selectedUsers.map((item) => {
        if (item._id.$oid === oid) {
          return { ...item, admin: !item.admin };
        }
        return item;
      });
      const filteredSelectedUsers = prevState.filteredSelectedUsers.map(
        (item) => {
          if (item._id.$oid === oid) {
            return { ...item, admin: !item.admin };
          }
          return item;
        }
      );
      return { selectedUsers, filteredSelectedUsers };
    });
  };

  handleActiveStatus = (activeStatus) => {
    this.setState({ activeStatus });
  };

  filterUser = (e) => {
    const value = e.target.value.toLowerCase();

    this.setState((prevState) => {
      let users = [];
      if (value) {
        users = prevState.selectedUsers.filter(
          ({ name, mobile, email }) =>
            name.toLowerCase().includes(value) ||
            mobile.toLowerCase().includes(value) ||
            email.toLowerCase().includes(value)
        );
      }
      return { userSearchText: value, filteredSelectedUsers: users };
    });
  };

  filterAsset = (e) => {
    const value = e.target.value.toLowerCase();

    this.setState((prevState) => {
      let assets = [];
      if (value) {
        assets = prevState.assetList.filter(({ name }) =>
          name.toLowerCase().includes(value)
        );
      }
      return { assetSearchText: value, filteredAssetList: assets };
    });
  };

  sortUsersByAdminStatus = (arr) => {
    const sortedArr = [];
    arr.forEach((item) => {
      if (item.admin) {
        sortedArr.unshift(item);
      } else {
        sortedArr.push(item);
      }
    });
    return sortedArr;
  };

  sortUsersByAssetCount = (arr) => {
    return arr.sort((a, b) => (a.assets < b.assets ? 1 : -1));
  };

  sortAssetsByActiveStatus = (arr) => {
    const sortedArr = [];
    arr.forEach((item) => {
      if (item.active) {
        sortedArr.unshift(item);
      } else {
        sortedArr.push(item);
      }
    });
    return sortedArr;
  };

  clearCurrentMobile = () => {
    this.props.form.resetFields(['billingPersonMobile']);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const { selectedUsers, activeStatus } = this.state;
      if (
        selectedUsers.length > 0 &&
        !selectedUsers.some((user) => user.admin)
      ) {
        showMessage.warning('Please make at least one admin from user list.');
        return;
      }

      if (!err) {
        const {
          name,
          address,
          country_code,
          profile,
          city,
          organization_type,
          discount,
          taxes,
          contactNumbers,
          billingPersonName,
          billingPersonEmail,
          billingPersonMobilePrefix,
          billingPersonMobile,
          remarks,
        } = values;

        const currentCountry = this.countryWithCity.find(
          ({ code }) => code === country_code
        );

        const mobile = billingPersonMobile
          ? billingPersonMobilePrefix + billingPersonMobile
          : '';

        const data = {
          name,
          address,
          country_code,
          city,
          organization_type,
          profile,
          discount,
          taxes,
          contact_numbers: contactNumbers.filter(
            (item) => item && Number(item)
          ),
          users: selectedUsers.map(({ _id, admin }) => ({
            user_oid: _id.$oid,
            admin,
          })),
          remarks,
          active: activeStatus,
          country: currentCountry.name,
          currency: currentCountry.currency,
        };

        if (billingPersonName || billingPersonEmail || billingPersonMobile) {
          data.billing_person = {
            name: billingPersonName || '',
            mobile: mobile || '',
            email: billingPersonEmail || '',
          };
        }

        const diffData = objDifferenceWith(this.ORIGINAL_DATA, data);

        if (Object.keys(diffData).length > 0) {
          this.setState({ submitted: true });
          patchData(API.organization.editOrView(this.ORG_OID), diffData)
            .then(({ data: { status, display } }) => {
              if (status === 'success') {
                this.ORIGINAL_DATA = { ...this.ORIGINAL_DATA, ...diffData };
                showMessage.success(display);
              } else if (status === 'failed') {
                showMessage.error(display);
              }
              this.setState({
                submitted: false,
                userSearchText: '',
                selectedUsers: this.sortUsersByAdminStatus(
                  this.sortUsersByAssetCount(selectedUsers)
                ),
                filteredSelectedUsers: [],
              });
            })
            .catch(() => {
              showMessage.error('Sorry! Something went wrong');
              this.setState({ submitted: false });
            });
        }
      }
    });
  };

  userDetailsModal = () => {
    this.setState((prevState) => ({
      showUserDetailsModal: !prevState.showUserDetailsModal,
    }));
  };

  viewUser = (userId) => {
    // set user oid and open modal to show user details
    this.setState({ userId });
    this.userDetailsModal();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      contactList,
      countryData,
      cityData,
      userData,
      userDataFetching,
      selectedUsers,
      filteredSelectedUsers,
      userSearchText,
      fetching,
      organizationTypes,
      activeStatus,
      submitted,
      assetList,
      assetSearchText,
      filteredAssetList,
      orgId,
      showUserDetailsModal,
      userId,
    } = this.state;

    const {
      billingPersonName,
      billingPersonMobile,
      billingPersonEmail,
      billingPersonMobilePrefix,
    } = this.props.form.getFieldsValue();

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div>
        <Row type='flex' justify='space-around' align='middle'>
          <Col xs={24} sm={18}>
            <Card
              title={
                <span className='title'>
                  <Icon type='bank' />
                  {' Edit Organization'}
                </span>
              }
              position='center'
              extra={<span>{orgId}</span>}
              loading={fetching}
            >
              <Form onSubmit={this.handleSubmit} layout='vertical'>
                <Form.Item label='Name' {...formItemLayout}>
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input organization name',
                      },
                      {
                        min: 5,
                        message: 'The input is minimum 5 characters.',
                      },
                      {
                        max: 150,
                        message: 'The input is maximum 150 characters.',
                      },
                    ],
                  })(
                    <Input
                      placeholder='Name'
                      disabled={!this.EDITABLE_FIELDS.includes('name')}
                    />
                  )}
                </Form.Item>

                <Form.Item label='Address' {...formItemLayout}>
                  {getFieldDecorator('address', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input address',
                      },
                      {
                        max: 250,
                        message: 'The input not more than 250 characters.',
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={3}
                      placeholder='Address'
                      disabled={!this.EDITABLE_FIELDS.includes('address')}
                    />
                  )}
                </Form.Item>

                <Form.Item label='Country' {...formItemLayout}>
                  {getFieldDecorator('country_code', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a country',
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      placeholder='Select Country'
                      onChange={this.changeCityList}
                      optionFilterProp='name'
                      disabled={!this.EDITABLE_FIELDS.includes('country')}
                    >
                      {countryData.map((item) => (
                        <Select.Option key={item.code} name={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label='City' {...formItemLayout}>
                  {getFieldDecorator('city', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a city',
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      placeholder='Select City'
                      disabled={!this.EDITABLE_FIELDS.includes('city')}
                    >
                      {cityData.hasOwnProperty('cities') &&
                        cityData.cities.map((item) => (
                          <Select.Option key={item}>{item}</Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label='Type' {...formItemLayout}>
                  {getFieldDecorator('organization_type', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a organization type',
                      },
                    ],
                  })(
                    <Select
                      placeholder='Select Type'
                      disabled={
                        !this.EDITABLE_FIELDS.includes('organization_type')
                      }
                    >
                      {organizationTypes.length > 0 &&
                        organizationTypes.map(({ name, display }) => (
                          <Select.Option key={name}>{display}</Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label='Profile' {...formItemLayout}>
                  {getFieldDecorator('profile', {
                    initialValue: 'General',
                    rules: [
                      {
                        required: true,
                        message: 'Please select profile',
                      },
                    ],
                  })(
                    <Select
                      placeholder='Select Profile'
                      disabled={!this.EDITABLE_FIELDS.includes('profile')}
                    >
                      {this.profileList.map((item) => (
                        <Select.Option key={item.id} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label='Discount (%)' {...formItemLayout}>
                  {getFieldDecorator('discount', {
                    initialValue: 0.0,
                    rules: [
                      {
                        required: true,
                        message: 'Please input discount',
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder='Discount'
                      min={0}
                      max={100}
                      style={{ width: '100%' }}
                      disabled={!this.EDITABLE_FIELDS.includes('discount')}
                    />
                  )}
                </Form.Item>

                <Form.Item label='Taxes (%)' {...formItemLayout}>
                  {getFieldDecorator('taxes', {
                    initialValue: 0.0,
                    rules: [
                      {
                        required: true,
                        message: 'Please input taxes',
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder='Taxes'
                      min={0}
                      max={100}
                      style={{ width: '100%' }}
                      disabled={!this.EDITABLE_FIELDS.includes('taxes')}
                    />
                  )}
                </Form.Item>

                {contactList.map((_, index) => (
                  <Form.Item
                    label={index === 0 ? 'Contact Numbers' : ' '}
                    {...formItemLayout}
                    key={index}
                    extra={
                      contactList.length < 3 &&
                      index === contactList.length - 1 ? (
                        <Button
                          size='small'
                          onClick={() => this.controlContactNumberForm('add')}
                        >
                          +
                        </Button>
                      ) : null
                    }
                  >
                    {getFieldDecorator(`contactNumbers[${index}]`, {
                      rules: [
                        {
                          pattern: /^\d+$/,
                          message: 'The input is not a valid mobile number',
                        },
                        {
                          required: index === 0,
                          message: 'Please input contact numbers',
                        },
                      ],
                    })(
                      <Input
                        placeholder={`Contact Number [${index + 1}]`}
                        addonAfter={
                          index !== 0 ? (
                            <Icon
                              type='delete'
                              onClick={() =>
                                this.controlContactNumberForm('remove', index)
                              }
                            />
                          ) : null
                        }
                        disabled={
                          !this.EDITABLE_FIELDS.includes('contact_numbers')
                        }
                      />
                    )}
                  </Form.Item>
                ))}

                <Form.Item label='User(s)' {...formItemLayout}>
                  {getFieldDecorator(
                    'users',
                    {}
                  )(
                    <Select
                      showSearch
                      placeholder='Search and Select Users'
                      filterOption={false}
                      notFoundContent={
                        userDataFetching ? (
                          <Spin size='small' />
                        ) : (
                          'No Content Found'
                        )
                      }
                      onSearch={this.handleFetchUser}
                      onChange={this.addUser}
                      defaultActiveFirstOption={false}
                      disabled={!this.EDITABLE_FIELDS.includes('users')}
                    >
                      {userData.map(({ _id, name, mobile, email }) => (
                        <Select.Option key={_id.$oid}>
                          {name}
                          {mobile ? ', ' + mobile : ''}
                          {email ? ', ' + email : ''}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                {selectedUsers.length > 0 && (
                  <Card
                    title={
                      <span>
                        <span>User: {selectedUsers.length} | </span>
                        <span>
                          Admin:{' '}
                          {selectedUsers.filter(({ admin }) => admin).length}
                        </span>
                        <span>
                          {filteredSelectedUsers.length
                            ? ` | Found: ${filteredSelectedUsers.length}`
                            : null}
                        </span>
                      </span>
                    }
                    extra={
                      <Input.Search
                        value={userSearchText}
                        onChange={this.filterUser}
                        allowClear
                      />
                    }
                    style={{ marginBottom: '15px' }}
                    bordered={false}
                  >
                    <Table
                      dataSource={
                        userSearchText.length
                          ? filteredSelectedUsers
                          : selectedUsers
                      }
                      size='small'
                      rowKey={(record) => record._id.$oid}
                      bordered={false}
                      pagination={{ size: 'small' }}
                    >
                      <Table.Column title='Name' dataIndex='name' />
                      <Table.Column title='Email' dataIndex='email' />
                      <Table.Column title='Mobile' dataIndex='mobile' />
                      <Table.Column title='Assets' dataIndex='assets' />
                      <Table.Column
                        title='Admin'
                        render={(value) => (
                          <Switch
                            checked={value.admin}
                            size='small'
                            onClick={() =>
                              this.controlAdminPermission(value._id.$oid)
                            }
                            disabled={!this.EDITABLE_FIELDS.includes('users')}
                          />
                        )}
                      />
                      <Table.Column
                        title='Action'
                        dataIndex='_id'
                        render={(value) => (
                          <span>
                            <Popconfirm
                              title='Want to set as a billing person？'
                              okText='Yes'
                              cancelText='No'
                              onConfirm={() =>
                                this.addAsBillingPerson(value.$oid)
                              }
                              disabled={!this.EDITABLE_FIELDS.includes('users')}
                              placement='bottomRight'
                            >
                              <Tooltip title='Add as a billing person'>
                                <Icon type='plus' />
                              </Tooltip>
                            </Popconfirm>
                            &nbsp; &nbsp;
                            <Popconfirm
                              title='Are you sure want to remove?'
                              okText='Yes'
                              cancelText='No'
                              onConfirm={() => this.removeUser(value.$oid)}
                              disabled={!this.EDITABLE_FIELDS.includes('users')}
                              placement='bottomRight'
                            >
                              <Tooltip title='Remove this user'>
                                <Icon type='delete' />
                              </Tooltip>
                            </Popconfirm>
                            &nbsp; &nbsp;
                            <Tooltip title='View user details'>
                              <Icon
                                type='eye'
                                onClick={() => this.viewUser(value.$oid)}
                              />
                            </Tooltip>
                            &nbsp; &nbsp;
                            <Tooltip title='Go to user edit page'>
                              <Link
                                to={`/users/edit/${value.$oid}`}
                                target='_blank'
                              >
                                <Icon type='edit' />
                              </Link>
                            </Tooltip>
                          </span>
                        )}
                      />
                    </Table>
                  </Card>
                )}

                <Form.Item label='Billing Person Name' {...formItemLayout}>
                  {getFieldDecorator(
                    'billingPersonName',
                    {}
                  )(
                    <Input
                      placeholder='Full Name'
                      disabled={
                        !this.EDITABLE_FIELDS.includes('billing_person')
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item label='Billing Person Mobile' {...formItemLayout}>
                  {getFieldDecorator('billingPersonMobile', {
                    rules: [
                      {
                        pattern: mobileRegexFromPrefix(
                          billingPersonMobilePrefix
                        ),
                        message: 'The input is not a valid mobile number',
                      },
                    ],
                  })(
                    <Input
                      addonBefore={getFieldDecorator(
                        'billingPersonMobilePrefix',
                        {
                          initialValue: '+880',
                        }
                      )(
                        <Select
                          style={{ width: 80 }}
                          onChange={this.clearCurrentMobile}
                        >
                          <Select.Option value='+880'>+880</Select.Option>
                          <Select.Option value='+977'>+977</Select.Option>
                        </Select>
                      )}
                      placeholder='Mobile Number'
                      disabled={
                        !this.EDITABLE_FIELDS.includes('billing_person')
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item label='Billing Person Email' {...formItemLayout}>
                  {getFieldDecorator('billingPersonEmail', {
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not a valid email',
                      },
                      {
                        max: 320,
                        message: 'The input not more than 320 characters.',
                      },
                    ],
                  })(
                    <Input
                      placeholder='Email Address'
                      disabled={
                        !this.EDITABLE_FIELDS.includes('billing_person')
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item label='Remarks' {...formItemLayout}>
                  {getFieldDecorator('remarks', {
                    initialValue: '',
                    rules: [
                      {
                        max: 250,
                        message: 'The input not more than 250 characters.',
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder='Remarks'
                      disabled={!this.EDITABLE_FIELDS.includes('remarks')}
                    />
                  )}
                </Form.Item>

                <Form.Item label='Active' {...formItemLayout}>
                  {this.EDITABLE_FIELDS.includes('active') ? (
                    <Switch
                      checked={activeStatus}
                      onClick={() => this.handleActiveStatus(!activeStatus)}
                      disabled={
                        !(
                          billingPersonName &&
                          billingPersonMobile &&
                          billingPersonEmail &&
                          selectedUsers.length > 0
                        )
                      }
                    />
                  ) : (
                    <Switch checked={activeStatus} disabled={true} />
                  )}
                </Form.Item>

                {assetList.length > 0 && (
                  <Card
                    title={
                      <span>
                        <span>Total Assets: [{this.assetInfo}]</span>
                        <span>
                          {filteredAssetList.length
                            ? ` | Found: ${filteredAssetList.length}`
                            : ''}
                        </span>
                      </span>
                    }
                    extra={
                      <Input.Search
                        value={assetSearchText}
                        onChange={this.filterAsset}
                        allowClear
                      />
                    }
                    style={{ marginBottom: '15px' }}
                    bordered={false}
                  >
                    {/* {this.sortAssetsByActiveStatus(assetList).map(
                    ({ _id, name, active }) => (
                      <Button
                        key={_id.$oid}
                        size='small'
                        href={`http://www.finder-lbs.com/admin#/assets/${_id.$oid}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ margin: '5px' }}
                        type={active ? 'default' : 'danger'}
                        ghost={!active}
                      >
                        {name}
                      </Button>
                    )
                  )} */}
                    <Table
                      dataSource={this.sortAssetsByActiveStatus(
                        assetSearchText ? filteredAssetList : assetList
                      )}
                      size='small'
                      rowKey={(record) => record._id.$oid}
                      bordered={false}
                      pagination={{ size: 'small' }}
                    >
                      <Table.Column title='Name' dataIndex='name' />
                      <Table.Column
                        title='Allow View'
                        dataIndex='allow_view'
                        render={(allow) =>
                          allow ? (
                            <Icon
                              type='check-circle'
                              theme='twoTone'
                              twoToneColor='#52c41a'
                            />
                          ) : (
                            <Icon
                              type='close-circle'
                              theme='twoTone'
                              twoToneColor='#eb2f96'
                            />
                          )
                        }
                        align='center'
                      />
                      <Table.Column
                        title='Active'
                        dataIndex='active'
                        render={(active) =>
                          active ? (
                            <Icon
                              type='check-circle'
                              theme='twoTone'
                              twoToneColor='#52c41a'
                            />
                          ) : (
                            <Icon
                              type='close-circle'
                              theme='twoTone'
                              twoToneColor='#eb2f96'
                            />
                          )
                        }
                        align='center'
                      />
                      <Table.Column
                        title='Action'
                        dataIndex='_id'
                        render={(value) => (
                          <Tooltip title='Go to asset edit page'>
                            <a
                              href={`http://www.finder-lbs.com/admin#/assets/${value.$oid}`}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <Icon type='edit' />
                            </a>
                          </Tooltip>
                        )}
                        align='center'
                      />
                    </Table>
                  </Card>
                )}

                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  style={{ float: 'right' }}
                  loading={submitted}
                >
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* Show user details in modal */}
        {showUserDetailsModal && (
          <Modal
            title='User Details'
            visible
            onCancel={this.userDetailsModal}
            footer={null}
            maskClosable={false}
          >
            <UserDetails userId={userId} />
          </Modal>
        )}
      </div>
    );
  }
}

export default Form.create()(EditOrganization);
