// Local Storage
export const setItem = (key, value) => {
  window.localStorage.setItem(key, value);
};
export const getItem = (key) => window.localStorage.getItem(key);

export const removeItem = (key) => window.localStorage.removeItem(key);

// Object difference check
export const objDifferenceWith = (mainObj = {}, changeObj = {}) => {
  const diffObj = {};
  Object.keys(mainObj).forEach((key) => {
    if (JSON.stringify(mainObj[key]) !== JSON.stringify(changeObj[key])) {
      if (changeObj.hasOwnProperty(key)) {
        diffObj[key] = changeObj[key];
      }
    }
  });
  return diffObj;
};

// organization, asset ids to find name
export const nameFromId = (id, obj = []) => {
  for (let i = 0; i < obj.length; i += 1) {
    if (obj[i]._id.$oid === id) {
      const name = obj[i].name;
      const org_id = obj[i].organization_id
        ? ` [${obj[i].organization_id}]`
        : '';
      return name + org_id;
    }
  }
  return '';
};

// Modify name by length
export const getName = (length = 2, name = getItem('name')) =>
  name ? name.split(' ').splice(0, length).join(' ') : '';

// Mobile
export const mobileRegexFromPrefix = (prefix) => {
  if (prefix === '+977') {
    return /^9[6-8]\d{8}$/;
  }
  return /^1[3-9]\d{8}$/;
};

export const mobileAndPrefix = (mobile) => {
  if (mobile.length === 14) {
    const asArray = mobile.split('');
    return {
      mobile: asArray.splice(4).join(''),
      prefix: asArray.splice(0, 4).join(''),
    };
  }
  return { mobile: null, prefix: null };
};

// Query string handle
export const getValueFromUrl = (key = '') => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  if (params.has(key)) {
    return params.get(key);
  }
  return null;
};

// delay api request
let requestTimeout = null;
export const delayApiRequest = (apiCallBack) => {
  clearTimeout(requestTimeout);
  requestTimeout = setTimeout(() => {
    apiCallBack();
  }, 1000);
};
