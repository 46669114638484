import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, message as showMessage } from 'antd';

import logout from '../../helper/Logout';
import { getName } from '../../helper/utility';

class ProfileMenu extends React.Component {
  userLogOut = () => {
    showMessage.info(`See you soon, ${getName()}`);
    logout();
  };

  render() {
    return (
      <Menu mode='horizontal' theme='dark' selectable={false}>
        <Menu.SubMenu
          title={
            <span>
              <Icon type='user' />
              {`${getName(1)}`}
            </span>
          }
        >
          <Menu.Item key='profile'>
            <Link to='/profile'>
              <Icon type='profile' />
              Profile
            </Link>
          </Menu.Item>
          <Menu.Item key='logout' onClick={this.userLogOut}>
            <Icon type='logout' />
            Logout
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );
  }
}
export default ProfileMenu;
