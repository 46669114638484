import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import styled from 'styled-components';

class NotificationCount extends React.Component {
  checkStatus = (feature = '', type = '') => {
    const { assetSetting } = this.props;
    // count only those asset have feature active
    const count = assetSetting.filter(item => item[feature] && item[feature][type]).length;
    return <span style={{ fontWeight: '500' }}>{count < 10 ? `0${count}` : count}</span>;
  };

  render() {
    return (
      <Form layout="inline">
        <FormBox>
          <Form.Item label="Power Cut" />
          <div>
            <Form.Item label="Email">{this.checkStatus('power_cut', 'email')}</Form.Item>
            <Form.Item label="SMS">{this.checkStatus('power_cut', 'sms')}</Form.Item>
            <Form.Item label="App">{this.checkStatus('power_cut', 'app')}</Form.Item>
          </div>
        </FormBox>

        <FormBox>
          <Form.Item label="Ignition" />
          <div>
            <Form.Item label="Email">{this.checkStatus('ignition', 'email')}</Form.Item>
            <Form.Item label="SMS">{this.checkStatus('ignition', 'sms')}</Form.Item>
            <Form.Item label="App">{this.checkStatus('ignition', 'app')}</Form.Item>
          </div>
        </FormBox>

        <FormBox>
          <Form.Item label="Over Speed" />
          <div>
            <Form.Item label="Email">{this.checkStatus('over_speed', 'email')}</Form.Item>
            <Form.Item label="SMS">{this.checkStatus('over_speed', 'sms')}</Form.Item>
            <Form.Item label="App">{this.checkStatus('over_speed', 'app')}</Form.Item>
          </div>
        </FormBox>

        <FormBox>
          <Form.Item label="Geofence" />
          <div>
            <Form.Item label="Email">{this.checkStatus('geofence', 'email')}</Form.Item>
            <Form.Item label="SMS">{this.checkStatus('geofence', 'sms')}</Form.Item>
            <Form.Item label="App">{this.checkStatus('geofence', 'app')}</Form.Item>
          </div>
        </FormBox>
      </Form>
    );
  }
}

NotificationCount.propTypes = {
  assetSetting: PropTypes.array.isRequired,
};

export default NotificationCount;

const FormBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
