import React from 'react';
import {
  Card,
  Table,
  Popconfirm,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Divider,
  Icon,
  message as showMessage,
} from 'antd';
import moment from 'moment';

import { getData, patchData } from '../../helper/dataService';
import API from '../../helper/api';
import Spinner from '../common/Spinner';

class StaffApprove extends React.Component {
  state = {
    isModalVisible: false,
    data: [],
    selectedStaffInfo: {},
    activeTab: 'unapproved',
    isLoading: true,
    departmentList: [],
    submitted: false,
  };

  componentDidMount() {
    this.fetchStaffApproveList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTab, isModalVisible, departmentList } = this.state;
    if (prevState.activeTab !== activeTab) {
      // Fetch staff user list for status change
      this.fetchStaffApproveList();
    } else if (isModalVisible === true && departmentList.length === 0) {
      // No need to load department list until modal open OR already fetched.
      this.fetchDepartmentList();
    }
  }

  fetchStaffApproveList = () => {
    // active tab means staff user status  - 'unapproved' OR 'rejected'
    // Get active tab name and start loader
    const { activeTab } = this.state;
    this.setState({ isLoading: true });
    getData(API.staff.approveList(activeTab))
      .then(({ data: { data = [], status = '' } }) => {
        this.setState({ isLoading: false });
        if (status === 'success') {
          this.setState({ data });
        } else if (status === 'failed') {
          showMessage.error('Sorry! Something went wrong');
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
        showMessage.error('Sorry! Something went wrong');
      });
  };

  fetchDepartmentList = () => {
    // get department list for staff
    getData(API.staff.departments)
      .then(({ data: { data = [], status = '' } }) => {
        if (status === 'success') {
          this.setState({ departmentList: data });
        }
      })
      .catch(() => {
        this.handleModal();
        showMessage.error('Sorry! Something went wrong');
      });
  };

  sendData = (oid = '', data = {}) => {
    // For update staff info must provide staff id
    this.setState({ submitted: true });
    patchData(API.staff.approve, { staff_oid: oid, ...data })
      .then(({ data: { status = '', message = '', display = '' } }) => {
        if (status === 'success') {
          // Remove this staff user from staff list so no need fetch data again.
          this.setState((prevState) => ({
            data: prevState.data.filter((item) => item._id.$oid !== oid),
          }));
          showMessage.success(display);
          if (message === 'user approved') {
            // Hide modal and clear form field state
            this.handleModal();
          }
        } else if (status === 'failed') {
          showMessage.error(display);
        }
        this.setState({ submitted: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ submitted: false });
      });
  };

  onTabChange = (activeTab) => {
    // set active tab and clear previous data
    this.setState({ activeTab, data: [] });
  };

  handleModal = () => {
    // Add / Remove  modal from DOM and remove alert
    this.setState((prevState) => ({
      isModalVisible: !prevState.isModalVisible,
    }));
  };

  rejectUser = ({ _id }) => {
    // For reject a user give boolean for 'approve'
    const data = { approved: false };
    this.sendData(_id.$oid, data);
  };

  approveUser = (selectedStaffInfo) => {
    // set staff info for user - 'name', 'email', 'oid'
    this.setState({
      selectedStaffInfo,
    });
    this.handleModal();
  };

  handleApproveSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          joining_date: moment(values.joining_date).format('DD-MM-YYYY'),
          approved: true,
        };
        this.sendData(this.state.selectedStaffInfo._id.$oid, data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const {
      isModalVisible,
      data,
      selectedStaffInfo,
      activeTab,
      isLoading,
      departmentList,
      submitted,
    } = this.state;
    return (
      <Card
        title={
          <span>
            <Icon type='solution' />
            &nbsp; Staff User Approve
          </span>
        }
        tabList={[
          { key: 'unapproved', tab: 'Unapproved' },
          { key: 'rejected', tab: 'Rejected' },
        ]}
        activeTabKey={activeTab}
        onTabChange={(key) => this.onTabChange(key)}
      >
        {/*  Show loader until data load otherwise show staff list */}

        <Table
          dataSource={data}
          rowKey={(record) => record._id.$oid}
          align='left'
          scroll={{ x: true }}
          bordered
          pagination={{
            position: 'top',
            hideOnSinglePage: true,
            pageSize: 20,
          }}
          loading={isLoading}
        >
          <Table.Column title='Name' dataIndex='name' />
          <Table.Column title='Email' dataIndex='email' />
          <Table.Column
            title='Action'
            render={(record) =>
              activeTab !== 'rejected' ? (
                <span>
                  <span
                    className='action'
                    onClick={() => this.approveUser(record)}
                  >
                    Approve
                  </span>
                  <Divider type='vertical' />
                  <Popconfirm
                    title='Are you sure?'
                    okText='Yes'
                    cancelText='No'
                    onConfirm={() => this.rejectUser(record)}
                    icon={false}
                    placement='bottomRight'
                  >
                    <span className='action'>Reject</span>
                  </Popconfirm>
                </span>
              ) : (
                <div
                  onClick={() => this.approveUser(record)}
                  className='action'
                >
                  Approve
                </div>
              )
            }
          />
        </Table>

        {/*  Show modal with form for approve user */}
        {isModalVisible && (
          <Modal
            title='Add Employee Info'
            visible
            onCancel={this.handleModal}
            footer={null}
            width='50vw'
            maskClosable={false}
          >
            {/* Show loader until department list fetched otherwise show form */}
            {departmentList.length > 0 ? (
              <Form onSubmit={this.handleApproveSubmit} layout='vertical'>
                <Form.Item label='Name' {...formItemLayout}>
                  {selectedStaffInfo.name}
                </Form.Item>
                <Form.Item label='Email' {...formItemLayout}>
                  {selectedStaffInfo.email}
                </Form.Item>
                <Form.Item label='Employee ID' {...formItemLayout}>
                  {getFieldDecorator('employee_id', {
                    rules: [
                      {
                        len: 4,
                        pattern: /^[1-9]\d{3}$/,
                        message: 'Input a valid employee id',
                      },
                      {
                        required: true,
                        message: 'Please input employee id',
                      },
                    ],
                  })(<Input type='number' placeholder='4 Digit Employee ID' />)}
                </Form.Item>

                <Form.Item label='Department' {...formItemLayout}>
                  {getFieldDecorator('department', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a department',
                      },
                    ],
                  })(
                    <Select placeholder='Select Department'>
                      {departmentList.map((item) => (
                        <Select.Option key={item.name}>
                          {item.display}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label='Designation' {...formItemLayout}>
                  {getFieldDecorator('designation', {
                    rules: [
                      {
                        min: 3,
                        max: 50,
                        message: 'Input must be between 3-50 characters',
                      },
                      {
                        required: true,
                        message: 'Please input designation',
                      },
                    ],
                  })(<Input placeholder='Designation' />)}
                </Form.Item>
                <Form.Item label='Joining Date' {...formItemLayout}>
                  {getFieldDecorator('joining_date', {
                    initialValue: null,
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please select a date',
                      },
                    ],
                  })(
                    <DatePicker
                      placeholder='Select Date'
                      format='DD-MM-YYYY'
                      showToday={false}
                      disabledTime
                      disabledDate={(current) =>
                        current > moment().endOf('day')
                      } // Disable future date input
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  size='large'
                  block
                  loading={submitted}
                >
                  Submit
                </Button>
              </Form>
            ) : (
              <Spinner />
            )}
          </Modal>
        )}
      </Card>
    );
  }
}

export default Form.create()(StaffApprove);
