import React from 'react';
import { Row, Col, Card, Form, Icon, message as showMessage } from 'antd';
import moment from 'moment';
import { getData } from '../../helper/dataService';
import API from '../../helper/api';

class Profile extends React.Component {
  state = {
    data: {},
    isLoading: true,
  };

  componentDidMount() {
    // Fetched staff details
    getData(API.auth.profileEditOrView)
      .then(({ data: { data } }) => {
        this.setState({
          isLoading: false,
          data,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        showMessage.error('Sorry! Something went wrong');
      });
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const {
      data: {
        name,
        email,
        email_verified,
        employee_id,
        mobile,
        mobile_verified,
        department,
        designation,
        birth_date,
        gender,
        blood_group,
        religion,
        joining_date,
      } = {},
      isLoading,
    } = this.state;

    return (
      <Row
        type='flex'
        justify='space-around'
        align='middle'
        style={{ padding: '25px 50px' }}
      >
        <Col xs={24} sm={18}>
          <Card
            title={
              <span>
                <Icon type='profile' /> User Profile
              </span>
            }
            loading={isLoading}
          >
            <Form layout='vertical'>
              <Form.Item label='Employee ID' {...formItemLayout}>
                {employee_id}
              </Form.Item>
              <Form.Item label='Name' {...formItemLayout} className='cap'>
                {name}
              </Form.Item>

              <Form.Item label='Email' {...formItemLayout}>
                <span className={email_verified ? 'active' : 'inactive'}>
                  {email}
                </span>
              </Form.Item>

              <Form.Item label='Mobile' {...formItemLayout}>
                <span className={mobile_verified ? 'active' : 'inactive'}>
                  {mobile}
                </span>
              </Form.Item>
              <Form.Item label='Department' {...formItemLayout} className='cap'>
                {department}
              </Form.Item>
              <Form.Item
                label='Designation'
                {...formItemLayout}
                className='cap'
              >
                {designation}
              </Form.Item>
              <Form.Item label='Date of Birth' {...formItemLayout}>
                {birth_date
                  ? moment(birth_date.$date).format('DD-MMM-YYYY')
                  : ''}
              </Form.Item>
              <Form.Item label='Gender' {...formItemLayout} className='cap'>
                {gender}
              </Form.Item>
              <Form.Item label='Blood Group' {...formItemLayout}>
                {blood_group}
              </Form.Item>
              <Form.Item label='Religion' {...formItemLayout} className='cap'>
                {religion}
              </Form.Item>
              <Form.Item label='Joining Date' {...formItemLayout}>
                {joining_date
                  ? moment(joining_date.$date).format('DD-MMM-YYYY')
                  : ''}
              </Form.Item>
            </Form>
            {/* )} */}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Profile;
