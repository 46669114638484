import React from 'react';
import { Card, Input, Icon, message as showMessage, Descriptions } from 'antd';
import { getData } from '../../helper/dataService';
import API from '../../helper/api';
import Spinner from '../common/Spinner';

class CheckBkash extends React.Component {
  state = {
    data: '',
    isLoading: false,
    txtId: ''
  };

  fetchData = () => {
    this.setState({ isLoading: true, data: '' });
    const { txtId } = this.state;
    getData(`${API.payments.bkash}/${txtId}`)
      .then(({ data: { status, display, data } }) => {
        if (status === 'success') {
          this.setState({ data, txtId: '' });
        } else if (status === 'failed') {
          showMessage.error(display);
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isLoading: false });
      });
  };

  handleSearch = () => {
    if (this.state.txtId.length >= 10) {
      this.fetchData();
    }
  };

  render() {
    const { data, isLoading, txtId } = this.state;

    return (
      <React.Fragment>
        <Card
          title={
            <span>
              <Icon type='safety' />
              {' Check bKash Transaction'}
            </span>
          }
        >
          <div style={{ width: '300px' }}>
            <Input.Search
              placeholder='Input transaction id here ...'
              onSearch={this.handleSearch}
              onChange={e =>
                this.setState({ txtId: e.target.value.toUpperCase() })
              }
              value={txtId}
              enterButton
              disabled={isLoading}
              allowClear
            />
          </div>
          <br />
          <br />
          <Card>
            {isLoading && <Spinner />}
            {data ? (
              <Descriptions title={`Transaction ID: ${data.trx_id}`}>
                <Descriptions.Item label='Number'>{`+${data.user_number}`}</Descriptions.Item>
                <Descriptions.Item label='Amount'>
                  {data.amount}
                </Descriptions.Item>
                <Descriptions.Item label='Time'>{data.time}</Descriptions.Item>
                <Descriptions.Item label='Type'>{data.type}</Descriptions.Item>
                {data.hasOwnProperty('reference') && (
                  <Descriptions.Item label='Reference'>
                    {data.reference}
                  </Descriptions.Item>
                )}
              </Descriptions>
            ) : (
              !isLoading && 'No Data Found'
            )}
          </Card>
        </Card>
      </React.Fragment>
    );
  }
}

export default CheckBkash;
