import { getData } from './dataService';
import API from './api';

const logout = () => {
  // Remove access token from server,
  getData(API.auth.logout);

  // remove saved user info from local storage
  window.localStorage.clear();

  // redirect to login page
  window.location.reload();
};

export default logout;
