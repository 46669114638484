import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { PermissionContext } from './permissionContext';

// Common
import Dashboard from './components/Dashboard';
import Error404 from './components/Error404';
import Navigation from './components/navigation/Navigation';

// About
import About from './components/about/About';
// Staff User
import StaffList from './components/staffUser/StaffList/StaffList';
import StaffApprove from './components/staffUser/StaffApprove';
import Profile from './components/auth/Profile';
import StaffPrivileges from './components/staffUser/StaffPrivileges';
// User
import UserList from './components/user/UserList/UserList';
import UserAdd from './components/user/UserAdd';
import UserEdit from './components/user/UserEdit';
// Notification
import Notification from './components/notification/Notification';
// Devices
import DeviceAdd from './components/device/DeviceAdd';
import DeviceEdit from './components/device/DeviceEdit';
import DeviceList from './components/device/DeviceList/DeviceList';
import DeviceLog from './components/device/DeviceLog';
// Organization
import OrganizationAdd from './components/organization/OrganizationAdd';
import OrganizationList from './components/organization/OrganizationList/OrganizationList';
import OrganizationEdit from './components/organization/OrganizationEdit';
// Payments
import CheckBkash from './components/payments/CheckBkash';
import OnlineReport from './components/payments/OnlineReport';
// Asset
import AssetList from './components/asset/AssetList/AssetList';
import AssetAdd from './components/asset/AssetAdd';
import AssetEdit from './components/asset/AssetEdit';

import OrderList from './components/order/OrderList/OrderList';
import OrderEdit from './components/order/OrderEdit';

class AuthApp extends React.Component {
  static contextType = PermissionContext;

  componentDidMount() {
    const { setPermission } = this.context;
    setPermission();
  }

  render() {
    const { loadingPermission, checkSubSectionPermission } = this.context;
    return (
      <div>
        <Layout>
          <Layout.Header
            style={{
              position: 'fixed',
              zIndex: 20000,
              width: '100%',
            }}
          >
            <Navigation />
          </Layout.Header>
          <Layout.Content style={{ padding: '25px 50px', marginTop: 60 }}>
            {loadingPermission ? (
              <div className='center-of-page-without-nav'>
                <Spin size='large' />
              </div>
            ) : (
              <Switch>
                {/* Dashboard */}
                <Route exact path='/' component={Dashboard} />

                {/* About */}
                <Route exact path='/about' component={About} />

                {/* Staff */}
                {checkSubSectionPermission('staff', 'list') && (
                  <Route exact path='/staff/list' component={StaffList} />
                )}
                {checkSubSectionPermission('staff', 'privileges') && (
                  <Route
                    exact
                    path='/staff/privileges/:oid'
                    component={StaffPrivileges}
                  />
                )}
                {checkSubSectionPermission('staff', 'approve') && (
                  <Route exact path='/staff/approve' component={StaffApprove} />
                )}
                <Route exact path='/profile' component={Profile} />

                {/* User */}
                {checkSubSectionPermission('users', 'list') && (
                  <Route exact path='/users/list' component={UserList} />
                )}
                {checkSubSectionPermission('users', 'add') && (
                  <Route exact path='/users/add' component={UserAdd} />
                )}
                {checkSubSectionPermission('users', 'edit') && (
                  <Route exact path='/users/edit/:oid' component={UserEdit} />
                )}
                {checkSubSectionPermission('users', 'notification') && (
                  <Route
                    exact
                    path='/users/notification/:oid'
                    component={Notification}
                  />
                )}

                {/* Devices */}
                {checkSubSectionPermission('devices', 'list') && (
                  <Route exact path='/devices/list' component={DeviceList} />
                )}
                {checkSubSectionPermission('devices', 'add') && (
                  <Route exact path='/devices/add' component={DeviceAdd} />
                )}
                {checkSubSectionPermission('devices', 'edit') && (
                  <Route
                    exact
                    path='/devices/edit/:id'
                    component={DeviceEdit}
                  />
                )}

                {checkSubSectionPermission('devices', 'log') && (
                  <Route
                    exact
                    path='/devices/log/:type/:id'
                    component={DeviceLog}
                  />
                )}

                {/* Organization */}
                {checkSubSectionPermission('organizations', 'list') && (
                  <Route
                    exact
                    path='/organizations/list'
                    component={OrganizationList}
                  />
                )}
                {checkSubSectionPermission('organizations', 'add') && (
                  <Route
                    exact
                    path='/organizations/add'
                    component={OrganizationAdd}
                  />
                )}
                {checkSubSectionPermission('organizations', 'edit') && (
                  <Route
                    exact
                    path='/organizations/edit/:oid'
                    component={OrganizationEdit}
                  />
                )}

                {/* Payments */}
                {checkSubSectionPermission('payments', 'reports') && (
                  <Route
                    exact
                    path='/payments/online-report'
                    component={OnlineReport}
                  />
                )}
                {checkSubSectionPermission('payments', 'bkash') && (
                  <Route exact path='/payments/bkash' component={CheckBkash} />
                )}

                {/* Assets */}
                {checkSubSectionPermission('assets', 'list') && (
                  <Route exact path='/assets/list' component={AssetList} />
                )}
                {checkSubSectionPermission('assets', 'add') && (
                  <Route exact path='/assets/add' component={AssetAdd} />
                )}
                {checkSubSectionPermission('assets', 'edit') && (
                  <Route exact path='/assets/edit/:oid' component={AssetEdit} />
                )}

                {/* Order */}
                {checkSubSectionPermission('orders', 'edit') && (
                  <Route exact path='/orders/edit/:oid' component={OrderEdit} />
                )}
                {checkSubSectionPermission('orders', 'list') && (
                  <Route exact path='/orders/list' component={OrderList} />
                )}

                {/* 404 PAGE */}
                <Route component={Error404} />
              </Switch>
            )}
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}

export default AuthApp;
