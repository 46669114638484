import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import OrganizationDetails from '../OrganizationDetails';
import { PermissionContext } from '../../../permissionContext';

class OrganizationDataTable extends React.Component {
  static contextType = PermissionContext;

  state = {
    showModal: false,
    orgId: '',
  };

  handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  showDetails = (orgId) => {
    this.setState({ orgId });
    this.handleModal();
  };

  render() {
    const { data, isLoading } = this.props;
    const { orgId, showModal } = this.state;
    const { checkSubSectionPermission } = this.context;

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='bank' />
              &nbsp;
              {'List of Organizations'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={isLoading}
          dataSource={data}
          rowKey={({ _id }) => _id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='ID' dataIndex='organization_id' />
          <Table.Column title='Name' dataIndex='name' />
          <Table.Column
            title='Contact Numbers'
            dataIndex='contact_numbers'
            render={(contact_numbers) =>
              contact_numbers.map((item, index) => (
                <span key={index}>
                  {item}
                  {index + 1 !== contact_numbers.length ? ', ' : ''}
                </span>
              ))
            }
          />
          <Table.Column
            title='Created On'
            dataIndex='created_on'
            render={(created_on) => (
              <span>
                {created_on
                  ? moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')
                  : ''}
              </span>
            )}
          />
          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={({ _id }) => (
              <Button.Group>
                {checkSubSectionPermission('organizations', 'view') && (
                  <Button
                    type='primary'
                    ghost
                    onClick={() => this.showDetails(_id.$oid)}
                  >
                    View
                  </Button>
                )}
                {checkSubSectionPermission('organizations', 'edit') && (
                  <Button type='primary' ghost>
                    <Link to={`/organizations/edit/${_id.$oid}`}>Edit</Link>
                  </Button>
                )}
              </Button.Group>
            )}
          />
        </Table>

        {showModal && (
          <Modal
            title='Organization Details'
            visible
            onCancel={this.handleModal}
            footer={null}
            maskClosable={false}
          >
            <OrganizationDetails orgId={orgId} />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

OrganizationDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default OrganizationDataTable;
