import React from "react";
import PropTypes from "prop-types";
import { Form, message as showMessage, Icon } from "antd";
import moment from "moment";

import { getData } from "../../helper/dataService";
import API from "../../helper/api";

import Spinner from "../common/Spinner";
import DividerWithColor from "../common/DividerWithColor";

class UserDetails extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };

  componentDidMount() {
    // Fetch user details
    getData(API.user.editOrView(this.props.userId))
      .then(({ data: { data, status } }) => {
        if (status === "success") {
          this.setState({ data });
        } else if (status === "failed") {
          showMessage.error("Sorry! Something went wrong");
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        showMessage.error("Sorry! Something went wrong");
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      isLoading,
      data: {
        created_by,
        created_on,
        user_type,
        email,
        email_subscription,
        email_verified,
        flagged,
        mobile,
        mobile_verified,
        modified_by,
        modified_on,
        name,
        number_of_assets,
        organizations,
        password,
        referral_code,
        remarks,
        sms_subscription,
        user_id,
      } = {},
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return isLoading ? (
      <Spinner />
    ) : (
      <Form layout="vertical">
        {flagged ? (
          <DividerWithColor>Account is Flagged</DividerWithColor>
        ) : null}

        <Form.Item label="User ID" {...formItemLayout}>
          {user_id}
        </Form.Item>

        <Form.Item
          label="Name"
          {...formItemLayout}
          extra={
            <span className={password ? "active" : "inactive"}>password</span>
          }
        >
          {name}
        </Form.Item>

        <Form.Item label="Mobile" {...formItemLayout}>
          {mobile ? (
            <span className={mobile_verified ? "active" : "inactive"}>
              {mobile}
            </span>
          ) : null}
        </Form.Item>

        <Form.Item label="Email" {...formItemLayout}>
          <span className={email_verified ? "active" : "inactive"}>
            {email}
          </span>
        </Form.Item>

        <Form.Item label="User Type" {...formItemLayout} className="cap">
          {user_type}
        </Form.Item>

        <Form.Item
          label="Organization"
          {...formItemLayout}
          extra={
            organizations.length > 0 ? (
              <span className={organizations[0].admin ? "active" : "inactive"}>
                Admin
              </span>
            ) : null
          }
        >
          {organizations.length > 0 ? (
            `${organizations[0].name} [${organizations[0].organization_id}]`
          ) : (
            <span className="blank">No Organization</span>
          )}
        </Form.Item>

        <Form.Item label="Number of Assets" {...formItemLayout}>
          {number_of_assets}
        </Form.Item>

        <Form.Item label="Referral Code" {...formItemLayout}>
          {referral_code}
        </Form.Item>

        <Form.Item label="Subscription" {...formItemLayout}>
          <span className={email_subscription ? "active" : "inactive"}>
            Email
          </span>
          &nbsp;&nbsp;
          <span className={sms_subscription ? "active" : "inactive"}>SMS</span>
        </Form.Item>

        {remarks && (
          <Form.Item label="Remarks" {...formItemLayout}>
            {remarks}
          </Form.Item>
        )}

        {created_on && (
          <Form.Item label="Created" {...formItemLayout}>
            <span>
              <Icon type="clock-circle" />{" "}
              {moment(created_on.$date).format("DD-MMM-YYYY hh:mm a")}
            </span>
            <br />
            <span>
              <Icon type="user" /> {created_by.name ? created_by.name : "User"}
            </span>
          </Form.Item>
        )}

        {modified_on && (
          <Form.Item label="Modified" {...formItemLayout}>
            {modified_on && (
              <span>
                <Icon type="clock-circle" />{" "}
                {moment(modified_on.$date).format("DD-MMM-YYYY hh:mm a")}
              </span>
            )}
            <br />
            <span>
              <Icon type="user" />{" "}
              {modified_by.name ? modified_by.name : "Not Available"}
            </span>
          </Form.Item>
        )}
      </Form>
    );
  }
}

UserDetails.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserDetails;
