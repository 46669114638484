import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Icon, Button } from 'antd';

import { PermissionContext } from '../../../permissionContext';

class StaffDataTable extends React.Component {
  static contextType = PermissionContext;

  render() {
    const { data, isLoading } = this.props;
    const { checkSubSectionPermission } = this.context;
    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='solution' />
              &nbsp;
              {'List of StaffS'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={isLoading}
          dataSource={data}
          rowKey={(record) => record._id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='ID' dataIndex='employee_id' />
          <Table.Column title='Name' dataIndex='name' />
          <Table.Column title='Department' dataIndex='department' />
          <Table.Column title='Mobile' dataIndex='mobile' />
          <Table.Column title='Email' dataIndex='email' />

          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={({ _id }) => (
              <Button.Group>
                {checkSubSectionPermission('staff', 'privileges') && (
                  <Button type='primary' ghost>
                    <Link to={`/staff/privileges/${_id.$oid}`}>Privileges</Link>
                  </Button>
                )}
              </Button.Group>
            )}
          />
        </Table>
      </React.Fragment>
    );
  }
}

StaffDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default StaffDataTable;
