import React from 'react';
import { Card } from 'antd';

import API from '../../../helper/api';

import Pagination from '../../Pagination';
import UserDataTable from './UserDataTable';

class UserList extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };

  saveData = (data, isLoading) => {
    // save data from pagination for passing in user table
    this.setState({ data, isLoading });
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <Card>
        <Pagination path={API.user.list} saveData={this.saveData} />
        <UserDataTable data={data} isLoading={isLoading} />
      </Card>
    );
  }
}

export default UserList;
