import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Icon, Button } from 'antd';

import { PermissionContext } from '../../../permissionContext';
import DeviceDetails from '../DeviceDetails';

class DeviceDataTable extends React.Component {
  static contextType = PermissionContext;

  state = {
    id: false,
  };

  viewDetails = (id) => {
    this.setState({ id });
  };

  render() {
    const { data, isLoading } = this.props;
    const { id } = this.state;
    const { checkSubSectionPermission } = this.context;

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='cluster' />
              &nbsp;
              {'List of devices'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={isLoading}
          dataSource={data}
          rowKey={(record) => record._id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='ID' dataIndex='device_id' />
          <Table.Column title='IMEI' dataIndex='imei' />
          <Table.Column title='Device Type' dataIndex='device_type' />
          <Table.Column title='Status' dataIndex='status' className='cap' />
          <Table.Column
            title='Created On'
            dataIndex='created_on'
            render={(created_on) => (
              <span>
                {created_on
                  ? moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')
                  : ''}
              </span>
            )}
          />
          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={({ _id, device_type, imei }) => (
              <Button.Group>
                {checkSubSectionPermission('devices', 'view') && (
                  <Button
                    type='primary'
                    ghost
                    onClick={() => this.viewDetails(_id.$oid)}
                  >
                    View
                  </Button>
                )}
                {checkSubSectionPermission('devices', 'edit') && (
                  <Button type='primary' ghost>
                    <Link to={`/devices/edit/${_id.$oid}`}>Edit</Link>
                  </Button>
                )}
                {checkSubSectionPermission('devices', 'log') && (
                  <Button type='primary' ghost>
                    <Link to={`/devices/log/${device_type}/${imei}`}>Log</Link>
                  </Button>
                )}
              </Button.Group>
            )}
          />
        </Table>

        {/* Show user details in modal */}
        {id && (
          <Modal
            title='Device Details'
            visible
            onCancel={() => this.viewDetails(false)}
            footer={null}
            maskClosable={false}
          >
            <DeviceDetails id={id} />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

DeviceDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DeviceDataTable;
