import React from 'react';
import PropTypes from 'prop-types';
import { Form, Switch, Button, message as showMessage } from 'antd';
import styled from 'styled-components';

import { patchData } from '../../helper/dataService';
import API from '../../helper/api';
import { objDifferenceWith } from '../../helper/utility';

class NotificationAssetForm extends React.Component {
  state = {
    assetSetting: {},
    submitted: false,
  };

  // Will need to status check for SUBMIT button
  initialAssetStatus = {};

  componentDidMount() {
    const {
      assetSetting,
      assetSetting: { power_cut, ignition, over_speed, geofence },
    } = this.props;

    // Check features are exist then update intial status and form values
    if (power_cut) {
      const {
        sms: powerCutSms,
        email: powerCutEmail,
        app: powerCutApp,
      } = power_cut;
      this.initialAssetStatus = {
        ...this.initialAssetStatus,
        powerCutEmail,
        powerCutSms,
        powerCutApp,
      };
      this.props.form.setFieldsValue({
        powerCutEmail,
        powerCutSms,
        powerCutApp,
      });
    }
    if (ignition) {
      const {
        sms: ignitionSms,
        email: ignitionEmail,
        app: ignitionApp,
      } = ignition;
      this.initialAssetStatus = {
        ...this.initialAssetStatus,
        ignitionSms,
        ignitionEmail,
        ignitionApp,
      };
      this.props.form.setFieldsValue({
        ignitionEmail,
        ignitionSms,
        ignitionApp,
      });
    }
    if (over_speed) {
      const {
        sms: overSpeedSms,
        email: overSpeedEmail,
        app: overSpeedApp,
      } = over_speed;
      this.initialAssetStatus = {
        ...this.initialAssetStatus,
        overSpeedSms,
        overSpeedEmail,
        overSpeedApp,
      };
      this.props.form.setFieldsValue({
        overSpeedEmail,
        overSpeedSms,
        overSpeedApp,
      });
    }
    if (geofence) {
      const {
        sms: geofenceSms,
        email: geofenceEmail,
        app: geofenceApp,
      } = geofence;
      this.initialAssetStatus = {
        ...this.initialAssetStatus,
        geofenceSms,
        geofenceEmail,
        geofenceApp,
      };
      this.props.form.setFieldsValue({
        geofenceSms,
        geofenceEmail,
        geofenceApp,
      });
    }

    // Will need for disable feature action
    this.setState({ assetSetting });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitted: true });
        const {
          userOid,
          assetOid,
          updateAssetNotification,
          closeModal,
        } = this.props;

        const {
          ignitionEmail,
          ignitionSms,
          ignitionApp,
          overSpeedEmail,
          overSpeedSms,
          overSpeedApp,
          geofenceSms,
          geofenceEmail,
          geofenceApp,
          powerCutEmail,
          powerCutSms,
          powerCutApp,
        } = values;

        const {
          assetSetting: { power_cut, ignition, over_speed, geofence },
        } = this.state;

        // asset oid and features setting
        // if feature is null send as null
        const data = {
          assets_oid: [assetOid],
          settings: {
            power_cut: power_cut
              ? {
                  email: powerCutEmail,
                  sms: powerCutSms,
                  app: powerCutApp,
                }
              : null,
            ignition: ignition
              ? {
                  email: ignitionEmail,
                  sms: ignitionSms,
                  app: ignitionApp,
                }
              : null,
            over_speed: over_speed
              ? {
                  email: overSpeedEmail,
                  sms: overSpeedSms,
                  app: overSpeedApp,
                }
              : null,
            geofence: geofence
              ? {
                  email: geofenceEmail,
                  sms: geofenceSms,
                  app: geofenceApp,
                }
              : null,
          },
        };

        patchData(API.user.notification(userOid), data)
          .then(({ data: { display, status } }) => {
            if (status === 'success') {
              updateAssetNotification(data, assetOid);
              showMessage.success(display);
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
            closeModal();
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      assetSetting: { power_cut, ignition, over_speed, geofence },
      submitted,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} layout='inline'>
        <FormBox>
          <Form.Item label='Power Cut' />
          <div>
            <Form.Item label='Email'>
              {getFieldDecorator('powerCutEmail', {
                valuePropName: 'checked',
              })(<Switch disabled={!power_cut} size='small' />)}
            </Form.Item>
            <Form.Item label='SMS'>
              {getFieldDecorator('powerCutSms', {
                valuePropName: 'checked',
              })(<Switch disabled={!power_cut} size='small' />)}
            </Form.Item>
            <Form.Item label='App'>
              {getFieldDecorator('powerCutApp', {
                valuePropName: 'checked',
              })(<Switch disabled={!power_cut} size='small' />)}
            </Form.Item>
          </div>
        </FormBox>

        <FormBox>
          <Form.Item label='Ignition' />
          <div>
            <Form.Item label='Email'>
              {getFieldDecorator('ignitionEmail', {
                valuePropName: 'checked',
              })(<Switch disabled={!ignition} size='small' />)}
            </Form.Item>
            <Form.Item label='SMS'>
              {getFieldDecorator('ignitionSms', {
                valuePropName: 'checked',
              })(<Switch disabled={!ignition} size='small' />)}
            </Form.Item>
            <Form.Item label='App'>
              {getFieldDecorator('ignitionApp', {
                valuePropName: 'checked',
              })(<Switch disabled={!ignition} size='small' />)}
            </Form.Item>
          </div>
        </FormBox>

        <FormBox>
          <Form.Item label='Over Speed' />
          <div>
            <Form.Item label='Email'>
              {getFieldDecorator('overSpeedEmail', {
                valuePropName: 'checked',
              })(<Switch disabled={!over_speed} size='small' />)}
            </Form.Item>
            <Form.Item label='SMS'>
              {getFieldDecorator('overSpeedSms', {
                valuePropName: 'checked',
              })(<Switch disabled={!over_speed} size='small' />)}
            </Form.Item>
            <Form.Item label='App'>
              {getFieldDecorator('overSpeedApp', {
                valuePropName: 'checked',
              })(<Switch disabled={!over_speed} size='small' />)}
            </Form.Item>
          </div>
        </FormBox>

        <FormBox>
          <Form.Item label='Geofence' />
          <div>
            <Form.Item label='Email'>
              {getFieldDecorator('geofenceEmail', {
                valuePropName: 'checked',
              })(<Switch disabled={!geofence} size='small' />)}
            </Form.Item>
            <Form.Item label='SMS'>
              {getFieldDecorator('geofenceSms', {
                valuePropName: 'checked',
              })(<Switch disabled={!geofence} size='small' />)}
            </Form.Item>
            <Form.Item label='App'>
              {getFieldDecorator('geofenceApp', {
                valuePropName: 'checked',
              })(<Switch disabled={!geofence} size='small' />)}
            </Form.Item>
          </div>
        </FormBox>

        {/* no need to enable submit button no difference found */}
        {Object.keys(
          objDifferenceWith(
            this.initialAssetStatus,
            this.props.form.getFieldsValue()
          )
        ).length > 0 && (
          <Button type='primary' htmlType='submit' block loading={submitted}>
            SUBMIT
          </Button>
        )}
      </Form>
    );
  }
}

NotificationAssetForm.propTypes = {
  userOid: PropTypes.string.isRequired,
  assetOid: PropTypes.string.isRequired,
  updateAssetNotification: PropTypes.func.isRequired,
  assetSetting: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Form.create()(NotificationAssetForm);

const FormBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
