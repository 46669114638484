import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import styled from 'styled-components';

const SpinnerBox = styled.div`
  position: 'relative';
  height: auto;
  width: '100%';
  margin: 'auto';
  text-align: center;
`;

const Spinner = ({ size, tip }) => (
  <SpinnerBox>
    <Spin
      size={size}
      style={{
        margin: '5px'
      }}
      tip={tip || ''}
    />
  </SpinnerBox>
);

Spinner.defaultProps = {
  size: 'default',
  tip: ''
};

Spinner.propTypes = {
  size: PropTypes.string,
  tip: PropTypes.string
};

export default Spinner;
