import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Icon, Button } from 'antd';

import { PermissionContext } from '../../../permissionContext';

import AssetDetails from '../AssetDetails';

class AssetDataTable extends React.Component {
  static contextType = PermissionContext;

  state = {
    id: false,
  };

  viewDetails = (id) => {
    this.setState({ id });
  };

  render() {
    const { data, isLoading } = this.props;
    const { id } = this.state;
    const { checkSubSectionPermission } = this.context;

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='car' />
              &nbsp;
              {'List of Assets'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={isLoading}
          dataSource={data}
          rowKey={(record) => record._id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='ID' dataIndex='asset_id' />
          <Table.Column title='Name' dataIndex='name' />

          <Table.Column
            title='Created On'
            dataIndex='created_on'
            render={(created_on) => (
              <span>
                {created_on
                  ? moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')
                  : null}
              </span>
            )}
          />
          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={({ _id }) => (
              <Button.Group>
                {checkSubSectionPermission('assets', 'view') && (
                  <Button
                    type='primary'
                    ghost
                    onClick={() => this.viewDetails(_id.$oid)}
                  >
                    View
                  </Button>
                )}
                {checkSubSectionPermission('assets', 'edit') && (
                  <Button type='primary' ghost>
                    <Link to={`/assets/edit/${_id.$oid}`} className='action'>
                      Edit
                    </Link>
                  </Button>
                )}
              </Button.Group>
            )}
          />
        </Table>

        {/* Show user details in modal */}
        {id && (
          <Modal
            title='Asset Details'
            visible
            onCancel={() => this.viewDetails(false)}
            footer={null}
            maskClosable={false}
          >
            <AssetDetails assetId={id} />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

AssetDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AssetDataTable;
