// pageLimit, pageNumber, Search - this are include in /components/Pagination for all list

const API = {
  asset: {
    add: 'admin/assets',
    list: 'admin/assets/list',
    editOrView: (id) => `admin/assets/${id}`,
    search: (name) => `admin/assets/find?search=${name}`,
  },
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    profileEditOrView: 'auth/profile',
    permission: 'auth/profile/permissions',
    refreshToken: 'auth/refresh_token',
    mobileVerification: {
      sendCode: (number) => `auth/profile/mobile?number=${number}`,
      resendCode: (number) => `auth/send_code?mobile=${number}&resend=ok`,
      verifyCode: (number, code) =>
        `auth/mobile_verify?mobile=${number}&code=${code}`,
    },
  },
  countryWithCity: 'country_info',
  dashboard: 'admin/dashboard',
  device: {
    add: 'admin/devices',
    list: 'admin/devices/list',
    editOrView: (id) => `admin/devices/${id}`,
    type: 'admin/devices/types',
    excel: 'admin/devices/upload/excel',
    search: (name) => `admin/devices/find?search=${name.toUpperCase()}`,
  },
  order: {
    list: 'admin/orders/list',
    editOrView: (id) => `admin/orders/${id}`,
  },
  organization: {
    assetList: (id) => `admin/organizations/${id}/assets`,
    userList: (id) => `admin/organizations/${id}/users`,
    search: (name) => `admin/organizations/find?search=${name}`,
    types: 'admin/organizations/types',
    add: 'admin/organizations',
    list: 'admin/organizations/list',
    editOrView: (id) => `admin/organizations/${id}`,
    sof: (sof) => `admin/organizations/sof/${sof}`,
  },
  payments: {
    bkash: 'admin/payments/bkash_check',
    reports: 'admin/payments/online-payment-reports',
  },
  staff: {
    view: (id) => `admin/staff_users/${id}/privileges/view`,
    usersList: 'admin/staff_users/list',
    userPrivileges: (id) => `admin/staff_users/${id}/privileges`,
    privileges: 'admin/staff/privileges',
    departments: 'admin/staff/departments',
    approve: 'admin/staff/approve',
    approveList: (status) => `admin/staff/approve?status=${status}`, // status=[unapproved/rejected]
  },
  user: {
    add: 'admin/users',
    list: 'admin/users/list',
    assetList: (id) => `admin/users/${id}/assets`,
    types: 'admin/users/types',
    editOrView: (id) => `admin/users/${id}`,
    notification: (oid) => `admin/users/${oid}/notification`,
    search: (name) => `admin/users/find?search=${name}`,
    deleteMobile: (oid) => `admin/users/${oid}/mobile-remove`,
  },
};

export default API;
