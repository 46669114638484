import React from 'react';
import { message } from 'antd';
import { getData } from './helper/dataService';
import API from './helper/api';

const PermissionContext = React.createContext();

class PermissionProvider extends React.Component {
  state = {
    loading: true
  };

  privileges = {};

  accessLevel = null;

  setPermission = () => {
    getData(`${API.auth.permission}`)
      .then(({ data: { data: { privileges, access_level }, status } }) => {
        if (status === 'success') {
          this.privileges = privileges;
          this.accessLevel = Number(access_level);
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        message.error('Sorry! Something went wrong');
        this.setState({ loading: false });
      });
  };

  checkSectionPermission = (section = '') => {
    if (this.accessLevel === 10) {
      return true;
    }
    return this.privileges.hasOwnProperty(section);
  };

  checkSubSectionPermission = (section = '', action = '') => {
    if (this.accessLevel === 10) {
      return true;
    }
    return (
      this.privileges.hasOwnProperty(section) &&
      this.privileges[section].includes(action)
    );
  };

  render() {
    return (
      <PermissionContext.Provider
        value={{
          loadingPermission: this.state.loading,
          setPermission: this.setPermission,
          checkSectionPermission: this.checkSectionPermission,
          checkSubSectionPermission: this.checkSubSectionPermission
        }}
      >
        {this.props.children}
      </PermissionContext.Provider>
    );
  }
}

export { PermissionContext, PermissionProvider };
