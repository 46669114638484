import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Icon, Button } from 'antd';

import UserDetails from '../UserDetails';
import { PermissionContext } from '../../../permissionContext';

class UserDataTable extends React.Component {
  static contextType = PermissionContext;

  state = {
    showUserDetailsModal: false,
    userId: '',
  };

  userDetailsModal = () => {
    this.setState((prevState) => ({
      showUserDetailsModal: !prevState.showUserDetailsModal,
    }));
  };

  viewUser = (userId) => {
    // set user oid and open modal to show user details
    this.setState({ userId });
    this.userDetailsModal();
  };

  render() {
    const { data, isLoading } = this.props;
    const { showUserDetailsModal, userId } = this.state;
    const { checkSubSectionPermission } = this.context;

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='team' />
              &nbsp;
              {'List of users'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={isLoading}
          dataSource={data}
          rowKey={(record) => record._id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='ID' dataIndex='user_id' />
          <Table.Column title='Name' dataIndex='name' />
          <Table.Column title='Mobile' dataIndex='mobile' />
          <Table.Column title='Email' dataIndex='email' />
          <Table.Column title='User Type' dataIndex='user_type' />
          <Table.Column
            title='Created On'
            dataIndex='created_on'
            render={(created_on) => (
              <span>
                {created_on
                  ? moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')
                  : ''}
              </span>
            )}
          />
          <Table.Column
            fixed='right'
            width={100}
            title='Action'
            render={({ _id }) => (
              <Button.Group>
                {checkSubSectionPermission('users', 'view') && (
                  <Button
                    type='primary'
                    ghost
                    onClick={() => this.viewUser(_id.$oid)}
                  >
                    View
                  </Button>
                )}
                {checkSubSectionPermission('users', 'edit') && (
                  <Button type='primary' ghost>
                    <Link to={`/users/edit/${_id.$oid}`}>Edit</Link>
                  </Button>
                )}
                {checkSubSectionPermission('users', 'notification') && (
                  <Button type='primary' ghost>
                    <Link to={`/users/notification/${_id.$oid}`}>
                      Notification
                    </Link>
                  </Button>
                )}
              </Button.Group>
            )}
          />
        </Table>

        {/* Show user details in modal */}
        {showUserDetailsModal && (
          <Modal
            title='User Details'
            visible
            onCancel={this.userDetailsModal}
            footer={null}
            maskClosable={false}
          >
            <UserDetails userId={userId} />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

UserDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UserDataTable;
