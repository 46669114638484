import React from 'react';
import {
  Row,
  Col,
  Card,
  Steps,
  Icon,
  DatePicker,
  Form,
  Button,
  Input,
  Select,
  message as showMessage,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getData, patchData } from '../../helper/dataService';
import API from '../../helper/api';
import { getName, mobileRegexFromPrefix, setItem } from '../../helper/utility';

class CompleteRegistration extends React.Component {
  state = {
    currentStep: 0,
    name: '',
    email: '',
    mobile: '',
    gender: '',
    religion: '',
    blood_group: '',
    birth_date: '',
    time: 0,
    isLoading: true,
    submitted: false,
  };

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = () => {
    getData(API.auth.profileEditOrView)
      .then(
        ({
          data: {
            data: {
              name,
              email,
              mobile,
              mobile_verified,
              gender,
              religion,
              blood_group,
              birth_date,
            } = {},
          },
        }) => {
          this.setState({
            isLoading: false,
            name,
            mobile: '',
            email,
            gender,
            religion,
            blood_group,
            birth_date,
          });

          // For mobile verified staff select user info FORM step otherwise start form mobile FORM
          if (mobile && mobile_verified) {
            this.setState({ mobile, currentStep: 2 });
          }

          // For all fill up field select final step.
          if (
            name &&
            email &&
            mobile &&
            mobile_verified &&
            gender &&
            religion &&
            blood_group &&
            birth_date
          ) {
            this.setState({
              currentStep: 3,
            });
          }
        }
      )
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isLoading: false });
      });
  };

  timer = (time) => {
    // Set how many seconds to count
    this.setState({
      time,
    });

    this.timerInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.time > 0) {
          return { time: prevState.time - 1 };
        }
        // Clear setInterval from DOM
        clearInterval(this.timerInterval);
        return { time: 0 };
      });
    }, 1000);
  };

  handleMobileSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ submitted: true });

        let { mobile, mobilePrefix } = values;
        mobile = mobilePrefix + mobile;

        getData(API.auth.mobileVerification.sendCode(mobile))
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              this.setState({
                currentStep: 1,
                mobile,
              });
              this.timer(60);
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  resendCode = () => {
    const { mobile } = this.state;
    this.timer(60);

    getData(API.auth.mobileVerification.resendCode(mobile))
      .then(({ data: { status, display } }) => {
        // status=success will send the code so no need to show success message again
        if (status === 'failed') {
          this.setState({
            time: 0,
          });
          showMessage.error(display);
        }
      })
      .catch(() => showMessage.error('Sorry! Something went wrong'));
  };

  handleMobileVerificationSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const { mobile } = this.state;
      const { verification } = values;

      if (!err) {
        this.setState({ submitted: true });

        getData(API.auth.mobileVerification.verifyCode(mobile, verification))
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              const {
                name,
                email,
                gender,
                religion,
                blood_group,
                birth_date,
              } = this.state;

              // After mobile verification done check field are filled up or not?
              if (
                name &&
                email &&
                gender &&
                religion &&
                blood_group &&
                birth_date
              ) {
                this.setState({
                  currentStep: 3,
                });
              } else {
                this.setState({
                  currentStep: 2,
                });
              }
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  handleUserInfoSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ submitted: true });

        const {
          name,
          email,
          mobile,
          dateOfBirth,
          gender,
          religion,
          bloodGroup,
        } = values;
        const data = {
          name,
          email,
          mobile,
          birth_date: moment(dateOfBirth).format('DD-MM-YYYY'), // Send as STRING
          gender,
          religion,
          blood_group: bloodGroup,
        };

        patchData('auth/profile', data)
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              this.setState({
                currentStep: 3,
              });
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  clearCurrentMobile = () => {
    this.props.form.setFieldsValue({ mobile: '' });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const mobilePrefix = getFieldValue('mobilePrefix');

    const {
      currentStep,
      name,
      email,
      mobile,
      birth_date,
      blood_group,
      religion,
      gender,
      time,
      isLoading,
      submitted,
    } = this.state;

    return (
      <Row
        type='flex'
        justify='space-around'
        align='middle'
        style={{ padding: '25px 50px' }}
      >
        <Col xs={24} sm={18}>
          <Card
            title={`Welcome ${getName()}! Please complete your profile.`}
            loading={isLoading}
          >
            <React.Fragment>
              <Steps current={currentStep} labelPlacement='vertical'>
                <Steps.Step title='Mobile' icon={<Icon type='mobile' />} />
                <Steps.Step title='Verification' icon={<Icon type='check' />} />
                <Steps.Step title='Personal Info' icon={<Icon type='form' />} />
                <Steps.Step title='Complete' icon={<Icon type='trophy' />} />
              </Steps>

              <br />
              <br />

              <div className='steps-content'>
                {/* Mobile Input */}
                {currentStep === 0 && (
                  <Form onSubmit={this.handleMobileSubmit}>
                    <Form.Item label='Mobile Number'>
                      {getFieldDecorator('mobile', {
                        initialValue: mobile,
                        rules: [
                          {
                            pattern: mobileRegexFromPrefix(mobilePrefix),
                            message: 'The input is not a valid mobile number',
                          },
                          {
                            required: true,
                            message: 'Please input your mobile number',
                          },
                        ],
                      })(
                        <Input
                          addonBefore={getFieldDecorator('mobilePrefix', {
                            initialValue: '+880',
                          })(
                            <Select
                              style={{ width: 80 }}
                              onChange={this.clearCurrentMobile}
                            >
                              <Select.Option value='+880'>+880</Select.Option>
                              {/* <Select.Option value="+977">+977</Select.Option> */}
                            </Select>
                          )}
                          placeholder='Mobile Number'
                        />
                      )}
                    </Form.Item>

                    <Button
                      type='primary'
                      htmlType='submit'
                      size='large'
                      style={{ float: 'right' }}
                      loading={submitted}
                    >
                      Next
                    </Button>
                  </Form>
                )}

                {/* Verification Code */}
                {currentStep === 1 && (
                  <div>
                    <Form onSubmit={this.handleMobileVerificationSubmit}>
                      <Form.Item
                        label={
                          <span>
                            Verification Code [
                            {time > 0 ? (
                              `Wait for ${time} seconds`
                            ) : (
                              <span
                                onClick={this.resendCode}
                                className='action'
                              >
                                Resend Code
                              </span>
                            )}
                            ]
                          </span>
                        }
                        extra={`A sms has been sent to ${mobile}.`}
                        colon={false}
                      >
                        {getFieldDecorator('verification', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input verification code',
                            },
                          ],
                        })(<Input placeholder='Verification Code' />)}
                      </Form.Item>

                      <Button
                        type='primary'
                        htmlType='submit'
                        size='large'
                        style={{ float: 'right' }}
                        loading={submitted}
                      >
                        Next
                      </Button>
                    </Form>
                  </div>
                )}

                {/*  Staff User Form */}
                {currentStep === 2 && (
                  <Form onSubmit={this.handleUserInfoSubmit} layout='vertical'>
                    <Form.Item label='Name' {...formItemLayout}>
                      {name}
                    </Form.Item>

                    <Form.Item label='Email' {...formItemLayout}>
                      {email}
                    </Form.Item>

                    <Form.Item label='Mobile' {...formItemLayout}>
                      {mobile}
                    </Form.Item>

                    <Form.Item label='Date of Birth' {...formItemLayout}>
                      {getFieldDecorator('dateOfBirth', {
                        initialValue: birth_date
                          ? moment(birth_date.$date)
                          : null,
                        rules: [
                          {
                            type: 'object',
                            required: true,
                            message: 'Please select a date',
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder='Select Date'
                          format='DD-MM-YYYY'
                          showToday={false}
                          disabledTime
                          size='large'
                          style={{ width: '100%' }}
                          // Can't select date less than 13 years
                          disabledDate={(current) =>
                            current >
                            moment().endOf('year').subtract(13, 'years')
                          }
                        />
                      )}
                    </Form.Item>

                    <Form.Item label='Gender' {...formItemLayout}>
                      {getFieldDecorator('gender', {
                        initialValue: gender,
                        rules: [
                          {
                            required: true,
                            message: 'Please select your gender',
                          },
                        ],
                      })(
                        <Select placeholder='Select Gender'>
                          <Select.Option value='male'>Male</Select.Option>
                          <Select.Option value='female'>Female</Select.Option>
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item label='Religion' {...formItemLayout}>
                      {getFieldDecorator('religion', {
                        initialValue: religion,
                        rules: [
                          {
                            required: true,
                            message: 'Please select your religion',
                          },
                        ],
                      })(
                        <Select placeholder='Select Religion'>
                          <Select.Option value='islam'>Islam</Select.Option>
                          <Select.Option value='hinduism'>
                            Hinduism
                          </Select.Option>
                          <Select.Option value='buddhism'>
                            Buddhism
                          </Select.Option>
                          <Select.Option value='christianity'>
                            Christianity
                          </Select.Option>
                          <Select.Option value='other'>Other</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label='Blood Group' {...formItemLayout}>
                      {getFieldDecorator('bloodGroup', {
                        initialValue: blood_group,
                        rules: [
                          {
                            required: true,
                            message: 'Please select your blood group',
                          },
                        ],
                      })(
                        <Select placeholder='Select Blood Group'>
                          <Select.Option value='AB+'>AB+</Select.Option>
                          <Select.Option value='AB-'>AB-</Select.Option>
                          <Select.Option value='A+'>A+</Select.Option>
                          <Select.Option value='A-'>A-</Select.Option>
                          <Select.Option value='B+'>B+</Select.Option>
                          <Select.Option value='B-'>B-</Select.Option>
                          <Select.Option value='O+'>O+</Select.Option>
                          <Select.Option value='O-'>O-</Select.Option>
                          <Select.Option value='unknown'>
                            I don't know
                          </Select.Option>
                        </Select>
                      )}
                    </Form.Item>

                    <Button
                      type='primary'
                      htmlType='submit'
                      size='large'
                      style={{ float: 'right' }}
                      loading={submitted}
                    >
                      Next
                    </Button>
                  </Form>
                )}
                {currentStep === 3 && (
                  <h2 align='center'>
                    <br />
                    Congratulation! Your profile is complete.
                    <br />
                    <Link
                      onClick={() => setItem('profileComplete', 'yes')}
                      to='/'
                    >
                      Go to dashboard
                    </Link>
                  </h2>
                )}
              </div>
            </React.Fragment>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(CompleteRegistration);
