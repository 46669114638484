import React from 'react';
import PropTypes from 'prop-types';
import { Form, message as showMessage, Icon } from 'antd';
import moment from 'moment';

import { getData } from '../../helper/dataService';
import API from '../../helper/api';

import Spinner from '../common/Spinner';

class OrganizationDetails extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };

  componentDidMount() {
    getData(API.organization.editOrView(this.props.orgId))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ data });
        } else if (status === 'failed') {
          showMessage.error('Sorry! Something went wrong');
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      isLoading,
      data: {
        active,
        address,
        billing_person,
        city,
        contact_numbers,
        profile,
        country,
        created_by,
        created_on,
        credit_days,
        currency,
        discount,
        modified_by,
        modified_on,
        name,
        free_sms_left,
        no_of_free_sms,
        paid_sms_left,
        no_of_paid_sms,
        number_of_assets,
        number_of_active_assets,
        number_of_users,
        organization_id,
        organization_type,
        remarks,
        taxes,
      } = {},
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return isLoading ? (
      <Spinner />
    ) : (
      <Form layout='vertical'>
        <Form.Item label='Organization ID' {...formItemLayout}>
          {organization_id}
        </Form.Item>

        <Form.Item label='Active' {...formItemLayout}>
          <span className={active ? 'active' : 'inactive'}>
            {active ? 'Yes' : 'No'}
          </span>
        </Form.Item>

        <Form.Item label='Name' {...formItemLayout}>
          {name}
        </Form.Item>

        <Form.Item label='Type' {...formItemLayout}>
          {organization_type}
        </Form.Item>

        <Form.Item label='Profile' {...formItemLayout}>
          {profile}
        </Form.Item>

        <Form.Item label='Contact Numbers' {...formItemLayout}>
          {contact_numbers.map((item, index) => (
            <span key={index}>
              {item}
              {index + 1 !== contact_numbers.length ? ', ' : ''}
            </span>
          ))}
        </Form.Item>

        <Form.Item label='Total Assets' {...formItemLayout}>
          {`${number_of_active_assets} / ${number_of_assets}`}
        </Form.Item>

        <Form.Item label='Total Users' {...formItemLayout}>
          {number_of_users}
        </Form.Item>

        <Form.Item label='Credit Days' {...formItemLayout}>
          {credit_days}
        </Form.Item>

        <Form.Item label='Currency' {...formItemLayout}>
          {currency.toUpperCase()}
        </Form.Item>

        <Form.Item label='Discount' {...formItemLayout}>
          {`${discount}%`}
        </Form.Item>

        <Form.Item label='Taxes' {...formItemLayout}>
          {`${taxes}%`}
        </Form.Item>

        <Form.Item label='Free SMS Left' {...formItemLayout}>
          {free_sms_left} / {no_of_free_sms}
        </Form.Item>

        <Form.Item label='Paid SMS Left' {...formItemLayout}>
          {paid_sms_left} / {no_of_paid_sms}
        </Form.Item>

        <Form.Item label='Country' {...formItemLayout}>
          {country}
        </Form.Item>

        <Form.Item label='City' {...formItemLayout}>
          {city}
        </Form.Item>

        <Form.Item label='Address' {...formItemLayout}>
          {address}
        </Form.Item>

        <Form.Item label='Billing Person' {...formItemLayout}>
          {billing_person.hasOwnProperty('name') ? (
            <React.Fragment>
              {billing_person.name && (
                <span>
                  {billing_person.name}
                  <br />
                </span>
              )}
              {billing_person.mobile && (
                <span>
                  <Icon type='mobile' /> {billing_person.mobile}
                  <br />
                </span>
              )}
              {billing_person.email && (
                <span>
                  <Icon type='mail' /> {billing_person.email}
                </span>
              )}
            </React.Fragment>
          ) : (
            <span className='blank'>No Billing Person</span>
          )}
        </Form.Item>

        {remarks && (
          <Form.Item label='Remarks' {...formItemLayout}>
            {remarks}
          </Form.Item>
        )}

        {created_on && (
          <Form.Item label='Created' {...formItemLayout}>
            <span>
              <Icon type='clock-circle' />{' '}
              {moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')}
            </span>
            <br />
            <span>
              <Icon type='user' /> {created_by.name ? created_by.name : 'User'}
            </span>
          </Form.Item>
        )}

        {modified_on && (
          <Form.Item label='Modified' {...formItemLayout}>
            {modified_on && (
              <span>
                <Icon type='clock-circle' />{' '}
                {moment(modified_on.$date).format('DD-MMM-YYYY hh:mm a')}
              </span>
            )}
            <br />
            <span>
              <Icon type='user' />{' '}
              {modified_by.name ? modified_by.name : 'Not Available'}
            </span>
          </Form.Item>
        )}
      </Form>
    );
  }
}

OrganizationDetails.propTypes = {
  orgId: PropTypes.string.isRequired,
};

export default OrganizationDetails;
