import React from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Switch,
  Select,
  Row,
  Col,
  Icon,
  Popconfirm,
  Spin,
  Modal,
  message as showMessage,
  Tooltip,
} from 'antd';
// import { Link } from 'react-router-dom';
import moment from 'moment';

import { getData, patchData } from '../../helper/dataService';
import API from '../../helper/api';
import {
  nameFromId,
  objDifferenceWith,
  delayApiRequest,
} from '../../helper/utility';

import DividerWithColor from '../common/DividerWithColor';
import OrganizationDetails from '../organization/OrganizationDetails';

class EditUser extends React.Component {
  state = {
    orgData: [],
    assetsData: [],
    orgFetching: false,
    assetFetching: false,
    userTypes: [],
    submitted: false,
    fetchingUserData: true,
    loadMore: false,
    data: [],
    showModal: '',
    orgId: '',
    organizationAdminStatus: false,
  };

  EDITABLE_FIELDS = [];
  ORIGINAL_DATA = {};

  componentDidMount() {
    this.fetchUserData();
    this.fetchUserTypes();
  }

  fetchUserData = () => {
    getData(API.user.editOrView(this.props.match.params.oid))
      .then(({ data: { data = [], editable = [], status } }) => {
        if (status === 'success') {
          const {
            user_type,
            organizations,
            sms_subscription,
            email_subscription,
            remarks,
            flagged,
            assets_oid,
            number_of_assets,
          } = data;

          // user_type provide as display name so convert it into value
          const userType = user_type.split(' ').join('_').toLowerCase();

          /* Get first organizations oid from array.
          In future multiple organization oid can be exists
          */
          let organizations_oid = '';
          let organizationAdminStatus = false;

          if (organizations.length > 0) {
            organizations_oid = organizations[0]._id.$oid;
            organizationAdminStatus = organizations[0].admin;
          }

          // Set editable list of user
          this.EDITABLE_FIELDS = [...editable];

          //
          this.setState({
            data,
            organizationAdminStatus,
            orgData: organizations,
          });

          this.ORIGINAL_DATA = {
            user_type: userType,
            organizations_oid,
            assets_oid,
            sms_subscription,
            email_subscription,
            remarks,
            flagged,
          };

          this.props.form.setFieldsValue({
            user_type: userType,
            organizations_oid,
            sms_subscription,
            email_subscription,
            remarks,
          });
          this.setState({ fetchingUserData: false });

          // Fetch other API
          this.fetchAssetsByOrgID(organizations_oid);
          this.fetchAssetsByUserId(number_of_assets);
        } else if (status === 'failed') {
          showMessage.error('Sorry! Something went wrong');
        }
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ fetchingUserData: false });
      });
  };

  fetchAssetsByOrgID = (value) => {
    if (value) {
      // remove previous asset list for organization change
      this.props.form.setFieldsValue({
        assets_oid: [],
      });
      this.setState({ assetsData: [], assetFetching: true });
      getData(API.organization.assetList(value))
        .then(({ data: { data, status } }) => {
          if (status === 'success') {
            this.setState({ assetsData: data });
          }
          this.setState({ assetFetching: false });
        })
        .catch(() => {
          showMessage.error('Sorry! Something went wrong');
          this.setState({ assetFetching: false });
        });
    }
  };

  fetchAssetsByUserId = (number_of_assets) => {
    if (number_of_assets > 0) {
      this.setState({ assetFetching: true });
      getData(API.user.assetList(this.props.match.params.oid))
        .then(({ data: { data, status } }) => {
          if (status === 'success') {
            // set user assets 'assets_oid' for mark selected asset from all organization asset list
            const assets_oid = data.map((item) => item._id.$oid);
            this.props.form.setFieldsValue({
              assets_oid,
            });

            // Update ORIGINAL_DATA
            this.ORIGINAL_DATA = { ...this.ORIGINAL_DATA, assets_oid };
          }
          this.setState({ assetFetching: false });
        })
        .catch(() => {
          showMessage.error('Sorry! Something went wrong');
          this.setState({ assetFetching: false });
        });
    } else {
      // for blank asset list set as blank array
      this.props.form.setFieldsValue({
        assets_oid: [],
      });
      this.setState({
        assetFetching: false,
        submitted: false,
      });
      this.ORIGINAL_DATA = { ...this.ORIGINAL_DATA, assets_oid: [] };
    }
  };

  handleFetchOrg = (organizationName) => {
    if (organizationName.length >= 4) {
      delayApiRequest(() => this.fetchOrg(organizationName));
    }
  };

  fetchUserTypes = () => {
    getData(API.user.types)
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ userTypes: data });
        }
      })
      .catch(() => showMessage.error('Sorry! Something went wrong'));
  };

  fetchOrg = (value) => {
    this.props.form.setFieldsValue({
      organizations_oid: '',
      assets_oid: [],
    });
    this.setState({ orgData: [], assetsData: [], orgFetching: true });
    getData(API.organization.search(value))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ orgData: data });
        }
        this.setState({ orgFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ orgFetching: false });
      });
  };

  handleOrgChange = (value) => {
    this.fetchAssetsByOrgID(value);
  };

  clearOrg = () => {
    // For remove current selected organization
    this.setState({
      orgData: [],
      assetsData: [],
    });
    this.props.form.setFieldsValue({
      organizations_oid: '',
      assets_oid: [],
    });
  };

  handleLoadMore = () => {
    // show and hide content
    this.setState((prevState) => ({ loadMore: !prevState.loadMore }));
  };

  handleAssetSelectAll = () => {
    const { assetsData } = this.state;

    // Remove button clicked: Unselect all if assets list and selected assets oid length is equal
    // OTHERWISE
    // Add button Clicked: Select all assets
    this.props.form.setFieldsValue({
      assets_oid:
        assetsData.length === this.props.form.getFieldValue('assets_oid').length
          ? []
          : assetsData.map((item) => item._id.$oid),
    });
  };

  handleFlagSubmit = (value) => {
    this.sendData(value);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // Check different between previous and current values
        const diffValues = objDifferenceWith(this.ORIGINAL_DATA, values);

        if (Object.keys(diffValues).length > 0) {
          this.ORIGINAL_DATA = { ...this.ORIGINAL_DATA, ...diffValues };
          if (diffValues.hasOwnProperty('organizations_oid')) {
            /*
             For future development purpose for multiple organization selection,
             send organization oid in array.
             */
            const organizationsOidString =
              this.props.form.getFieldValue('organizations_oid');
            const organizations_oid = organizationsOidString
              ? [organizationsOidString]
              : [];
            this.sendData({ ...diffValues, organizations_oid });
          } else {
            this.sendData(diffValues);
          }
        }
      }
    });
  };

  sendData = (values) => {
    this.setState({ submitted: true });
    patchData(API.user.editOrView(this.props.match.params.oid), values)
      .then(({ data: { display, status } }) => {
        if (status === 'success') {
          if (values.hasOwnProperty('flagged')) {
            this.fetchUserData();
          }
          showMessage.success(display);
        }
        this.setState({ submitted: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ submitted: false });
      });
  };

  deleteMobile = () => {
    this.setState({ submitted: true });
    patchData(API.user.deleteMobile(this.props.match.params.oid))
      .then(({ data: { display, status } }) => {
        if (status === 'success') {
          showMessage.success(display);
        }
        this.setState({
          data: { ...this.state.data, mobile: '' },
          submitted: false,
        });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ submitted: false });
      });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  showDetails = (orgId) => {
    this.setState({ orgId });
    this.handleModal();
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const {
      orgData,
      assetsData,
      orgFetching,
      assetFetching,
      submitted,
      fetchingUserData,
      loadMore,
      userTypes,
      organizationAdminStatus,
      data: {
        created_by,
        created_on,
        email,
        email_verified,
        flagged,
        mobile,
        mobile_verified,
        modified_by,
        modified_on,
        name,
        password,
        referral_code,
        user_id,
      } = {},
      showModal,
      orgId,
    } = this.state;

    const assetsOid = getFieldValue('assets_oid');
    const organizationsOid = getFieldValue('organizations_oid');

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='team' />
                {' Edit User'}
              </span>
            }
            extra={
              fetchingUserData ? null : (
                <span style={{ float: 'right' }}>
                  {this.EDITABLE_FIELDS.includes('flagged') ? (
                    <Popconfirm
                      title={`${flagged ? 'Unflag' : 'Flag'} User?`}
                      onConfirm={() =>
                        this.handleFlagSubmit({ flagged: !flagged })
                      }
                      icon={null}
                      placement='bottomRight'
                    >
                      {user_id}
                    </Popconfirm>
                  ) : (
                    user_id
                  )}
                </span>
              )
            }
          >
            {/* Show  not editable content - START */}
            <Card loading={fetchingUserData}>
              <Form layout='vertical'>
                {flagged ? (
                  <DividerWithColor>Account is Flagged</DividerWithColor>
                ) : null}

                <Form.Item
                  label='Name'
                  {...formItemLayout}
                  extra={
                    <span className={password ? 'active' : 'inactive'}>
                      password
                    </span>
                  }
                >
                  {name}
                </Form.Item>

                <Form.Item label='Mobile' {...formItemLayout}>
                  {mobile ? (
                    <span className={mobile_verified ? 'active' : 'inactive'}>
                      {mobile}
                      <Popconfirm
                        title='Are you sure want to remove?'
                        okText='Yes'
                        cancelText='No'
                        onConfirm={this.deleteMobile}
                        // disabled={!this.EDITABLE_FIELDS.includes('users')}
                        placement='bottomRight'
                      >
                        <Tooltip title='Remove'>
                          <Icon
                            type={submitted ? 'loading' : 'delete'}
                            style={{ marginLeft: '5px' }}
                          />
                        </Tooltip>
                      </Popconfirm>
                    </span>
                  ) : null}
                </Form.Item>

                <Form.Item label='Email' {...formItemLayout}>
                  <span className={email_verified ? 'active' : 'inactive'}>
                    {email}
                  </span>
                </Form.Item>

                {loadMore && (
                  <React.Fragment>
                    <Form.Item label='Referral Code' {...formItemLayout}>
                      {referral_code}
                    </Form.Item>

                    {created_on && (
                      <Form.Item label='Created' {...formItemLayout}>
                        <span>
                          <Icon type='clock-circle' />{' '}
                          {moment(created_on.$date).format(
                            'DD-MMM-YYYY hh:mm a'
                          )}
                        </span>
                        <br />
                        <span>
                          <Icon type='user' />{' '}
                          {created_by.name ? created_by.name : 'User'}
                        </span>
                      </Form.Item>
                    )}

                    {modified_on && (
                      <Form.Item label='Modified' {...formItemLayout}>
                        {modified_on.$date && (
                          <span>
                            <Icon type='clock-circle' />{' '}
                            {moment(modified_on.$date).format(
                              'DD-MMM-YYYY hh:mm a'
                            )}
                          </span>
                        )}
                        <br />
                        <span>
                          <Icon type='user' />{' '}
                          {modified_by.name
                            ? modified_by.name
                            : 'Not Available'}
                        </span>
                      </Form.Item>
                    )}
                  </React.Fragment>
                )}

                {/* Show and hide content control */}
                <Button
                  type='ghost'
                  size='small'
                  onClick={this.handleLoadMore}
                  style={{ float: 'right' }}
                >
                  {loadMore ? 'Show Less' : 'Show More'}
                </Button>
              </Form>
            </Card>
            {/* Show not editable content - END */}

            <br />

            {/* Show editable content - START */}
            <Card loading={fetchingUserData}>
              <Form
                onSubmit={this.handleSubmit}
                hideRequiredMark
                layout='vertical'
              >
                <Form.Item label='User Type' {...formItemLayout}>
                  {getFieldDecorator('user_type', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a user type',
                      },
                    ],
                  })(
                    <Select
                      placeholder='Select User Type'
                      notFoundContent={
                        userTypes.length <= 0 ? <Spin size='small' /> : null
                      }
                      disabled={!this.EDITABLE_FIELDS.includes('user_type')}
                    >
                      {userTypes.map((item) => (
                        <Select.Option key={item.name}>
                          {item.display}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                {/* Organization START */}
                <Form.Item
                  label='Organization'
                  extra={
                    organizationsOid ? (
                      <Button.Group size='small'>
                        <Button
                          onClick={() => this.showDetails(organizationsOid)}
                        >
                          {nameFromId(organizationsOid, orgData)}
                        </Button>
                        {!flagged && !organizationAdminStatus && (
                          <Tooltip title='Remove organization'>
                            <Button icon='delete' onClick={this.clearOrg} />
                          </Tooltip>
                        )}
                      </Button.Group>
                    ) : null
                  }
                  {...formItemLayout}
                >
                  {getFieldDecorator(
                    'organizations_oid',
                    {}
                  )(
                    <Select
                      showSearch
                      placeholder='Search and Select Organization'
                      filterOption={false}
                      notFoundContent={
                        orgFetching ? <Spin size='small' /> : 'No Content Found'
                      }
                      onSearch={this.handleFetchOrg}
                      onChange={this.handleOrgChange}
                      defaultActiveFirstOption={false}
                      disabled={
                        !this.EDITABLE_FIELDS.includes('organizations') ||
                        organizationAdminStatus
                      }
                    >
                      {orgData.length > 0 &&
                        orgData.map((item) => (
                          <Select.Option key={item._id.$oid}>
                            {item.name} [{item.organization_id}]
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                {/* Organization End */}

                {/* Asset Start */}
                <Form.Item
                  label='Asset(s)'
                  extra={
                    assetsData.length > 0 &&
                    this.EDITABLE_FIELDS.includes('organizations') ? (
                      <Button
                        onClick={this.handleAssetSelectAll}
                        size='small'
                        type='ghost'
                      >
                        {assetsData.length === assetsOid.length
                          ? 'Remove all assets'
                          : 'Add all assets'}
                      </Button>
                    ) : null
                  }
                  {...formItemLayout}
                >
                  {getFieldDecorator('assets_oid', {
                    rules: [
                      {
                        type: 'array',
                      },
                    ],
                  })(
                    <Select
                      mode='multiple'
                      placeholder='Search and Select Asset(s)'
                      optionFilterProp='name'
                      maxTagCount={0}
                      maxTagPlaceholder={() =>
                        assetsOid.length === 1
                          ? '1 asset selected'
                          : `${assetsOid.length} assets selected`
                      }
                      notFoundContent={
                        // eslint-disable-next-line no-nested-ternary
                        organizationsOid ? (
                          assetFetching ? (
                            <Spin size='small' />
                          ) : (
                            'No Content Found'
                          )
                        ) : (
                          'Select Organization First'
                        )
                      }
                      disabled={!this.EDITABLE_FIELDS.includes('organizations')}
                    >
                      {assetsData.map((item) => (
                        <Select.Option
                          key={item._id.$oid}
                          value={item._id.$oid}
                          name={item.name}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                {assetsData.length > 0 && assetsOid && (
                  <Card
                    label='Selected Assets'
                    style={{ marginBottom: '24px' }}
                    bordered={false}
                  >
                    {assetsOid.map((id) => (
                      <Button
                        key={id}
                        size='small'
                        href={`http://www.finder-lbs.com/admin#/assets/${id}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ margin: '5px' }}
                      >
                        {nameFromId(id, assetsData)}
                      </Button>
                    ))}
                  </Card>
                )}
                {/* Asset END */}

                <Form.Item label='Remarks' {...formItemLayout}>
                  {getFieldDecorator('remarks', {
                    initialValue: '',
                    rules: [
                      {
                        max: 250,
                        message: 'The input not more than 250 characters.',
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder='Remarks'
                      disabled={!this.EDITABLE_FIELDS.includes('remarks')}
                    />
                  )}
                </Form.Item>
                <Form.Item label='Email Subscription' {...formItemLayout}>
                  {getFieldDecorator('email_subscription', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Switch
                      disabled={
                        !this.EDITABLE_FIELDS.includes('email_subscription')
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item label='SMS Subscription' {...formItemLayout}>
                  {getFieldDecorator('sms_subscription', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Switch
                      disabled={
                        !this.EDITABLE_FIELDS.includes('sms_subscription')
                      }
                    />
                  )}
                </Form.Item>

                {/* Show submit button if user is not active */}
                {!flagged && (
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    loading={submitted}
                    style={{ float: 'right' }}
                  >
                    SUBMIT
                  </Button>
                )}
              </Form>
            </Card>
          </Card>
          {showModal && (
            <Modal
              title='Organization Details'
              visible
              onCancel={this.handleModal}
              footer={null}
              maskClosable={false}
            >
              <OrganizationDetails orgId={orgId} />
            </Modal>
          )}
        </Col>
      </Row>
    );
  }
}

export default Form.create()(EditUser);
