import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

const DividerWithColor = ({ children, color }) => (
  <Divider
    style={{
      color: `${color}`,
      width: '100%',
      paddingTop: '0px',
      marginTop: '0'
    }}
  >
    {children}
  </Divider>
);

DividerWithColor.defaultProps = {
  color: '#f03e3e'
};

DividerWithColor.propTypes = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string
};

export default DividerWithColor;
