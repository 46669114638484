import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { notification, message } from 'antd';

import { getItem } from './helper/utility';

import 'antd/dist/antd.css';
import './app.css';

import AuthApp from './AuthApp';
import Login from './components/auth/Login';

class App extends React.Component {
  componentDidMount() {
    // check internet
    window.addEventListener('online', () => this.checkInternetConnection('on'));
    window.addEventListener('offline', () =>
      this.checkInternetConnection('off')
    );
  }

  componentWillUnmount() {
    window.removeEventListener('online');
    window.removeEventListener('offline');
  }

  checkInternetConnection = (status) => {
    notification.destroy();
    if (status === 'on') {
      notification.success({
        message: 'You are now online.',
        placement: 'bottomLeft',
      });
    } else if (status === 'off') {
      notification.warning({
        message: 'You are currently offline.',
        placement: 'bottomLeft',
        duration: null,
      });
    }
  };

  checkUserStatus = () => {
    const accessToken = getItem('accessToken');
    const refreshToken = getItem('refreshToken');
    const profileComplete = getItem('profileComplete');

    if (accessToken && refreshToken && profileComplete === 'yes') {
      return true;
    }
    return false;
  };

  render() {
    message.config({
      top: 100,
      duration: 3.5,
      maxCount: 5,
    });
    return (
      <Switch>
        <Route
          exact
          path='/login'
          render={(props) =>
            !this.checkUserStatus() ? <Login {...props} /> : <Redirect to='/' />
          }
        />

        <Route
          path='/'
          render={(props) =>
            this.checkUserStatus() ? (
              <AuthApp {...props} />
            ) : (
              <Redirect to='/login' />
            )
          }
        />
      </Switch>
    );
  }
}

export default App;
