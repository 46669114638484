import React from "react";
import { Card } from "antd";

import API from "../../../helper/api";

import Pagination from "../../Pagination";
import AssetDataTable from "./AssetDataTable";

class AssetList extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };

  saveData = (data, isLoading) => {
    // save data from pagination
    this.setState({ data, isLoading });
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <Card>
        <Pagination path={API.asset.list} saveData={this.saveData} />
        <AssetDataTable data={data} isLoading={isLoading} />
      </Card>
    );
  }
}

export default AssetList;
