import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Button } from 'antd';

import Logo from '../../assets/finder_logo_white.png';
import MainMenu from './MainMenu';
import ProfileMenu from './ProfileMenu';

class Navigation extends React.Component {
  state = {
    mainMenuMode: 'horizontal',
  };
  componentDidMount() {
    this.handleMenuMode(window.innerWidth);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) => {
    this.handleMenuMode(e.target.innerWidth);
  };

  handleMenuMode = (width) => {
    this.setState({
      mainMenuMode: width < 900 ? 'vertical' : 'horizontal',
    });
  };

  render() {
    const { mainMenuMode } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link to='/'>
            <img
              src={Logo}
              style={{
                width: '120px',
                height: '35px',
                margin: '0 10px 10px 0',
              }}
              alt='Finder Logo'
            />
          </Link>
          {mainMenuMode === 'horizontal' && <MainMenu mode={mainMenuMode} />}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ProfileMenu />
          {mainMenuMode === 'vertical' && (
            <Dropdown
              overlayStyle={{ marginTop: '20px' }}
              placement='bottomRight'
              overlay={
                <MainMenu style={{ marginTop: '15px' }} mode={mainMenuMode} />
              }
            >
              <Button type='primary'>
                <Icon type='menu' />
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
    );
  }
}
export default Navigation;
