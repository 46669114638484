// Add current release top of the array (index = 0)
export default [
  {
    version: '1.1.0',
    works: ['Access Level', 'Privileges']
  },
  {
    version: '1.0.0',
    works: [
      'Authentication',
      'Staff Approve',
      'User Add, Edit',
      'User Notification Settings'
    ]
  }
];
