import React from "react";
import PropTypes from "prop-types";
import { Form, message as showMessage } from "antd";

import { getData } from "../../helper/dataService";
import API from "../../helper/api";

import Spinner from "../common/Spinner";

class StaffDetails extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };

  componentDidMount() {
    // Fetch staff details

    getData(API.staff.view(this.props.staffId))
      .then(({ data: { data, status } }) => {
        if (status === "success") {
          this.setState({ data });
        } else if (status === "failed") {
          showMessage.error("Sorry! Something went wrong");
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        showMessage.error("Sorry! Something went wrong");
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      isLoading,
      data: { employee_id, name, department, designation, email } = {},
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return isLoading ? (
      <Spinner />
    ) : (
      <Form layout="vertical">
        <Form.Item label="ID" {...formItemLayout}>
          {employee_id}
        </Form.Item>

        <Form.Item label="Name" {...formItemLayout}>
          {name}
        </Form.Item>

        <Form.Item label="Department" {...formItemLayout}>
          {department}
        </Form.Item>

        <Form.Item label="Designation" {...formItemLayout}>
          {designation}
        </Form.Item>

        <Form.Item label="Email" {...formItemLayout}>
          {email}
        </Form.Item>
      </Form>
    );
  }
}

StaffDetails.propTypes = {
  staffId: PropTypes.string.isRequired,
};

export default StaffDetails;
