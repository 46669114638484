import React from 'react';
import { Collapse, Tag } from 'antd';

import versions from './versions';

class About extends React.Component {
  state = {
    showVersion: true
  };

  handleShowDetails = () => {
    this.setState({
      showVersion: false
    });
  };

  render() {
    const { showVersion } = this.state;

    return showVersion ? (
      <div className='center-of-page'>
        <h1 style={{ color: '#40a9ff' }}>{`v${versions[0].version}`}</h1>
        <Tag color='#40a9ff' onClick={this.handleShowDetails}>
          Release Notes
        </Tag>
      </div>
    ) : (
      <Collapse defaultActiveKey={['1']}>
        {versions.map((item, index) => (
          <Collapse.Panel header={`v${item.version}`} key={index + 1}>
            <ul style={{ padding: '15px' }}>
              {item.works.map(work => (
                <li>{work}</li>
              ))}
            </ul>
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  }
}

export default About;
