import React from 'react';
import {
  Card,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Icon,
  Tooltip,
  Switch,
  message as showMessage,
} from 'antd';

import { patchData, getData } from '../../helper/dataService';
import API from '../../helper/api';
import Spinner from '../common/Spinner';
import { delayApiRequest, objDifferenceWith } from '../../helper/utility';

class AssetEdit extends React.Component {
  state = {
    fetching: true,
    data: {},
    deviceFetching: false,
    deviceData: [],
    submitted: false,
  };

  EDITABLE_FIELDS = [];
  ORIGINAL_DATA = {};

  componentDidMount() {
    this.fetchAssetData();
  }

  fetchAssetData = () => {
    getData(API.asset.editOrView(this.props.match.params.oid))
      .then(({ data: { data, status, editable } }) => {
        if (status === 'success') {
          this.EDITABLE_FIELDS = [...editable];
          const { active, allow_view, devices, name, remarks } = data;
          let device_oid = '';
          if (devices.length) {
            device_oid = devices[0]._id.$oid;
          }

          const finalData = { active, allow_view, device_oid, name, remarks };
          this.props.form.setFieldsValue({ ...finalData });

          this.ORIGINAL_DATA = { ...finalData };

          this.setState({ data, deviceData: devices });
        } else if (status === 'failed') {
          showMessage.error('Sorry! Something went wrong');
        }
        this.setState({ fetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ fetching: false });
      });
  };

  //* Device
  handleFetchDevice = (deviceName) => {
    if (deviceName.length >= 4) {
      delayApiRequest(() => this.fetchDevice(deviceName));
    }
  };

  fetchDevice = (deviceName) => {
    this.props.form.resetFields(['device_oid']);

    this.setState({ deviceData: [], deviceFetching: true });
    getData(API.device.search(deviceName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ deviceData: data });
        } else {
          showMessage.error('Sorry! Something went wrong');
        }
        this.setState({ deviceFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ deviceFetching: false });
      });
  };

  clearDevice = () => {
    this.setState({ deviceData: [] });
    this.props.form.setFieldsValue({ device_oid: '' });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = objDifferenceWith(this.ORIGINAL_DATA, values);

        const finalData = { ...data };
        if (finalData.hasOwnProperty('device_oid')) {
          finalData.devices = finalData.device_oid
            ? [finalData.device_oid]
            : [];
          delete finalData.device_oid;
        }

        if (Object.keys(finalData).length) {
          this.setState({ submitted: true });
          patchData(
            API.asset.editOrView(this.props.match.params.oid),
            finalData
          )
            .then(({ data: { status, display } }) => {
              this.ORIGINAL_DATA = { ...this.ORIGINAL_DATA, ...data };
              if (status === 'success') {
                showMessage.success(display);
              } else if (status === 'failed') {
                showMessage.error(display);
              }
              this.setState({ submitted: false });
            })
            .catch(() => {
              showMessage.error('Sorry! Something went wrong');
              this.setState({ submitted: false });
            });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const {
      fetching,
      deviceFetching,
      deviceData,
      submitted,
      data: { asset_id = '' },
    } = this.state;

    const currentSelectedDevice = deviceData.find(
      ({ _id }) => _id.$oid === getFieldValue('device_oid')
    );

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='car' />
                {' Edit Asset'}
              </span>
            }
            extra={<span>{asset_id}</span>}
            loading={fetching}
          >
            <Form onSubmit={this.handleSubmit} layout='vertical'>
              <Form.Item label='Name' {...formItemLayout}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input asset name.',
                    },
                  ],
                })(
                  <Input
                    type='text'
                    disabled={!this.EDITABLE_FIELDS.includes('name')}
                  />
                )}
              </Form.Item>

              <Form.Item
                label='Device'
                extra={
                  currentSelectedDevice ? (
                    <Button.Group size='small'>
                      <Button>
                        {currentSelectedDevice.imei} [
                        {currentSelectedDevice.device_id}] [
                        {currentSelectedDevice.device_type}]
                      </Button>
                      <Tooltip title='Remove device'>
                        <Button
                          icon='delete'
                          onClick={this.clearDevice}
                          disabled={
                            !this.EDITABLE_FIELDS.includes('devices') ||
                            !this.ORIGINAL_DATA.device_oid
                          }
                        />
                      </Tooltip>
                    </Button.Group>
                  ) : null
                }
                {...formItemLayout}
              >
                {getFieldDecorator(
                  'device_oid',
                  {}
                )(
                  <Select
                    showSearch
                    placeholder='Search and Select Device'
                    filterOption={false}
                    notFoundContent={
                      deviceFetching ? <Spinner /> : 'No Content Found'
                    }
                    onSearch={this.handleFetchDevice}
                    defaultActiveFirstOption={false}
                    disabled={
                      !this.EDITABLE_FIELDS.includes('devices') ||
                      !this.ORIGINAL_DATA.device_oid
                    }
                  >
                    {deviceData.length > 0 &&
                      deviceData.map(
                        ({ _id, imei, device_id, device_type }) => (
                          <Select.Option key={_id.$oid}>
                            {imei} [{device_id}] [{device_type}]
                          </Select.Option>
                        )
                      )}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label='Remarks' {...formItemLayout}>
                {getFieldDecorator('remarks', {
                  initialValue: '',
                  rules: [
                    {
                      max: 250,
                      message: 'The input not more than 250 characters.',
                    },
                  ],
                })(
                  <Input.TextArea
                    placeholder='Remarks'
                    rows={4}
                    disabled={!this.EDITABLE_FIELDS.includes('remarks')}
                  />
                )}
              </Form.Item>
              <Form.Item label='Active' {...formItemLayout}>
                {getFieldDecorator('active', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Switch disabled={!this.EDITABLE_FIELDS.includes('active')} />
                )}
              </Form.Item>
              <Form.Item label='Allow View' {...formItemLayout}>
                {getFieldDecorator('allow_view', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Switch
                    disabled={!this.EDITABLE_FIELDS.includes('allow_view')}
                  />
                )}
              </Form.Item>

              <Button
                type='primary'
                size='large'
                htmlType='submit'
                style={{ float: 'right' }}
                loading={submitted}
              >
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(AssetEdit);
