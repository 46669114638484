import React from 'react';
import {
  Card,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Icon,
  Tooltip,
  message as showMessage,
} from 'antd';

import { postData, getData } from '../../helper/dataService';
import API from '../../helper/api';
import Spinner from '../common/Spinner';
import { delayApiRequest } from '../../helper/utility';

class AssetAdd extends React.Component {
  state = {
    deviceFetching: false,
    deviceData: [],
    assetFetching: false,
    assetData: [],
    submitted: false,
  };

  // * ASSET
  handleFetchAsset = (assetName) => {
    if (assetName.length >= 4) {
      delayApiRequest(() => this.fetchAsset(assetName));
    }
  };
  fetchAsset = (assetName) => {
    this.props.form.resetFields(['asset_oid']);
    this.setState({ assetData: [], assetFetching: true });

    getData(API.asset.search(assetName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ assetData: data });
        }
        this.setState({ assetFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ assetFetching: false });
      });
  };

  clearAsset = () => {
    this.setState({ assetData: [], deviceData: [] });
    this.props.form.resetFields(['asset_oid', 'device_oid', 'remarks']);
  };

  //* Device
  handleFetchDevice = (deviceName) => {
    if (deviceName.length >= 4) {
      delayApiRequest(() => this.fetchDevice(deviceName));
    }
  };

  fetchDevice = (deviceName) => {
    this.props.form.resetFields(['device_oid']);

    this.setState({ deviceData: [], deviceFetching: true });
    getData(API.device.search(deviceName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ deviceData: data });
        } else {
          showMessage.error('Sorry! Something went wrong');
        }
        this.setState({ deviceFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ deviceFetching: false });
      });
  };

  clearDevice = () => {
    this.setState({ deviceData: [] });
    this.props.form.resetFields(['device_oid']);
  };

  // * set remarks
  setRemarks = (value) => {
    const asset = this.state.assetData.find(
      (asset) => asset._id.$oid === value
    );

    this.props.form.setFieldsValue({
      remarks: asset.remarks ? asset.remarks : '',
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitted: true });

        const { assetData } = this.state;
        const { asset_oid } = values;
        const selectedAsset = assetData.find(
          ({ _id }) => _id.$oid === asset_oid
        );

        postData(API.asset.add, {
          ...values,
          name: selectedAsset ? selectedAsset.name : '',
        })
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              showMessage.success(display);
              this.props.form.resetFields([
                'asset_oid',
                'device_oid',
                'remarks',
              ]);
              this.setState({
                assetData: [],
                deviceData: [],
              });
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const {
      assetFetching,
      assetData,
      deviceFetching,
      deviceData,
      submitted,
    } = this.state;

    const currentSelectedAsset = assetData.find(
      ({ _id }) => _id.$oid === getFieldValue('asset_oid')
    );
    const currentSelectedDevice = deviceData.find(
      ({ _id }) => _id.$oid === getFieldValue('device_oid')
    );

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='car' />
                {' Add Asset'}
              </span>
            }
          >
            <Form onSubmit={this.handleSubmit} layout='vertical'>
              <Form.Item
                label='Name'
                extra={
                  currentSelectedAsset ? (
                    <Button.Group size='small'>
                      <Button>
                        {currentSelectedAsset.name} [
                        {currentSelectedAsset.asset_id}]
                      </Button>
                      <Tooltip title='Remove Asset'>
                        <Button icon='delete' onClick={this.clearAsset} />
                      </Tooltip>
                    </Button.Group>
                  ) : null
                }
                {...formItemLayout}
              >
                {getFieldDecorator('asset_oid', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a asset',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder='Search and Select Asset'
                    filterOption={false}
                    notFoundContent={
                      assetFetching ? <Spinner /> : 'No Content Found'
                    }
                    onSearch={this.handleFetchAsset}
                    defaultActiveFirstOption={false}
                    onSelect={this.setRemarks}
                  >
                    {assetData.length > 0 &&
                      assetData.map(({ _id, name, asset_id }) => (
                        <Select.Option key={_id.$oid}>
                          {name} [{asset_id}]
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                label='Device'
                extra={
                  currentSelectedDevice ? (
                    <Button.Group size='small'>
                      <Button>
                        {currentSelectedDevice.imei} [
                        {currentSelectedDevice.device_id}] [
                        {currentSelectedDevice.device_type}]
                      </Button>
                      <Tooltip title='Remove device'>
                        <Button icon='delete' onClick={this.clearDevice} />
                      </Tooltip>
                    </Button.Group>
                  ) : null
                }
                {...formItemLayout}
              >
                {getFieldDecorator('device_oid', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a device',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder='Search and Select Device'
                    filterOption={false}
                    notFoundContent={
                      deviceFetching ? <Spinner /> : 'No Content Found'
                    }
                    onSearch={this.handleFetchDevice}
                    defaultActiveFirstOption={false}
                  >
                    {deviceData.length > 0 &&
                      deviceData.map(
                        ({ _id, imei, device_id, device_type }) => (
                          <Select.Option key={_id.$oid}>
                            {imei} [{device_id}] [{device_type}]
                          </Select.Option>
                        )
                      )}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label='Remarks' {...formItemLayout}>
                {getFieldDecorator('remarks', {
                  initialValue: '',
                  rules: [
                    {
                      max: 250,
                      message: 'The input not more than 250 characters.',
                    },
                  ],
                })(<Input.TextArea placeholder='Remarks' rows={4} />)}
              </Form.Item>
              <Button
                type='primary'
                size='large'
                htmlType='submit'
                style={{ float: 'right' }}
                loading={submitted}
              >
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(AssetAdd);
