import React from 'react';
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Icon,
  message as showMessage,
  DatePicker,
  Popconfirm,
  InputNumber,
  Table,
  Typography,
  // Input,
} from 'antd';
import moment from 'moment';

import { getData, patchData } from '../../helper/dataService';
import API from '../../helper/api';
import { objDifferenceWith } from '../../helper/utility';

class OrderEdit extends React.Component {
  state = {
    fetchingOrderData: true,
    data: {},
    submitted: false,
    loadMore: false,
  };

  EDITABLE_FIELDS = [];
  ORIGINAL_DATA = {};

  componentDidMount() {
    this.fetchOrderData();
  }

  fetchOrderData = () => {
    getData(API.order.editOrView(this.props.match.params.oid))
      .then(({ data: { data = [], editable = [], status } }) => {
        if (status === 'success') {
          const {
            discount_amount,
            next_installment_amount,
            status,
            order_expiration_date,
          } = data;

          // Set editable list of user
          this.EDITABLE_FIELDS = [...editable];

          this.setState({
            data,
          });

          this.ORIGINAL_DATA = {
            next_installment_amount,
            discount_amount,
            order_expiration_date: moment(order_expiration_date),
            status,
          };

          this.props.form.setFieldsValue({
            discount_amount,
            next_installment_amount,
            order_expiration_date: moment(order_expiration_date),
          });
          this.setState({ fetchingOrderData: false });
        } else if (status === 'failed') {
          showMessage.error('Sorry! Something went wrong');
        }
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ fetchingOrderData: false });
      });
  };

  handleLoadMore = () => {
    // show and hide content
    this.setState((prevState) => ({ loadMore: !prevState.loadMore }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // Check different between previous and current values
        const diffValues = objDifferenceWith(this.ORIGINAL_DATA, values);

        if (Object.keys(diffValues).length > 0) {
          if (diffValues.hasOwnProperty('order_expiration_date')) {
            diffValues.order_expiration_date = moment(
              diffValues.order_expiration_date
            ).format('YYYY-MM-DD');
          }
          this.ORIGINAL_DATA = { ...this.ORIGINAL_DATA, ...diffValues };
          this.sendData({ ...diffValues });
        }
      }
    });
  };

  handleCancelOrder = () => {
    this.sendData({ status: 'Cancelled' });
  };

  sendData = (values) => {
    this.setState({ submitted: true });
    patchData(API.order.editOrView(this.props.match.params.oid), values)
      .then(({ data: { display, status } }) => {
        if (status === 'success') {
          this.fetchOrderData();

          showMessage.success(display);
        }
        this.setState({ submitted: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ submitted: false });
      });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const {
      fetchingOrderData,
      submitted,
      // loadMore,
      data: {
        customer: { name, email, mobile } = {},
        status,
        total_amount,
        products,
        _id,
      } = {},
    } = this.state;

    const netPayable = total_amount - getFieldValue('discount_amount');
    const dueAmount = netPayable - getFieldValue('next_installment_amount');

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='shopping-cart' />
                {' Edit Order'}
              </span>
            }
            extra={
              status === 'Ordered' ? (
                <Popconfirm
                  title='Are you sure？'
                  okText='Yes'
                  cancelText='No'
                  onConfirm={this.handleCancelOrder}
                >
                  <Button type='danger'>Cancel</Button>
                </Popconfirm>
              ) : null
            }
          >
            {/* Show  not editable content - START */}
            <Card loading={fetchingOrderData}>
              <Form layout='vertical'>
                <Form.Item label='Name' {...formItemLayout}>
                  {name}
                </Form.Item>
                <Form.Item label='Mobile' {...formItemLayout}>
                  {mobile}
                </Form.Item>
                <Form.Item label='Email' {...formItemLayout}>
                  {email}
                </Form.Item>
                <Form.Item label='Pay' {...formItemLayout}>
                  {_id ? (
                    <Typography.Paragraph
                      copyable
                    >{`https://payment.finder-lbs.com/online/order_ssl.html?oid=${_id.$oid}`}</Typography.Paragraph>
                  ) : null}
                </Form.Item>

                {/* {loadMore && (

                )} */}

                {/* Show and hide content control */}
                {/* <Button
                  type='ghost'
                  size='small'
                  onClick={this.handleLoadMore}
                  style={{ float: 'right' }}
                >
                  {loadMore ? 'Show Less' : 'Show More'}
                </Button> */}
              </Form>
              <Table
                dataSource={products}
                size='small'
                rowKey='code'
                bordered={false}
                pagination={false}
                footer={() => `Total Amount: ${total_amount}`}
              >
                <Table.Column title='Code' dataIndex='code' />
                <Table.Column title='Name' dataIndex='name' />
                <Table.Column title='Price' dataIndex='price' />
                <Table.Column title='Quantity' dataIndex='quantity' />
                <Table.Column
                  title='Subtotal'
                  render={({ price, quantity }) => price * quantity}
                />
              </Table>
            </Card>
            {/* Show not editable content - END */}

            <br />

            {/* Show editable content - START */}
            <Card loading={fetchingOrderData}>
              <Form
                onSubmit={this.handleSubmit}
                hideRequiredMark
                layout='vertical'
              >
                <Form.Item label='Order Expiration' {...formItemLayout}>
                  {getFieldDecorator('order_expiration_date', {
                    initialValue: 0,
                  })(
                    <DatePicker
                      showToday
                      allowClear={false}
                      disabledDate={(current) =>
                        current < moment().startOf('day')
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item label='Discount' {...formItemLayout}>
                  {getFieldDecorator('discount_amount', {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: 'Please input discount',
                      },
                    ],
                  })(
                    <InputNumber
                      min={0}
                      max={Number(total_amount)}
                      placeholder='Discount'
                      disabled={
                        !this.EDITABLE_FIELDS.includes('discount_amount')
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item label='Net Payable' {...formItemLayout}>
                  {netPayable}
                </Form.Item>
                <Form.Item label='Next Installment' {...formItemLayout}>
                  {getFieldDecorator('next_installment_amount', {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: 'Please input next installment',
                      },
                    ],
                  })(
                    <InputNumber
                      min={0}
                      max={netPayable}
                      placeholder='Next Installment'
                      disabled={
                        !this.EDITABLE_FIELDS.includes(
                          'next_installment_amount'
                        )
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item label='Due Amount' {...formItemLayout}>
                  {dueAmount}
                </Form.Item>

                {/* <Form.Item label='Remarks' {...formItemLayout}>
                  {getFieldDecorator('remarks', {
                    initialValue: '',
                    rules: [
                      {
                        max: 250,
                        message: 'The input not more than 250 characters.',
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder='Remarks'
                      disabled={!this.EDITABLE_FIELDS.includes('remarks')}
                    />
                  )}
                </Form.Item> */}

                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  loading={submitted}
                  style={{ float: 'right' }}
                  disabled={status !== 'Ordered'}
                >
                  SUBMIT
                </Button>
              </Form>
            </Card>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(OrderEdit);
