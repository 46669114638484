import React from 'react';
import PropTypes from 'prop-types';
import { Form, message as showMessage, Icon } from 'antd';
import moment from 'moment';

import { getData } from '../../helper/dataService';
import API from '../../helper/api';

import Spinner from '../common/Spinner';

class AssetDetails extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };

  componentDidMount() {
    getData(API.asset.editOrView(this.props.assetId))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ data });
        } else if (status === 'failed') {
          showMessage.error('Sorry! Something went wrong');
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      isLoading,
      data: {
        active,
        allow_view,
        asset_id,
        created_by,
        created_on,
        devices,
        modified_by,
        modified_on,
        name,
        remarks,
      } = {},
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return isLoading ? (
      <Spinner />
    ) : (
      <Form layout='vertical'>
        <Form.Item label='Asset ID' {...formItemLayout}>
          {asset_id}
        </Form.Item>

        <Form.Item label='Active' {...formItemLayout}>
          <span className={active ? 'active' : 'inactive'}>
            {active ? 'Yes' : 'No'}
          </span>
        </Form.Item>

        <Form.Item label='Allow View' {...formItemLayout}>
          <span className={allow_view ? 'active' : 'inactive'}>
            {allow_view ? 'Yes' : 'No'}
          </span>
        </Form.Item>

        <Form.Item label='Name' {...formItemLayout}>
          {name}
        </Form.Item>

        <Form.Item
          label='Device'
          extra={devices.length ? devices[0].device_type : null}
          {...formItemLayout}
        >
          {devices.length > 0 ? (
            `${devices[0].imei} [${devices[0].device_id}]`
          ) : (
            <span className='blank'>No Device</span>
          )}
        </Form.Item>

        {remarks && (
          <Form.Item label='Remarks' {...formItemLayout}>
            {remarks}
          </Form.Item>
        )}

        {created_on && (
          <Form.Item label='Created' {...formItemLayout}>
            <span>
              <Icon type='clock-circle' />{' '}
              {moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')}
            </span>
            <br />
            <span>
              <Icon type='user' /> {created_by.name ? created_by.name : 'User'}
            </span>
          </Form.Item>
        )}

        {modified_on && (
          <Form.Item label='Modified' {...formItemLayout}>
            {modified_on && (
              <span>
                <Icon type='clock-circle' />{' '}
                {moment(modified_on.$date).format('DD-MMM-YYYY hh:mm a')}
              </span>
            )}
            <br />
            <span>
              <Icon type='user' />{' '}
              {modified_by.name ? modified_by.name : 'Not Available'}
            </span>
          </Form.Item>
        )}
      </Form>
    );
  }
}

AssetDetails.propTypes = {
  assetId: PropTypes.string.isRequired,
};

export default AssetDetails;
