import React from 'react';
import PropTypes from 'prop-types';
import { Select, Button, Radio, Divider, message as showMessage } from 'antd';
import styled from 'styled-components';

import { patchData } from '../../helper/dataService';
import API from '../../helper/api';

class NotificationAllAssetForm extends React.Component {
  state = {
    submitted: false,
    featureSelect: '',
    sms: '',
    email: '',
    app: '',
  };

  features = {
    power_cut: 'Power Cut',
    ignition: 'Ignition',
    over_speed: 'Over Speed',
    geofence: 'Geofence',
  };

  // Will need to status check for SUBMIT button
  currentSelectedFeatureStatus = { name: '', sms: '', email: '' };

  handleFeatureSelect = (value) => {
    const checkStatus = (feature = '', type = '') => {
      const { assetSetting } = this.props;
      // here sequence is very important
      // check if feature exist and for how much assets
      if (assetSetting.every((item) => item[feature] && item[feature][type])) {
        return 'on';
      }
      if (assetSetting.some((item) => item[feature] && item[feature][type])) {
        return 'custom';
      }
      if (!assetSetting.every((item) => item[feature] && item[feature][type])) {
        return 'off';
      }
      return undefined;
    };
    const sms = checkStatus(value, 'sms');
    const email = checkStatus(value, 'email');
    const app = checkStatus(value, 'app');

    // Save current status before change
    this.currentSelectedFeatureStatus = {
      name: value,
      sms,
      email,
      app,
    };

    // set feature setting status against current data
    this.setState({
      featureSelect: value,
      sms,
      email,
      app,
    });
  };

  handleFeatureStatus = (e) => {
    // set feature name and value -> [power_cut] etc
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    this.setState({ submitted: true });
    const {
      userOid,
      updateAllAssetNotification,
      allAssetSetting,
      assetSetting,
      closeModal,
    } = this.props;

    const { featureSelect, sms, email, app } = this.state;

    const {
      sms: initialSmsStatus,
      email: initialEmailStatus,
      app: initialAppStatus,
    } = this.currentSelectedFeatureStatus;

    //  create object against current selected feature
    const data = {
      [featureSelect]: {},
    };

    //  create object against current selected feature for boolean
    const dataInBool = {
      [featureSelect]: {},
    };

    const convertIntoBool = (value) => {
      if (value === 'on') return true;
      if (value === 'off') return false;
      return undefined;
    };

    if (sms !== initialSmsStatus) {
      data[featureSelect].sms = sms;
      dataInBool[featureSelect].sms = convertIntoBool(sms);
    }
    if (email !== initialEmailStatus) {
      data[featureSelect].email = email;
      dataInBool[featureSelect].email = convertIntoBool(email);
    }
    if (app !== initialAppStatus) {
      data[featureSelect].app = app;
      dataInBool[featureSelect].app = convertIntoBool(app);
    }

    // get all asset oid only if feature exist in asset
    const assets_oid = assetSetting
      .filter((item) => item[featureSelect])
      .map((item) => item.asset._id.$oid);

    /*
      if current notification for search and all notification are same
      no need to send asset oids
    */
    const finalData =
      allAssetSetting.length === assetSetting.length
        ? dataInBool
        : { assets_oid, settings: { ...dataInBool } };

    // send api request for all assets OR assests have current selected feature
    // otherwise closemodal
    if (
      !finalData.hasOwnProperty('assets_oid') ||
      (finalData.hasOwnProperty('assets_oid') &&
        finalData.assets_oid.length > 0)
    ) {
      patchData(API.user.notification(userOid), finalData)
        .then(({ data: { display, status } }) => {
          if (status === 'success') {
            // update parent notifications
            updateAllAssetNotification(data);
            showMessage.success(display);
          } else if (status === 'failed') {
            showMessage.error(display);
          }
          this.setState({ submitted: false });
          closeModal();
        })
        .catch(() => {
          showMessage.error('Sorry! Something went wrong');
          this.setState({ submitted: false });
        });
    } else {
      closeModal();
    }
  };

  render() {
    const { submitted, featureSelect, sms, email, app } = this.state;
    const {
      sms: initialSmsStatus,
      email: initialEmailStatus,
      app: initialAppStatus,
    } = this.currentSelectedFeatureStatus;

    return (
      <div>
        <Select
          onChange={this.handleFeatureSelect}
          style={{ width: '100%' }}
          placeholder='Select feature'
        >
          {Object.keys(this.features).map((item) => (
            <Select.Option key={item}>{this.features[item]}</Select.Option>
          ))}
        </Select>

        {featureSelect && (
          <React.Fragment>
            <Divider>{this.features[featureSelect]}</Divider>
            <RadioBox>
              Email:
              <Radio.Group
                onChange={this.handleFeatureStatus}
                buttonStyle='solid'
                name='email'
                value={email}
              >
                <Radio.Button value='on'>On</Radio.Button>
                <Radio.Button value='off'>Off</Radio.Button>
                <Radio.Button
                  value='custom'
                  disabled={initialEmailStatus !== 'custom'}
                >
                  Custom
                </Radio.Button>
              </Radio.Group>
            </RadioBox>
            <RadioBox>
              SMS:
              <Radio.Group
                onChange={this.handleFeatureStatus}
                buttonStyle='solid'
                name='sms'
                value={sms}
              >
                <Radio.Button value='on'>On</Radio.Button>
                <Radio.Button value='off'>Off</Radio.Button>
                <Radio.Button
                  value='custom'
                  disabled={initialSmsStatus !== 'custom'}
                >
                  Custom
                </Radio.Button>
              </Radio.Group>
            </RadioBox>
            <RadioBox>
              App:
              <Radio.Group
                onChange={this.handleFeatureStatus}
                buttonStyle='solid'
                name='app'
                value={app}
              >
                <Radio.Button value='on'>On</Radio.Button>
                <Radio.Button value='off'>Off</Radio.Button>
                <Radio.Button
                  value='custom'
                  disabled={initialAppStatus !== 'custom'}
                >
                  Custom
                </Radio.Button>
              </Radio.Group>
            </RadioBox>
            <br />

            {/* no need to enable submit button for same setting value */}
            {(sms !== initialSmsStatus ||
              email !== initialEmailStatus ||
              app !== initialAppStatus) && (
              <Button
                type='primary'
                block
                onClick={this.handleSubmit}
                loading={submitted}
              >
                SUBMIT
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

NotificationAllAssetForm.propTypes = {
  userOid: PropTypes.string.isRequired,
  updateAllAssetNotification: PropTypes.func.isRequired,
  allAssetSetting: PropTypes.array.isRequired,
  assetSetting: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default NotificationAllAssetForm;

const RadioBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
