import React from "react";
import PropTypes from "prop-types";
import { Form, message as showMessage, Icon } from "antd";
import moment from "moment";

import { getData } from "../../helper/dataService";
import API from "../../helper/api";

import Spinner from "../common/Spinner";

class DeviceDetails extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };

  componentDidMount() {
    getData(API.device.editOrView(this.props.id))
      .then(({ data: { data, status } }) => {
        if (status === "success") {
          this.setState({ data });
        } else if (status === "failed") {
          showMessage.error("Sorry! Something went wrong");
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        showMessage.error("Sorry! Something went wrong");
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      isLoading,
      data: {
        created_by,
        created_on,
        device_id,
        device_type,
        imei,
        model,
        modified_by,
        modified_on,
        name,
        remarks,
        status,
        upload_id,
      } = {},
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return isLoading ? (
      <Spinner />
    ) : (
      <Form layout="vertical">
        <Form.Item label="Device ID" {...formItemLayout}>
          {device_id}
        </Form.Item>

        <Form.Item label="IMEI" {...formItemLayout}>
          {imei}
        </Form.Item>

        <Form.Item label="Device Type" {...formItemLayout}>
          {device_type}
        </Form.Item>

        <Form.Item label="Name" {...formItemLayout}>
          {name}
        </Form.Item>

        <Form.Item label="Model" {...formItemLayout}>
          {model}
        </Form.Item>

        <Form.Item label="Status" {...formItemLayout} className="cap">
          {status}
        </Form.Item>

        {upload_id && (
          <Form.Item label="Upload ID" {...formItemLayout}>
            {upload_id}
          </Form.Item>
        )}

        {remarks && (
          <Form.Item label="Remarks" {...formItemLayout}>
            {remarks}
          </Form.Item>
        )}

        {created_on && (
          <Form.Item label="Created" {...formItemLayout}>
            <span>
              <Icon type="clock-circle" />{" "}
              {moment(created_on.$date).format("DD-MMM-YYYY hh:mm a")}
            </span>
            <br />
            <span>
              <Icon type="user" /> {created_by.name ? created_by.name : "User"}
            </span>
          </Form.Item>
        )}

        {modified_on && (
          <Form.Item label="Modified" {...formItemLayout}>
            {modified_on && (
              <span>
                <Icon type="clock-circle" />{" "}
                {moment(modified_on.$date).format("DD-MMM-YYYY hh:mm a")}
              </span>
            )}
            <br />
            <span>
              <Icon type="user" />{" "}
              {modified_by.name ? modified_by.name : "Not Available"}
            </span>
          </Form.Item>
        )}
      </Form>
    );
  }
}

DeviceDetails.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DeviceDetails;
