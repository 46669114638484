import { Card } from 'antd';
import React from 'react';
import API from '../../../helper/api';
import Pagination from '../../Pagination';
import OrganizationDataTable from './OrganizationDataTable';

class OrganizationList extends React.Component {
  state = {
    data: '',
    isLoading: true,
  };

  saveData = (data, isLoading) => {
    this.setState({ data, isLoading });
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <Card style={{ marginBottom: '100px' }}>
        <Pagination
          path={API.organization.list}
          saveData={this.saveData}
          filterByCountry={true}
        />
        {data && <OrganizationDataTable data={data} isLoading={isLoading} />}
      </Card>
    );
  }
}

export default OrganizationList;
